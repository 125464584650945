import Link from 'next/link';
import PropTypes from 'prop-types';

import styles from '../sass/NotificationsEmpty.module.scss';

const NotificationsEmpty = ({
  emptyDescribe = '',
  isShowLink = true,
  linkText = '',
}) => {
  return (
    <div className={styles.empty}>
      <div className={styles.emoji}>
        <span role="img" aria-label="dream">🤔</span>
      </div>
      <div className={styles.text}>
        <p>{emptyDescribe}</p>
      </div>
      {isShowLink && (
        <Link href="/notifications" className={styles.link}>
          {linkText}
        </Link>
      )}
    </div>
  );
};

NotificationsEmpty.propTypes = {
  emptyDescribe: PropTypes.string,
  linkText: PropTypes.string,
  isShowLink: PropTypes.bool
};

export default NotificationsEmpty;
