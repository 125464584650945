import {
  TECHNOLOGIES_TREE_RESET_MODAL, TECHNOLOGIES_TREE_SET_DATA, TECHNOLOGIES_TREE_SET_DRAG_DATA,
  TECHNOLOGIES_TREE_SET_EXPANDED_KEYS, TECHNOLOGIES_TREE_SET_FILTER_DATA,
  TECHNOLOGIES_TREE_SET_MODAL_DATA,
  TECHNOLOGIES_TREE_SET_MODAL_ERROR, TECHNOLOGIES_TREE_SET_NEW_TECHNOLOGY, TECHNOLOGIES_TREE_SET_SUBMIT,
  TECHNOLOGIES_TREE_SET_TECHNOLOGY, TECHNOLOGIES_TREE_SET_USERS,
  TECHNOLOGY_USERS_SET_INACTIVE
} from './types';

export const initialState = {
  users: [],
  newTechnology: null,
  technology: null,
  isLoading: true,
  technologiesTree: [],
  technologiesList: [],
  expandedKeys: [],
  isSubmit: false,
  pageFilters: {
    search: '',
    autoExpandAll: false,
  },
  queryParams: {
    filters: {
      id: [],
      name: '',
      show_inactive: false,
    },
    sort: 'name',
  },
  mainModal: {
    modalId: 'mainModal',
    isModalOpen: false,
    technologyId: null,
    name: '',
    link: '',
    description: '',
    parent: null,
    isParentOpen: false,
    searchParentValue: '',
    uploadedImage: null,
    isHaveParent: false,
  },
  cropModal: {
    modalId: 'cropModal',
    isModalOpen: false,
  },
  saveModal: {
    modalId: 'saveModal',
    isModalOpen: false,
    parentNode: null,
  },
  deleteModal: {
    modalId: 'deleteModal',
    isModalOpen: false,
    technologyId: null,
    technologyName: null,
  },
  modalErrors: {},
  dragStartId: null,
  dragOverId: null,
  isDragOver: false,
  isDragStart: false,
  showInactiveSkills: false,
  showActiveUsers: true,
};

export default function technologiesTree(state = initialState, action) {
  switch (action.type) {
    case TECHNOLOGIES_TREE_SET_NEW_TECHNOLOGY:
      return {
        ...state,
        newTechnology: action.technology,
      };
    case TECHNOLOGIES_TREE_SET_TECHNOLOGY:
      return {
        ...state,
        technology: action.technology,
      };
    case TECHNOLOGIES_TREE_SET_USERS:
      return {
        ...state,
        users: action.users,
      };
    case TECHNOLOGIES_TREE_SET_DRAG_DATA:
      return {
        ...state,
        [action.key]: action.value,
      };
    case TECHNOLOGIES_TREE_SET_EXPANDED_KEYS:
      return {
        ...state,
        expandedKeys: action.expandedKeys,
      };
    case TECHNOLOGIES_TREE_SET_FILTER_DATA:
      return {
        ...state,
        pageFilters: {
          ...state.pageFilters,
          ...action.data,
        },
      };
    case TECHNOLOGIES_TREE_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case TECHNOLOGIES_TREE_SET_DATA:
      return {
        ...state,
        technologiesTree: action.technologiesTree,
        technologiesList: action.technologiesList,
        isLoading: false,
      };
    case TECHNOLOGIES_TREE_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case TECHNOLOGIES_TREE_SET_MODAL_ERROR:
      return {
        ...state,
        modalErrors: action.errors,
      };
    case TECHNOLOGIES_TREE_RESET_MODAL:
      return {
        ...state,
        modalErrors: {},
        mainModal: initialState.mainModal,
        deleteModal: initialState.deleteModal,
        saveModal: initialState.saveModal,
      };
    case TECHNOLOGY_USERS_SET_INACTIVE:
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
}
