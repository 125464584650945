import { batch, shallowEqual } from 'react-redux';

import IconButton from '../../../components/Button/IconButton';
import Bell from '../../../components/Icons/Bell';
import Portal from '../../../components/Portal/Portal';
import { NOTIFICATIONS_CHIPS, NOTIFICATIONS_TABS } from '../../../constants/notifications';
import { useDispatch, useSelector } from '../../../hooks/reduxToolkit';
import {
  fetchNotificationsAsync, setChips, setModalData, setQueryFilters, setTabs
} from '../../../toolkitStore/notificationsModal/slice';
import { localStorageHelper } from '../../../utils/storageHelper';
import NotificationsModal from './NotificationsModal';

import styles from '../sass/Notifications.module.scss';

const Notifications = () => {
  const dispatch = useDispatch();

  const modalContainer = document.getElementById('modal-container');

  const {
    isUnreadNotification, isModalOpen
  } = useSelector(({ notificationsModal }) => ({
    isUnreadNotification: notificationsModal.isUnreadNotification,
    isModalOpen: notificationsModal.modalProps.isModalOpen,
  }), shallowEqual);

  const handleClickButton = () => {
    const tab = localStorageHelper.get('notificationsTab');
    let filters = {};

    batch(() => {
      if (tab) {
        dispatch(setTabs(tab));

        if (tab === NOTIFICATIONS_TABS.NEW) {
          filters = { only_unread: true, only_read: null };
        } else {
          filters = { only_unread: null, only_read: true };
        }
      }

      dispatch(setChips(NOTIFICATIONS_CHIPS.ALL));
      dispatch(setQueryFilters(filters));
      dispatch(setModalData({ isModalOpen: true }));
      dispatch(fetchNotificationsAsync());
    });
  };

  return (
    <div className={styles.helpContainer}>
      <IconButton
        size="sm"
        icon={<Bell isActive={isUnreadNotification} />}
        variant="ghost"
        isActive={isModalOpen}
        onClick={handleClickButton}
      />
      {isModalOpen && (
        <Portal container={modalContainer}>
          <NotificationsModal />
        </Portal>
      )}
    </div>
  );
};

export default Notifications;
