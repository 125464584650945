import { createListenerMiddleware } from '@reduxjs/toolkit';

import { debounce } from '../../utils/helpers';
import { hasProperty, isEqual } from '../../utils/objectHelpers';
import { queryStringify, replaceRouterQuery } from '../../utils/routerQuery';
import {
  approveBatchWorklogsAsync,
  approveSingleWorklogAsync,
  fetchReviewAsync,
  setQueryFilter,
} from './slice';

const { startListening, middleware } = createListenerMiddleware();

const debounceFetchReview = debounce((dispatch) => {
  dispatch(fetchReviewAsync({ isApprove: true }));
});

startListening({
  predicate: (action) => {
    switch (action.type) {
      case setQueryFilter.toString(): {
        return true;
      }
      case approveSingleWorklogAsync.fulfilled.toString():
        return true;
      case approveBatchWorklogsAsync.fulfilled.toString():
        return true;
      case approveBatchWorklogsAsync.rejected.toString():
        return true;
      default:
        break;
    }

    return false;
  },
  effect: async ({ type, payload }, { getOriginalState, getState, dispatch }) => {
    switch (type) {
      case setQueryFilter.toString(): {
        const previousFilters = getOriginalState().review.query.filters;
        const currentFilters = getState().review.query.filters;

        replaceRouterQuery(queryStringify({ ...currentFilters }), ['id']);

        if ((payload.status || payload.project?.id || payload.id || hasProperty(payload, 'start_date'))
          && !isEqual(currentFilters, previousFilters)) {
          dispatch(fetchReviewAsync({ isApprove: false }));
        }

        break;
      }
      case approveSingleWorklogAsync.fulfilled.toString(): {
        debounceFetchReview(dispatch);
        break;
      }
      case approveBatchWorklogsAsync.fulfilled.toString(): {
        debounceFetchReview(dispatch);
        break;
      }
      case approveBatchWorklogsAsync.rejected.toString(): {
        debounceFetchReview(dispatch);
        break;
      }
      default:
        break;
    }
  },
});

export default middleware;
