import Router from 'next/router';

import { wrapToArray } from './arrayHelpers';
import { compactObject, convertStringToDeepObject, isObject } from './objectHelpers';

const checkSquareBrackets = (str) => {
  return str.includes('[') && str.includes(']');
};

export const queryStringify = (params) => {
  return Object
    .entries(compactObject(params))
    .reduce((acc, [key, value]) => {
      return { ...acc, [key]: isObject(value) ? JSON.stringify(value) : value };
    }, {});
};

export const parseRouterQuery = (query, toParse = [], toArray = []) => {
  return Object
    .entries(query)
    .reduce((acc, [key, value]) => {
      let tempValue = value;

      if (checkSquareBrackets(key)) {
        const { keys, obj } = convertStringToDeepObject(`${key}=${value}`, toArray?.includes(key));
        const mainKey = keys[0];

        return { ...acc, [mainKey]: { ...acc[mainKey], ...obj[mainKey] } };
      }

      if (toParse?.includes(key)) {
        tempValue = JSON.parse(value);
      }

      if (toArray?.includes(key)) {
        tempValue = wrapToArray(value);
      }

      if (value === 'true' || value === 'false') {
        tempValue = value === 'true';
      }

      return { ...acc, [key]: tempValue };
    }, {});
};

export const parseUrlString = (url, toArray = []) => {
  const query = {};

  if (url.includes('?')) {
    const queryString = url.split('?')[1];

    new URLSearchParams(queryString).forEach((value, key) => {
      const decodeKey = decodeURIComponent(key);
      const decodeValue = decodeURIComponent(value);

      if (query[decodeKey]) {
        query[decodeKey] = [...query[decodeKey], decodeValue];
      } else {
        query[decodeKey] = toArray.includes(decodeKey) ? wrapToArray(decodeValue) : decodeValue;
      }
    });
  }

  return query;
};

export const removeRouterQueryByKeys = async (keys) => {
  const query = Object
    .entries(Router.query)
    .reduce((acc, [k, v]) => {
      if (Array.isArray(keys) ? !keys.includes(k) : k !== keys) {
        acc[k] = v;
      }
      return acc;
    }, {});

  await Router.replace({ query }, undefined, { shallow: true });
};

export const replaceRouterQuery = (query, keys = []) => {
  const savedQuery = keys.reduce((acc, item) => {
    const value = Router.query[item];

    return value ? { ...acc, [item]: value } : acc;
  }, query);

  Router.replace({ query: savedQuery }, undefined, { shallow: true }).then();
};

export const mergeRouterQuery = (query, callback) => {
  Router.replace({ query: { ...Router.query, ...query } }, undefined, { shallow: true }).then(callback);
};
