import { SORT_BY_START_DATE } from '../../constants/common';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import {
  INVOICE_RECOMMENDED_FETCH_COMMENTS,
  INVOICE_RECOMMENDED_FETCH_INVOICES, INVOICE_RECOMMENDED_RESET_DATEPICKER, INVOICE_RECOMMENDED_SET_ABORT,
  INVOICE_RECOMMENDED_SET_DATA, INVOICE_RECOMMENDED_SET_DATEPICKER_DATA, INVOICE_RECOMMENDED_SET_EXPANDED_ROW,
  INVOICE_RECOMMENDED_SET_LOG_ERROR, INVOICE_RECOMMENDED_SET_MODAL_DATA, INVOICE_RECOMMENDED_SET_PAGE,
  INVOICE_RECOMMENDED_SET_QUERY_PARAMS, INVOICE_RECOMMENDED_SET_SORT, INVOICE_RECOMMENDED_SET_TABLE_HEAD_INPUT,
  INVOICE_RECOMMENDED_TOGGLE_COMMENT_ACTION, INVOICES_RECOMMENDED_SET_COMMENT_SUBMITTING,
  INVOICES_RECOMMENDED_SET_INITIAL_STATE
} from './types';

export const initialState = {
  isLoading: true,
  isCommentSubmitting: false,
  data: [],
  dailyTotal: [],
  weeklyTotal: [],
  monthlyTotal: [],
  expandedRows: [],
  totalWorkingHours: 0,
  isPending: false,
  meta: {},
  isApprovePending: [],
  commentsData: [],
  projectManagerData: null,
  expandedRow: null,
  queryParams: {
    filters: {
      show_own: false,
      show_only_previous_period: true,
      start_date: '',
      end_date: '',
      client: { id: [], name: '' },
      project: { name: '' },
      manager: { name: '' },
    },
    sort: `-${SORT_BY_START_DATE}`,
    with: ['client', 'project', 'manager'],
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  worklogsQueryParams: {
    filters: {
      id: [],
      project: { id: [] }
    },
    group_by: ['milestone_item_id'],
  },
  abortProps: {
    abortInvoices: {},
    abortWorkLogs: {},
    abortRoles: {},
    abortComments: {}
  },
  tableHeadInput: {
    client_name: '',
    project_name: '',
    manager_name: ''
  },
  projectItemProps: {
    name: '',
    type: '',
    rate: null,
    sum: null
  },
  modalProps: {
    isModalOpen: false,
    modalId: 'commentModal',
    invoiceId: null,
    project: null,
    client: null,
    commentId: '',
    comment_json: undefined,
    editCommentJson: undefined,
  },
  moreProps: {
    isOpen: false,
    id: '',
    style: {}
  },
  editModalProps: {
    isModalOpen: false,
    modalId: 'editCommentModal',
    comment_json: undefined,
    project: null,
    client: null,
    commentId: null,
  },
  warningModalProps: {
    isModalOpen: false,
    modalId: 'warningCommentModal',
  },
  modalErrors: {},
  reviewModalProps: {
    modalId: 'reviewModal',
    isModalOpen: false,
    isUpdateWorklogs: false,
  },
  datepicker: undefined,
  errors: []
};

export default function invoiceRecommended(state = initialState, action) {
  switch (action.type) {
    case INVOICES_RECOMMENDED_SET_INITIAL_STATE:
      return {
        ...initialState,
        queryParams: {
          ...initialState.queryParams,
          filters: {
            ...initialState.queryParams.filters,
            show_own: state.queryParams.filters.show_own,
          },
        },
      };
    case INVOICE_RECOMMENDED_SET_DATEPICKER_DATA:
      return {
        ...state,
        datepicker: action.datepicker,
      };
    case INVOICE_RECOMMENDED_RESET_DATEPICKER:
      return {
        ...state,
        datepicker: initialState.datepicker
      };
    case INVOICE_RECOMMENDED_SET_QUERY_PARAMS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.params,
          filters: {
            ...state[action.key].filters,
            ...action.params.filters
          }
        }
      };
    case INVOICE_RECOMMENDED_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        }
      };
    case INVOICE_RECOMMENDED_SET_DATA:
      return {
        ...state,
        ...action.data
      };
    case INVOICE_RECOMMENDED_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          [action.key]: action.controller
        }
      };
    case INVOICE_RECOMMENDED_FETCH_INVOICES:
      return {
        ...state,
        ...action.data,
        isLoading: false
      };
    case INVOICE_RECOMMENDED_SET_EXPANDED_ROW:
      return {
        ...state,
        expandedRow: action.expandedRowId,
      };
    case INVOICE_RECOMMENDED_SET_SORT:
      return {
        ...state,
        expandedRow: null,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    case INVOICE_RECOMMENDED_SET_TABLE_HEAD_INPUT:
      return {
        ...state,
        tableHeadInput: {
          ...state.tableHeadInput,
          ...action.tableHeadInput,
        }
      };
    case INVOICE_RECOMMENDED_FETCH_COMMENTS:
      return {
        ...state,
        commentsData: action.commentsData
      };
    case INVOICE_RECOMMENDED_TOGGLE_COMMENT_ACTION:
      return {
        ...state,
        moreProps: {
          ...state.moreProps,
          ...action.moreProps
        }
      };
    case INVOICE_RECOMMENDED_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case INVOICE_RECOMMENDED_SET_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    case INVOICES_RECOMMENDED_SET_COMMENT_SUBMITTING:
      return {
        ...state,
        isCommentSubmitting: action.isSubmitting,
      };
    default:
      return state;
  }
}
