import { FORBIDDEN, NO_CONTENT, NOT_FOUND } from '../constants/httpStatusCodes';
import fetchInstance from '../services/fetchInstance';

const getJson = async (res) => {
  let json;

  if (res.status === NO_CONTENT) {
    json = new Promise((resolve) => { resolve({ ok: res.ok }); });
  } else if (res.status === FORBIDDEN || res.status === NOT_FOUND) {
    const { status } = res;
    const data = await res.json();

    json = new Promise((resolve) => { resolve({ status, ...data }); });
  } else {
    json = res.json();
  }

  return json;
};

const interceptionFetch = (url, args, interceptor = {}) => {
  const { abortController, setAbortController } = interceptor;

  if (abortController?.signal) {
    abortController.abort();
  }
  const newAbortController = new AbortController();
  setAbortController(newAbortController);
  const { signal } = newAbortController;
  const newArgs = {
    ...args,
    signal
  };

  return fetchInstance(url, newArgs);
};

export const set = (method, url, body, interceptor = {}, headers = {}) => {
  const args = {
    method,
    headers,
    body: JSON.stringify(body)
  };

  return Object.keys(interceptor).length > 0
    ? interceptionFetch(url, args, interceptor)
      .then((res) => getJson(res))
      .catch((err) => {
        if (err.name === 'AbortError') {
          return null;
        }
        return new Error(err);
      })
    : fetchInstance(url, args)
      .then((res) => getJson(res))
      .catch(({ message }) => {
        throw message;
      });
};

export const authSet = (method, url, body = {}, interceptor = {}, headers = {}) => {
  return set(
    method,
    url,
    body,
    interceptor,
    headers,
  );
};

export const get = (url, interceptor = {}, headers = {}) => {
  return Object.keys(interceptor).length > 0
    ? interceptionFetch(url, { method: 'GET', headers }, interceptor)
      .then((res) => getJson(res))
      .catch((err) => {
        if (err.name === 'AbortError') {
          return null;
        }
        return new Error(err);
      })
    : fetchInstance(url, { method: 'GET', headers })
      .then((res) => getJson(res))
      .catch(({ message }) => {
        throw message;
      });
};

export const authGet = (url, interceptor = {}, headers = {}) => {
  return get(url, interceptor, headers);
};
