import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

export const initialState = {
  isLoading: true,
  error: undefined,
  profile: undefined,
  permissions: [],
  roles: [],
};

export const getUserProfile = createAsyncThunk(
  'user/getUserProfile',
  async (_, { rejectWithValue, signal }) => {
    try {
      const { data, status } = await axios.get('/users/profile', { signal });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.profile = action.payload;
        state.permissions = action.payload.permissions;
        state.roles = action.payload.roles;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
