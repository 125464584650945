import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { endOfYear, startOfYear } from 'date-fns';

import { MAX_FETCH_DATA_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import {
  formatStringDate, parseHolidaysData,
} from '../../utils/helpers';

const date = new Date();
const startDateOfYear = startOfYear(date);
const endDateOfYear = endOfYear(date);

export const initialState = {
  isLoading: false,
  data: [],
  link: {},
  meta: [],
  holidays: [],
  selectedDate: '',
  selectedYear: startDateOfYear,
  query: {
    filters: {
      id: '',
      user: {
        id: [],
        search: ''
      },
      start_date: {
        gte: formatStringDate(startDateOfYear, 'y-MM-dd'),
      },
      end_date: {
        lte: formatStringDate(endDateOfYear, 'y-MM-dd'),
      },
      status: ['approved'],
    },
    page: 1,
    per_page: MAX_FETCH_DATA_PER_PAGE,
  },
  datepicker: {
    from: startDateOfYear.toString(),
    to: endDateOfYear.toString(),
    enteredTo: endDateOfYear.toString(),
    inputFrom: formatStringDate(startDateOfYear, 'dd.MM.yyyy'),
    inputEnteredTo: formatStringDate(endDateOfYear, 'dd.MM.yyyy'),
  },
  employeeSearchProps: {
  },
  abortProps: {
  },
  error: {},
};

export const fetchReportVacationsAsync = createAsyncThunk(
  'reportVacations/fetchReportVacationsAsync',
  async (_, { rejectWithValue, getState }) => {
    const { query: filters } = getState().reportVacations;

    const { data, status } = await axios.get('/vacations', { params: filters });

    return status === 200 ? { data } : rejectWithValue(data.message);
  }
);

export const fetchHolidaysAsync = createAsyncThunk(
  'reportVacations/fetchHolidays',
  async (_, { rejectWithValue, getState }) => {
    const {
      query: {
        filters: {
          start_date: { gte: start },
          end_date: { lte: end }
        }
      }
    } = getState().reportVacations;
    const params = {
      filters: {
        start_date: start,
        end_date: end,
      }
    };
    const { data, status } = await axios.get('/holidays', { params });
    const holidays = parseHolidaysData(
      data.data,
      new Date(start),
      new Date(end)
    );

    return status === 200 ? { holidays } : rejectWithValue(data.message);
  }
);

export const reportVacationsSlice = createSlice({
  name: 'reportVacationsSlice',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setDatepicker: (state, action) => {
      state.datepicker = { ...state.datepicker, ...action.payload };
    },
    setSubmit: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportVacationsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchReportVacationsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data.data;
        state.link = action.payload.data.link;
        state.meta = action.payload.data.meta;
      })
      .addCase(fetchReportVacationsAsync.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(fetchHolidaysAsync.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchHolidaysAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.holidays = action.payload.holidays;
      })
      .addCase(fetchHolidaysAsync.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {
  resetState,
  setQueryFilter,
  setQuery,
  setSubmit,
  setDatepicker
} = reportVacationsSlice.actions;

export default reportVacationsSlice.reducer;
