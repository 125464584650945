import { SORT_BY_NAME } from '../../constants/common';
import { STATUS_ACTIVE } from '../../constants/status';
import { parseMonthIntToObj, parseToMonthInt } from '../../utils/helpers';
import {
  SALARY_DATA_UPDATE, SALARY_FETCH_DATA, SALARY_FETCH_ENUM, SALARY_FETCH_META,
  SALARY_FETCH_NUMBER_DEBTORS, SALARY_RESET_MODAL, SALARY_RESET_QWERY_PARAMS, SALARY_RESET_STATE, SALARY_SET_ABORT,
  SALARY_SET_EXPANDED_ROW,
  SALARY_SET_IS_LOADING,
  SALARY_SET_MODAL_DATA, SALARY_SET_PAGE,
  SALARY_SET_PAGE_FILTER_DATA, SALARY_SET_QUERY_PARAMS, SALARY_SET_SORT,
  SALARY_SET_SUBMIT, SALARY_SET_TABLE_CHECKED, SALARY_SET_TABLE_HEAD_INPUT
} from './types';

const { month, year } = parseMonthIntToObj();
const currentMonth = parseToMonthInt(year, month);

export const initialState = {
  data: [],
  links: {},
  meta: {},
  modalErrors: {},
  tableChecked: [],
  isApprovePending: [],
  lastChecked: null,
  isSubmit: {},
  isLoading: false,
  tableHeadInput: {
    employeeInputValue: '',
  },
  queryParams: {
    filters: {
      id: [],
      month: currentMonth,
      user_name: '',
      user_id: [],
      anomaly_status: [],
      xero_status: [],
      email_status: [],
      active_users: false,
      without_empty: false,
      use_builder: undefined,
      only_deleted: false,
    },
    sort: `${SORT_BY_NAME}`,
    page: null,
  },
  employees: {
    queryParams: {
      filters: {
        name: '',
        status: [STATUS_ACTIVE],
      },
    },
    employeeInputValue: ''
  },
  anomaliesFilters: {
    status: [],
  },
  pageFilters: {
    employeeName: '',
    selectedEmployeeName: [],
    isEmployeeOpen: false,
    isMonthOpen: false,
    isFilterOpen: false,
    useBuilder: false,
  },
  salaryFields: {
    user_id: '',
    useBuilder: false,
    month: null,
    amount: '',
    comment: '',
    department_id: '',
    type_id: '',
    amount_uah: '',
    amount_rate: '',
  },
  debtors: {
    numberOfDebtors: 0,
    isLinksLoading: false
  },
  reviewSalaryModalProps: {
    modalId: 'reviewSalaryModal',
    isModalOpen: false,
    isUpdateWorklogs: false,
  },
  previewSalaryModal: {
    modalId: 'salaryPreviewModal',
    previewHtml: '',
    isModalOpen: false,
  },
  sendSalaryModal: {
    modalId: 'sendSalaryModal',
    isModalOpen: false,
    title: '',
    content: '',
    action: '',
    sendSuccessful: false,
    id: null,
  },
  salaryAnomaliesReportConfirmModal: {
    modalId: 'salaryAnomaliesReportConfirmModal',
    isModalOpen: false,
    title: '',
    action: '',
    id: null,
  },
  exportResultModal: {
    modalId: 'exportResultModal',
    isModalOpen: false,
    summaryReport: {
      warnings: [],
      summary_url: '',
      rates_url: ''
    },
    isImportLoading: false
  },
  abortProps: {
    abortSalary: {},
    abortSalaryHolidays: {}
  },
  expandedRow: null,
};

export default function salary(state = initialState, action) {
  switch (action.type) {
    case SALARY_SET_EXPANDED_ROW:
      return {
        ...state,
        expandedRow: action.rowId
      };
    case SALARY_RESET_STATE:
      return initialState;
    case SALARY_SET_PAGE_FILTER_DATA:
      return {
        ...state,
        pageFilters: {
          ...state.pageFilters,
          ...action.data
        }
      };
    case SALARY_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    case SALARY_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SALARY_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    case SALARY_DATA_UPDATE:
      return {
        ...state,
        [action.key]: action.data,
      };
    case SALARY_FETCH_ENUM:
      return {
        ...state,
        anomaliesFilters: {
          ...state.anomaliesFilters,
          status: action.payload
        }
      };
    case SALARY_FETCH_NUMBER_DEBTORS:
      return {
        ...state,
        debtors: action.data
      };
    case SALARY_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case SALARY_RESET_MODAL:
      return {
        ...state,
        modalErrors: initialState.modalErrors,
        previewSalaryModal: initialState.previewSalaryModal,
        sendSalaryModal: initialState.sendSalaryModal,
        exportResultModal: initialState.exportResultModal
      };
    case SALARY_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case SALARY_SET_TABLE_CHECKED:
      return {
        ...state,
        tableChecked: action.tableChecked,
        lastChecked: action.lastChecked,
      };
    case SALARY_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          },
          page: null,
        }
      };
    case SALARY_SET_TABLE_HEAD_INPUT:
      return {
        ...state,
        tableHeadInput: {
          ...state.tableHeadInput,
          ...action.data,
        }
      };
    case SALARY_SET_SUBMIT:
      return {
        ...state,
        isSubmit: {
          ...state.isSubmit,
          ...action.data,
        }
      };
    case SALARY_FETCH_META:
      return {
        ...state,
        meta: action.meta
      };
    case SALARY_RESET_QWERY_PARAMS:
      return {
        ...state,
        tableHeadInput: {
          employeeInputValue: initialState.tableHeadInput.employeeInputValue,
        },
        queryParams: {
          ...state.queryParams,
          page: null,
          filters: {
            ...state.queryParams.filters,
            id: initialState.queryParams.filters.id,
            user_name: initialState.queryParams.filters.user_name,
            user_id: initialState.queryParams.filters.user_id,
            anomaly_status: initialState.queryParams.filters.anomaly_status,
            xero_status: initialState.queryParams.filters.xero_status,
            email_status: initialState.queryParams.filters.email_status,
            use_builder: initialState.queryParams.filters.use_builder,
            only_deleted: initialState.queryParams.filters.only_deleted,
          },
        },
      };
    case SALARY_SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
}
