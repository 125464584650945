import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { newValidateErrors, validateClientCompaniesModal, validateErrors } from '../../utils/validators';

export const initialState = {
  isLoading: true,
  error: undefined,
  data: [],
  meta: {},
  modalProps: {
    modalId: 'companyModal',
    isModalOpen: false,
    companyId: null,
  },
  modalErrors: {},
  companyFields: {
    name: '',
    state: '',
    address: '',
    upworkNames: [],
    client: undefined,
    country_code: undefined,
    country_code_subdivision: undefined
  },
  isEdit: false,
  isSubmit: false,
  isActive: false,
  query: {
    filters: {
      name: '',
      address: '',
      show_inactive: true
    },
    with: ['country_code', 'country_code_subdivision'],
    sort: 'name',
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
};

export const fetchClientCompaniesAsync = createAsyncThunk(
  'clientCompanies/fetchClientCompanies',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { clientCompanies: { query } } = getState();
      const { data, status } = await axios.get('/client_companies', {
        params: { ...query, filters: { ...query.filters, client_id: [Router.query.id] } }
      });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const restoreCompany = createAsyncThunk(
  'clientCompanies/restoreClientCompany',
  async ({ companyId, isActive }, { dispatch, rejectWithValue }) => {
    try {
      let method = 'DELETE';
      let endPoint = `/client_companies/${companyId}`;

      if (!isActive) {
        method = 'PUT';
        endPoint += '/restore';
      }

      const { data, status } = await axios({ method, url: endPoint });

      if (status === 200 || status === 204) {
        dispatch(fetchClientCompaniesAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditClientCompanyAsync = createAsyncThunk(
  'clientCompanies/addOrEditClientCompany',
  async ({ companyId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const { companyFields } = getState().clientCompanies;
      const errors = validateErrors(companyFields, validateClientCompaniesModal);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let endPoint = '/client_companies';

      if (companyId) {
        method = 'PUT';
        endPoint += `/${companyId}`;
      }

      const {
        name,
        address,
        upworkNames,
        comments,
        country_code_subdivision: countryCodeSubdivision,
        country_code: countryCode,
      } = companyFields;

      const { data, status } = await axios({
        method,
        url: endPoint,
        data: {
          name,
          address,
          country_code_subdivision_id: countryCodeSubdivision?.id,
          country_code_id: countryCode?.id,
          client_id: Router.query.id,
          upwork_names: upworkNames.filter((item) => item !== ''),
          comments
        }
      });

      if (status === 200 || status === 201) {
        if (companyId) {
          dispatch(fetchClientCompaniesAsync());
        } else {
          Router.push(`/clients/${data.client_id}/companies/${data.id}`);
        }
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientCompaniesSlice = createSlice({
  name: 'clientCompanies',
  initialState,
  reducers: {
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
      state.query.page = initialState.query.page;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setStatus: (state, action) => {
      state.isActive = action.payload;
    },
    setFieldsData: (state, action) => {
      state.companyFields = { ...state.companyFields, ...action.payload };
      state.modalErrors = {
        ...state.modalErrors,
        ...newValidateErrors({ ...action.payload }, validateClientCompaniesModal)
      };
    },
    resetClientCompaniesModal: (state) => {
      state.modalErrors = initialState.modalErrors;
      state.companyFields = initialState.companyFields;
      state.modalProps = initialState.modalProps;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientCompaniesAsync.pending, (state) => {
        state.isLoading = true;
        state.clientCompanies = undefined;
        state.error = undefined;
      })
      .addCase(fetchClientCompaniesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchClientCompaniesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addOrEditClientCompanyAsync.pending, (state) => {
        state.isLoading = true;
        state.clientCompanies = undefined;
        state.error = undefined;
        state.isSubmit = true;
      })
      .addCase(addOrEditClientCompanyAsync.fulfilled, (state) => {
        state.isLoading = false;
        state.isSubmit = false;
        state.modalErrors = initialState.modalErrors;
        state.companyFields = initialState.companyFields;
        state.modalProps = initialState.modalProps;
      })
      .addCase(addOrEditClientCompanyAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isSubmit = false;
        if (action.payload.isFormValidation) {
          state.modalErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
      })
      .addCase(restoreCompany.pending, (state) => {
        state.isLoading = true;
        state.clientCompanies = undefined;
        state.error = undefined;
        state.isSubmit = true;
      })
      .addCase(restoreCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSubmit = false;
        if (action.payload === '') {
          state.modalProps.isModalOpen = false;
          state.modalErrors = initialState.modalErrors;
          state.companyFields = initialState.companyFields;
          state.modalProps = initialState.modalProps;
        }
      })
      .addCase(restoreCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.isSubmit = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setModalData,
  setFieldsData,
  setStatus,
  resetClientCompaniesModal,
} = clientCompaniesSlice.actions;

export default clientCompaniesSlice.reducer;
