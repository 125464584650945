import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import { parseMilestoneItemData } from '../../utils/aggrements';
import { formatStringDate } from '../../utils/helpers';
import { sendFiles } from '../../utils/sendFiles';
import { validateErrors, validateMilestoneInfo } from '../../utils/validators';

export const acceptMilestoneAsync = createAsyncThunk(
  'milestoneInfo/acceptMilestone',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { modalFields } = getState().milestoneInfo;
      const {
        name, managerComment, images, approvedDate, status: statusMilestone, milestoneId
      } = modalFields;

      const errors = validateErrors({
        name, managerComment, images, approvedDate
      }, validateMilestoneInfo);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const files = images.filter(({ file }) => file instanceof File && file).map(({ file }) => file);
      const prevMediaIds = images.filter(({ file }) => !(file instanceof File && file)).map(({ file }) => file.id);
      let mediaIds = [];

      if (files.length > 0) {
        mediaIds = await sendFiles(files);

        if (mediaIds.error) {
          return rejectWithValue(mediaIds);
        }

        mediaIds = mediaIds.data.filter((item) => item !== undefined);
      }

      if (mediaIds.error) {
        return rejectWithValue({ isFormValidation: true, errors: mediaIds.error });
      }

      const body = {
        status: statusMilestone,
        client_acceptance_comment: managerComment,
        client_acceptance_date: formatStringDate(approvedDate, 'y-MM-dd'),
        client_acceptance_file_ids: [...prevMediaIds, ...mediaIds]
      };

      const { data, status } = await axios.post(`/product_milestones/${milestoneId}/accept_by_client`, body);

      return status === 200 ? parseMilestoneItemData(data) : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const rejectMilestoneAsync = createAsyncThunk(
  'milestoneInfo/rejectMilestone',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { modalFields } = getState().milestoneInfo;

      const { data, status } = await axios.post(`/product_milestones/${modalFields.milestoneId}/accept_by_client`, {
        status: modalFields.status,
      });

      return status === 200 ? parseMilestoneItemData(data) : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
