import Router from 'next/router';

import { REFRESH_ACCESS_TOKEN_ERROR } from '../constants/errors';
import { NOT_AUTHORIZED } from '../constants/httpStatusCodes';
import axios from './axios';

let isRefreshing = false;
let failedRequestsQueue = [];

const processQueue = (error, token = null) => {
  failedRequestsQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedRequestsQueue = [];
};

const fetchInstance = async (url, options = {}) => {
  const rewriteOptions = { ...options };

  rewriteOptions.headers = {
    ...options.headers,
    Authorization: axios.defaults.headers.common['Authorization'],
  };

  const response = await fetch(url, rewriteOptions);

  if (response.status === NOT_AUTHORIZED && !rewriteOptions.retry) {
    rewriteOptions.retry = true;

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({ resolve, reject });
      })
        .then((token) => {
          rewriteOptions.headers.Authorization = `Bearer ${token}`;
          return fetch(url, rewriteOptions);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    isRefreshing = true;

    try {
      const session = await fetch('/api/auth/session').then((res) => res.json());

      if (session?.error === REFRESH_ACCESS_TOKEN_ERROR) {
        throw REFRESH_ACCESS_TOKEN_ERROR;
      }

      if (session?.accessToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${session.accessToken}`;
        rewriteOptions.headers.Authorization = `Bearer ${session.accessToken}`;
        processQueue(null, session.accessToken);
        isRefreshing = false;
        return fetch(url, rewriteOptions);
      }
    } catch (error) {
      if (error === REFRESH_ACCESS_TOKEN_ERROR) {
        await Router.push({ pathname: '/auth/sign-in', query: { callbackUrl: Router.router.asPath } });
      } else {
        processQueue(error, null);
      }

      isRefreshing = false;
    }
  }

  return response;
};

export default fetchInstance;
