import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_BY_NAME } from '../../constants/common';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { validateAssetsCategoriesForm, validateErrors } from '../../utils/validators';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isLoading: false,
  isSubmit: false,
  isActiveSubmit: false,
  queryParams: {
    filters: {
      name: '',
      activity: true,
    },
    sort: SORT_BY_NAME,
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  nameHeadValue: '',
  modal: {
    modalId: 'assetsCategories',
    isModalOpen: false,
  },
  formFields: {
    categoryId: null,
    name: '',
    key: '',
    activity: false,
  },
  formErrors: {},
};

export const getAssetsCategories = createAsyncThunk(
  'assetsCategories/getAssetsCategories',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().assetsCategories;
      const { data, status } = await axios.get('/assets_categories', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeAssetsCategory = createAsyncThunk(
  'assetsCategories/removeAssetsCategory',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/assets_categories/${id}`);

      if (status === 204) {
        dispatch(getAssetsCategories());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const restoreAssetsCategory = createAsyncThunk(
  'assetsCategories/restoreAssetsCategory',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.put(`/assets_categories/${id}/restore`);

      if (status === 200) {
        dispatch(getAssetsCategories());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditAssetsCategory = createAsyncThunk(
  'assetsCategories/createOrEditAssetsCategory',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().assetsCategories;
      const errors = validateErrors(formFields, validateAssetsCategoriesForm);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/assets_categories';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const { data, status } = await axios({
        url,
        method,
        data: {
          name: formFields.name,
          key: formFields.key,
          activity: formFields.activity
        }
      });

      if (status === 201 || status === 200) {
        dispatch(getAssetsCategories());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assetsCategoriesSlice = createSlice({
  name: 'assetsCategories',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setTableHeadInput: (state, action) => {
      state.nameHeadValue = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateAssetsCategoriesForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssetsCategories.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getAssetsCategories.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getAssetsCategories.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(removeAssetsCategory.pending, (state) => {
        state.error = undefined;
        state.isActiveSubmit = true;
      })
      .addCase(removeAssetsCategory.fulfilled, (state) => {
        state.isActiveSubmit = false;
      })
      .addCase(removeAssetsCategory.rejected, (state, action) => {
        state.error = action.payload;
        state.isActiveSubmit = false;
      });
    builder
      .addCase(restoreAssetsCategory.pending, (state) => {
        state.error = undefined;
        state.isActiveSubmit = true;
      })
      .addCase(restoreAssetsCategory.fulfilled, (state) => {
        state.isActiveSubmit = false;
      })
      .addCase(restoreAssetsCategory.rejected, (state, action) => {
        state.error = action.payload;
        state.isActiveSubmit = false;
      });
    builder
      .addCase(createOrEditAssetsCategory.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditAssetsCategory.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createOrEditAssetsCategory.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
  }
});

export const {
  setModalData,
  setPage,
  setQueryParamsFilters,
  resetModal,
  setSort,
  setTableHeadInput,
  setFormFields,
  setFormErrors,
  setValidateFormField,
  resetState,
} = assetsCategoriesSlice.actions;

export default assetsCategoriesSlice.reducer;
