import { batch } from 'react-redux';
import { i18n } from 'next-i18next';

import {
  MONTHS,
  SALARY_ANOMALIES_ACTIONS,
  STATUS_ANOMALY,
  STATUS_EMAIL,
  STATUS_XERO,
} from '../../constants/common';
import {
  SALARY_ANOMALY_STATUS_ENUM,
  SALARY_EMAIL_STATUS_ENUM,
  SALARY_XERO_STATUS_ENUM
} from '../../constants/enums';
import { SALARY_FILTERS } from '../../constants/localStorage';
import { FIRST_MONTH_INTEGER, LAST_MONTH_INTEGER, SALARY_PAYMENT_PERIOD } from '../../constants/values';
import { authGet, authSet } from '../../utils/apiCall';
import {
  getApiErrors,
  getTimeRange,
  isUseBuilder,
  numberFormatter,
  parseMonthIntToObj,
  parseToMonthInt,
  queryStringify
} from '../../utils/helpers';
import {
  mergeRouterQuery,
  queryStringify as queryStringifyRouter,
  removeRouterQueryByKeys,
} from '../../utils/routerQuery';
import { localStorageHelper } from '../../utils/storageHelper';
import { formatHours } from '../../utils/timesHelper';
import { addAlerts } from '../alerts/actions';
import { fetchNumberOfDebtors } from '../invoices/actions';
import { initialState } from './reducers';
import {
  SALARY_DATA_UPDATE, SALARY_FETCH_DATA, SALARY_FETCH_ENUM, SALARY_FETCH_META,
  SALARY_FETCH_NUMBER_DEBTORS, SALARY_RESET_MODAL, SALARY_RESET_QWERY_PARAMS, SALARY_RESET_STATE, SALARY_SET_ABORT,
  SALARY_SET_EXPANDED_ROW,
  SALARY_SET_IS_LOADING,
  SALARY_SET_MODAL_DATA, SALARY_SET_PAGE,
  SALARY_SET_PAGE_FILTER_DATA, SALARY_SET_QUERY_PARAMS, SALARY_SET_SORT,
  SALARY_SET_SUBMIT, SALARY_SET_TABLE_CHECKED, SALARY_SET_TABLE_HEAD_INPUT
} from './types';

export const setExpandedRow = (rowId) => ({
  type: SALARY_SET_EXPANDED_ROW, rowId
});

const parseSalaryData = (date) => date.map((item) => {
  const { month, year } = parseMonthIntToObj(item.month);
  const statusTotals = {
    ...item.status_totals,
    all_approved_hours: item.status_totals.all_approved_hours - item.status_totals.overtime_approved_hours,
    all_billed_hours: item.status_totals.all_billed_hours - item.status_totals.overtime_billed_hours,
    all_reported_hours: item.status_totals.all_reported_hours - item.status_totals.overtime_reported_hours
  };

  const convertHours = Object.keys(statusTotals)
    .reduce((acc, items) => {
      acc[items] = item.status_totals === null ? null : formatHours(statusTotals[items]);
      return acc;
    }, {});

  return {
    ...item,
    status_totals: {
      ...item.status_totals,
      ...convertHours,
    },
    amount_items: numberFormatter.format(item.amount_items),
    amount_bonuses: numberFormatter.format(item.amount_bonuses),
    amount_corrections: numberFormatter.format(item.amount_corrections),
    amount_total: numberFormatter.format(item.amount_total),
    useBuilder: isUseBuilder(item.anomaly_status_description),
    useBuilderDataRanges: getTimeRange(item.anomaly_status_description),
    monthIndex: month,
    monthYear: year,
  };
});

const setAbort = (abortName, abortController) => ({
  type: SALARY_SET_ABORT,
  abortProps: {
    [abortName]: abortController
  }
});

export const resetAnomaliesPage = () => ({ type: SALARY_RESET_STATE });

export const setModalData = (key, data) => ({ type: SALARY_SET_MODAL_DATA, key, data });

export const setPageFilterData = (data) => ({
  type: SALARY_SET_PAGE_FILTER_DATA, data
});

export const setTableChecked = (tableChecked, lastChecked = null) => ({
  type: SALARY_SET_TABLE_CHECKED, tableChecked, lastChecked
});

export const setDataUpdate = (key, data) => ({
  type: SALARY_DATA_UPDATE, key, data
});

export const resetModalData = () => ({ type: SALARY_RESET_MODAL });

export const setIsLoading = (isLoading) => ({ type: SALARY_SET_IS_LOADING, isLoading });

export const resetQueryParams = () => {
  return (dispatch) => {
    removeRouterQueryByKeys(['page', 'name', 'anomaly_status', 'xero_status', 'email_status', 'user_id']).then();

    dispatch({ type: SALARY_RESET_QWERY_PARAMS });
  };
};

export const setStatusFilters = (statuses) => ({
  type: SALARY_FETCH_ENUM,
  payload: statuses
});

const setSubmit = (data) => ({
  type: SALARY_SET_SUBMIT, data
});

export const fetchSalary = () => {
  return (dispatch, getState) => {
    const { queryParams, abortProps: { abortSalary } } = getState().salary;

    dispatch(setIsLoading(true));

    authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/salaries?${queryStringify(queryParams)}`,
      {
        abortController: abortSalary,
        setAbortController: (controller) => dispatch(setAbort('abortSalary', controller))
      }
    ).then((res) => {
      if (res?.message) {
        const alertsError = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alertsError));
      } else if (res && !res.message) {
        const data = parseSalaryData(res.data);
        batch(() => {
          dispatch({
            type: SALARY_FETCH_DATA,
            payload: {
              ...res,
              data,
            }
          });
          dispatch(setTableChecked([]));
          dispatch(setAbort('abortSalary', {}));
        });
      }
      dispatch(setIsLoading(false));
    });
  };
};

export const setTableHeadInput = (data) => ({
  type: SALARY_SET_TABLE_HEAD_INPUT, data
});

export const prevFetchSalary = () => {
  return (dispatch, getState) => {
    const { month, year } = parseMonthIntToObj();
    const day = new Date().getUTCDate();
    let updateMonth = day > 6 ? month + 1 : month;
    let updateYear = year;

    if (updateMonth === FIRST_MONTH_INTEGER) {
      updateYear -= 1;
      updateMonth = LAST_MONTH_INTEGER;
    }
    const actualMonth = parseToMonthInt(updateYear, updateMonth);

    dispatch({
      type: SALARY_SET_QUERY_PARAMS,
      filters: { month: actualMonth, anomaly_status: ['warning'], }
    });
    const { queryParams, abortProps: { abortSalary } } = getState().salary;

    authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/salaries?${queryStringify(queryParams)}`,
      {
        abortController: abortSalary,
        setAbortController: (controller) => dispatch(setAbort('abortSalary', controller))
      }
    ).then((res) => {
      if (res?.message) {
        const alertsError = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alertsError));
      } else if (res && !res.message) {
        if (res.data.length > 0) {
          const data = parseSalaryData(res.data);
          localStorageHelper.modify(SALARY_FILTERS, (storage) => ({
            ...storage,
            month: actualMonth,
            anomaly_status: ['warning'],
          }));
          batch(() => {
            dispatch({
              type: SALARY_FETCH_DATA,
              payload: {
                ...res,
                data
              }
            });
            dispatch(setTableChecked([]));
            dispatch(setAbort('abortSalary', {}));
          });
        } else {
          dispatch({
            type: SALARY_SET_QUERY_PARAMS,
            filters: { ...initialState.queryParams.filters }
          });
          localStorageHelper.modify(
            SALARY_FILTERS,
            (storage) => ({ ...storage, ...initialState.queryParams.filters })
          );
          dispatch(fetchSalary());
        }
      }
    });
  };
};

export const loadLinksData = (isInvoicesManagement = false) => {
  return (dispatch) => {
    dispatch({
      type: SALARY_FETCH_NUMBER_DEBTORS,
      data: {
        isLinksLoading: true,
        numberOfDebtors: 0,
      }
    });
    fetchNumberOfDebtors(isInvoicesManagement).then((res) => {
      dispatch({
        type: SALARY_FETCH_NUMBER_DEBTORS,
        data: {
          isLinksLoading: false,
          numberOfDebtors: res || 0,
        }
      });
    });
  };
};

export const setSalaryAnomaliesFilter = (sort, statusItem, statusValue) => {
  return (dispatch, getState) => {
    const { queryParams: { filters } } = getState().salary;
    const newSort = statusValue ? [...filters[sort], statusItem] : filters[sort].filter((item) => item !== statusItem);

    localStorageHelper.modify(SALARY_FILTERS, (storage) => ({ ...storage, [sort]: newSort }));

    if (newSort.length) {
      mergeRouterQuery(queryStringifyRouter({
        [sort]: newSort,
        page: 1,
      }));
    } else {
      removeRouterQueryByKeys([sort, 'page']).then();
    }

    batch(() => {
      dispatch({ type: SALARY_SET_QUERY_PARAMS, filters: { [sort]: newSort } });
      dispatch(fetchSalary());
    });
  };
};

export const fetchSalaryStatusEnum = () => {
  return (dispatch) => {
    const statusSortMap = {
      [SALARY_ANOMALY_STATUS_ENUM]: STATUS_ANOMALY,
      [SALARY_EMAIL_STATUS_ENUM]: STATUS_EMAIL,
      [SALARY_XERO_STATUS_ENUM]: STATUS_XERO,
    };
    const params = {
      filters: {
        only_enum: [SALARY_ANOMALY_STATUS_ENUM, SALARY_EMAIL_STATUS_ENUM, SALARY_XERO_STATUS_ENUM]
      }
    };

    authGet(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/app/enum?${queryStringify(params)}`)
      .then((res) => {
        if (res && !res.message) {
          const data = res.map((item) => ({
            ...item,
            sort: statusSortMap[item.enum],
            values: Object.keys(item.values || [])
          }));

          dispatch(setStatusFilters(data));
        }
      });
  };
};

export const fetchPayroll = (id) => {
  return (dispatch) => {
    authGet(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/salaries/${id}/payroll`)
      .then((res) => {
        if (res && !res.message) {
          dispatch(setModalData(
            'previewSalaryModal',
            {
              isModalOpen: true,
              previewHtml: res.payroll,
            }
          ));
        }
      });
  };
};

export const setSort = (sort) => ({ type: SALARY_SET_SORT, sort });

export const setPage = (page) => ({ type: SALARY_SET_PAGE, page });

export const setUpdatedDataNew = (res) => {
  return (dispatch, getState) => {
    const { data } = getState().salary;

    const parsRes = parseSalaryData(res);
    const newDate = data.map((item) => {
      const item2 = parsRes.find((i2) => i2.id === item.id);
      return item2 ? {
        ...item,
        ...item2,
      } : item;
    });

    batch(() => {
      dispatch(setDataUpdate('data', newDate));
      dispatch(setTableChecked([]));
    });
  };
};

export const actionsSalary = (action, id) => {
  return (dispatch, getState) => {
    const key = action.replace('/', '');
    dispatch(setSubmit({ [key]: true }));

    const {
      tableChecked,
      queryParams: {
        filters: {
          month,
          use_builder: useBuilder
        }
      },
      sendSalaryModal
    } = getState().salary;

    const ids = id ? [id] : tableChecked.map((items) => items.id);
    const sendRequest = action.includes('send');
    let body = { ids };
    if (ids.length === 0) {
      body = { month };
      if (action === SALARY_ANOMALIES_ACTIONS.SEND_EMAIL) {
        body.use_builder = useBuilder;
      }
    }

    if (sendRequest) {
      body.send_successful = sendSalaryModal.sendSuccessful;
    }

    authSet(
      'POST',
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/salaries/${action}`,
      body,
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    ).then((res) => {
      if (res?.message) {
        const alertsError = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alertsError));
      } else {
        const { t } = i18n;

        const data = {
          isModalOpen: true, btnTitle: 'send', action, sendSuccessful: true, id
        };

        if (sendRequest) {
          const countSum = res.count_unsuccessful + res.count_successful;
          const isUnsuccessful = res.count_unsuccessful > 0;
          const monthString = MONTHS[month.toString().slice(4) - 1];
          const year = month.toString().slice(0, 4);

          const actionsMap = {
            [SALARY_ANOMALIES_ACTIONS.SEND_EMAIL]: {
              title: ids.length > 0 ? 'sendEmails' : 'sendAllEmails',
              content: isUnsuccessful
                ? t('salarySalary:youCan', { successful: res.count_successful, countSum })
                : t('salarySalary:areYouSure', { countSum, monthString, year }),
            },
            [SALARY_ANOMALIES_ACTIONS.SEND_XERO]: {
              title: ids.length > 0 ? 'sendToXero' : 'sendAllToXero',
              content: isUnsuccessful
                ? t('salarySalary:youCanXero', { successful: res.count_successful, countSum })
                : t('salarySalary:areYouSureXero', { countSum, monthString, year }),
            },
          };

          data.title = actionsMap[action].title;
          data.content = actionsMap[action].content;

          dispatch(setModalData('sendSalaryModal', { ...data, action }));
        }
      }

      if (sendSalaryModal.sendSuccessful || action === SALARY_ANOMALIES_ACTIONS.APPROVE) {
        batch(() => {
          dispatch(fetchSalary());
          dispatch(resetModalData());
        });
      }

      dispatch(setSubmit({ [key]: false }));
    });
  };
};

export const fetchSalaryByCondition = (isInitialLoad = false) => {
  return (dispatch) => {
    batch(() => {
      if (isInitialLoad) {
        const isFetchPrevMonth = new Date().getDate() < SALARY_PAYMENT_PERIOD;

        dispatch(isFetchPrevMonth ? prevFetchSalary() : fetchSalary());
      } else {
        dispatch(fetchSalary());
      }
    });
  };
};

export const setQueryParamsFilters = (filters) => ({ type: SALARY_SET_QUERY_PARAMS, filters });

export const exportMonthSalaries = () => {
  return (dispatch, getState) => {
    dispatch(setModalData('exportResultModal', { isImportLoading: true }));
    const { queryParams: { filters } } = getState().salary;

    authSet(
      'POST',
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/salaries/summary_report`,
      { month: filters.month },
      {},
      {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    ).then((res) => {
      if (res && !res.message) {
        dispatch(setModalData('exportResultModal', {
          summaryReport: res || {},
          isImportLoading: false
        }));
      }
    });
  };
};

export const fetchCountersSalary = () => {
  return (dispatch, getState) => {
    const { queryParams, abortProps: { abortSalary } } = getState().salary;
    const params = { filters: queryParams.filters, page: 1 };

    authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/salaries?${queryStringify(params)}`,
      {
        abortController: abortSalary,
        setAbortController: (controller) => dispatch(setAbort('abortSalary', controller))
      }
    ).then((res) => {
      if (res && !res?.message) {
        dispatch({
          type: SALARY_FETCH_META,
          meta: res.meta
        });
      }
    });
  };
};
