import { useEffect } from 'react';
import { shallowEqual } from 'react-redux';

import { setIsUnreadNotification } from '../toolkitStore/notificationsModal/slice';
import { useSelector as useReduxSelector } from './redux';
import { useDispatch } from './reduxToolkit';

const useNotificationInterceptor = () => {
  const dispatch = useDispatch();
  const { isUnreadNotification } = useReduxSelector(({ general }) => ({
    isUnreadNotification: general.isUnreadNotification
  }), shallowEqual);

  useEffect(() => {
    dispatch(setIsUnreadNotification(isUnreadNotification));
  }, [isUnreadNotification]);
};
export default useNotificationInterceptor;
