import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import classNames from '../../utils/classNames';

import styles from './sass/IconButton.module.scss';

const IconButton = forwardRef((props, ref) => {
  const {
    icon, className, dataSelector, ariaLabel, variant, isActive, size, disabled, ...otherProps
  } = props;

  const classes = classNames(
    className,
    styles.btn,
    {
      [styles[size]]: size && size !== 'md',
      [styles[variant]]: variant,
      [styles.active]: isActive,
    }
  );

  return (
    <button
      ref={ref}
      type="button"
      aria-label={ariaLabel}
      className={classes}
      data-selector={dataSelector}
      disabled={disabled}
      {...otherProps}
    >
      {icon}
    </button>
  );
});

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  dataSelector: PropTypes.string,
  ariaLabel: PropTypes.string,
  variant: PropTypes.oneOf(['outline', 'ghost', 'secondary']),
  size: PropTypes.oneOf(['sm', 'md']),
};

export default IconButton;
