import { createSlice, nanoid } from '@reduxjs/toolkit';

import { parseNotificationWithHTML } from '../../utils/helpers';

export const initialState = {
  notifications: [],
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, { payload }) => {
      const notification = {
        id: nanoid(),
        ...payload,
        message: parseNotificationWithHTML(payload.message || '')
      };
      state.notifications.push(notification);
    },
    dismissNotification: (state, { payload }) => {
      const index = state.notifications.findIndex(
        (notification) => notification.id === payload
      );

      if (index !== -1) {
        state.notifications.splice(index, 1);
      }
    },
  },
});

export const { addNotification, dismissNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
