import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import AuthGuard from '../src/components/Auth/AuthGuard';
import PermissionGuard from '../src/components/PermissionGuard/PermissionGuard';
import Layout from '../src/layout/Layout';
import { gtmNoScript, gtmScript } from '../src/lib/gtm';
import { reduxStoreContext, reduxToolkitStoreContext } from '../src/lib/reduxContexts';
import { useStore } from '../src/store/store';
import { useStore as useToolkitStore } from '../src/toolkitStore/store';

import '../src/pages/App/sass/globals.scss';

function MainApp({ Component, pageProps }) {
  const { initialReduxState, session, permissions } = pageProps;
  const store = useStore(initialReduxState);
  const toolkitStore = useToolkitStore(initialReduxState);

  const getLayout = Component.getLayout || ((page) => (
    <AuthGuard>
      <Layout>
        {page}
      </Layout>
    </AuthGuard>
  ));

  useEffect(() => {
    if (/windows/i.test(navigator.userAgent)) {
      document.body.classList.add('emoji');
    }
  }, []);

  return (
    <>
      {process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' && (
        <>
          {gtmScript()}
          {gtmNoScript()}
        </>
      )}
      <Provider store={store} context={reduxStoreContext}>
        <Provider store={toolkitStore} context={reduxToolkitStoreContext}>
          <SessionProvider
            session={session}
            refetchInterval={60 * 60}
            refetchWhenOffline={false}
            refetchOnWindowFocus={false}
          >
            {getLayout((
              <PermissionGuard permissions={permissions}>
                <Component {...pageProps} />
              </PermissionGuard>
            ))}
          </SessionProvider>
        </Provider>
      </Provider>
    </>
  );
}

MainApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({
    initialReduxState: PropTypes.shape({}),
    session: PropTypes.shape({}),
    permissions: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  router: PropTypes.shape({
    asPath: PropTypes.string,
    push: PropTypes.func
  }).isRequired
};

export default appWithTranslation(MainApp);
