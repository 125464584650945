import { MAX_FETCH_DATA_PER_PAGE } from '../../constants/values';
import {
  INVOICE_CREATE_RESET_DATA, INVOICE_CREATE_SET_DATA, INVOICE_CREATE_SET_DATEPICKER_DATA,
  INVOICE_CREATE_SET_LOADING, INVOICE_CREATE_SET_MODAL_DATA, INVOICE_CREATE_SET_PARSE_WORKLOGS,
  INVOICE_CREATE_SET_QUERY_FILTERS, INVOICE_CREATE_SET_QUERY_PARAMS,
  INVOICE_SINGLE_CREATE_SET_LOADING
} from './types';

export const initialState = {
  recommendedInvoiceId: null,
  isUpdate: false,
  worklogs: [],
  parseWorklogs: [],
  selectedWorklogs: [],
  summary: {},
  errors: [],
  projectId: '',
  invoiceId: '',
  invoiceName: '',
  initialDates: {},
  employeeInputValue: '',
  employeeSearchValue: '',
  itemInputValue: '',
  itemSearchValue: '',
  clientCompanies: [],
  clientCompany: null,
  paymentMethodId: '',
  isLoading: false,
  queryParams: {
    filters: {
      start_date: '',
      end_date: '',
    },
    with: ['user'],
  },
  createInvoiceQueryParams: {
    page: 1,
    per_page: MAX_FETCH_DATA_PER_PAGE,
    filters: {
      id: [],
      start_date: '',
      end_date: '',
      invoice: {
        id: [],
      },
      only_available_billable: true,
    },
    with: ['user', 'milestone_item'],
  },
  createInvoiceModal: {
    isModalOpen: false,
    modalId: 'createInvoiceModal',
  },
  datepicker: undefined,
  isCreateInvoiceSubmit: false,
};

export default function invoiceCreate(state = initialState, action) {
  switch (action.type) {
    case INVOICE_CREATE_RESET_DATA:
      return initialState;
    case INVOICE_CREATE_SET_QUERY_FILTERS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters,
          },
        },
      };
    case INVOICE_CREATE_SET_QUERY_PARAMS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.params,
          filters: {
            ...action.params.filters,
          },
        },
      };
    case INVOICE_CREATE_SET_DATEPICKER_DATA:
      return {
        ...state,
        datepicker: action.data,
      };
    case INVOICE_CREATE_SET_MODAL_DATA:
      return {
        ...state,
        createInvoiceModal: {
          ...state.createInvoiceModal,
          ...action.data,
        },
      };
    case INVOICE_CREATE_SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case INVOICE_SINGLE_CREATE_SET_LOADING:
      return {
        ...state,
        isCreateInvoiceSubmit: action.isLoading,
      };
    case INVOICE_CREATE_SET_DATA:
      return {
        ...state,
        ...action.data,
      };
    case INVOICE_CREATE_SET_PARSE_WORKLOGS:
      return {
        ...state,
        parseWorklogs: action.data,
      };
    default:
      return state;
  }
}
