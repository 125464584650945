import PropTypes from 'prop-types';

import styles from './sass/Title.module.scss';

const Title = ({ children, className = '' }) => (
  <h1 className={`${styles.title} ${className}`}>
    {children}
  </h1>
);

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.array
  ]).isRequired,
  className: PropTypes.string,
};

export default Title;
