import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_BY_NAME } from '../../constants/common';
import { STATUS_ACTIVE } from '../../constants/status';
import { LIST_SIZE_PER_PAGE_25 } from '../../constants/values';
import axios from '../../services/axios';

export const initialState = {
  isLoading: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      name: '',
      sales_manager_name: '',
      sales_channel_name: '',
      sales_source_name: '',
      show_own: true,
      status: [STATUS_ACTIVE],
    },
    sort: SORT_BY_NAME,
    with: ['sales_channel', 'sales_manager', 'country_code', 'sales_source'],
    page: null,
    per_page: LIST_SIZE_PER_PAGE_25,
  },
};

export const fetchClientsAsync = createAsyncThunk(
  'clients/fetchClients',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { query } = getState().clients;
      const { data, status } = await axios.get('/clients', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
      state.query.page = initialState.query.page;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchClientsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchClientsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter, setQuery, setData, resetState
} = clientsSlice.actions;

export default clientsSlice.reducer;
