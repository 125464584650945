export const STATUS = 'status';

export const STATUS_ACTIVE = 'active';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_APPROVED = 'approved';
export const STATUS_REJECTED = 'rejected';
export const STATUS_PENDING = 'pending';
export const STATUS_PAID = 'paid';
export const STATUS_EXPIRED = 'expired';
export const STATUS_DELETED = 'deleted';
export const STATUS_SUCCESSFUL = 'successful';
export const STATUS_FAILED = 'failed';

export const SUBSCRIPTIONS_STATUSES = [STATUS_PAID, STATUS_PENDING, STATUS_APPROVED, STATUS_REJECTED];

export const TEACHLEADING_STATUSES = [STATUS_PENDING, STATUS_APPROVED];

export const EXPENSE_REPORT_STATUSES = [STATUS_PENDING, STATUS_APPROVED, STATUS_REJECTED];

// Review status
export const REVIEW_STATUS_CHANGED = 'changed';
export const REVIEW_STATUS_NEW = 'new';
export const REVIEW_STATUS_APPROVED = 'approved';
export const REVIEW_STATUSES = [REVIEW_STATUS_NEW, REVIEW_STATUS_CHANGED, REVIEW_STATUS_APPROVED];

// USE BUILDER STATUSES
export const USE_BUILDER_ALL = 'all';
export const USE_BUILDER_YES = 'yes';
export const USE_BUILDER_NO = 'no';

// INVOICE STATUSES
export const INVOICE_STATUS_DRAFT = 'draft';
export const INVOICE_STATUS_SENDING = 'sending';
export const INVOICE_STATUS_SENT = 'sent';
export const INVOICE_STATUS_PAID = 'paid';
export const INVOICE_STATUS_PARTLY = 'partial-paid';
export const INVOICE_STATUS_BAD_DEBT = 'bad-debt';
export const INVOICE_STATUS_CLOSED = 'closed';
export const INVOICE_STATUS_VOIDED = 'voided';
export const INVOICE_STATUS_PARTLY_VOIDED = 'partial-voided';
export const INVOICE_PAYMENT_STATUSES = [
  INVOICE_STATUS_PAID, INVOICE_STATUS_PARTLY, INVOICE_STATUS_BAD_DEBT, INVOICE_STATUS_CLOSED
];
export const INVOICE_STATUSES = [
  INVOICE_STATUS_PAID, INVOICE_STATUS_PARTLY, INVOICE_STATUS_VOIDED, INVOICE_STATUS_PARTLY_VOIDED,
  INVOICE_STATUS_BAD_DEBT, INVOICE_STATUS_SENT, INVOICE_STATUS_DRAFT, INVOICE_STATUS_SENDING, INVOICE_STATUS_CLOSED
];

export const INVOICE_SENDED_STATUSES = [
  INVOICE_STATUS_PAID, INVOICE_STATUS_PARTLY, INVOICE_STATUS_BAD_DEBT, INVOICE_STATUS_SENT, INVOICE_STATUS_CLOSED
];

// MILESTONE STATUSES
export const MILESTONES_STATUS_IN_PROGRESS = 'in-progress';
export const MILESTONES_STATUS_AUTO_ACCEPTED = 'auto-accepted';
export const MILESTONES_STATUS_CLIENT_ACCEPTED = 'accepted-by-client';

// PROJECT STATUSES
export const PROJECT_STATUSES = [STATUS_ACTIVE, STATUS_INACTIVE];

// CLIENT STATUSES
export const CLIENT_STATUSES = [STATUS_ACTIVE, STATUS_INACTIVE];

// USER / EMPLOYEE STATUSES
export const USER_STATUSES = [STATUS_ACTIVE, STATUS_INACTIVE];

// VACATION STATUSES
export const VACATION_STATUSES = {
  APPROVED: 'approved',
  PENDING: 'pending',
  REJECT: 'reject'
};
