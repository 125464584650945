import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import addMonths from 'date-fns/addMonths';
import subMonths from 'date-fns/subMonths';

import axios from '../../services/axios';
import { parseToMonthInt } from '../../utils/helpers';
import { validateErrors, validateSalaryPayments } from '../../utils/validators';

const date = new Date();
const fromDate = subMonths(date, 5);
const toDate = addMonths(date, 1);
const fromMonth = parseToMonthInt(fromDate.getFullYear(), fromDate.getMonth() + 1);
const toMonth = parseToMonthInt(toDate.getFullYear(), toDate.getMonth() + 1);

export const initialState = {
  isLoading: false,
  error: undefined,
  data: [],
  meta: {},
  importResultData: {
    warnings: [],
    count_created: 0,
    count_skipped: 0
  },
  query: {
    filters: {
      id: [],
      user_name: '',
      bank_account_name: '',
      user_id: [],
      active_users: false,
      start_month: fromMonth,
      end_month: toMonth,
    },
    sort: '-date'
  },
  pageFilters: {
    employeeName: '',
    isEmployeeOpen: false,
    selectedEmployeeName: [],
  },
  monthsPeriod: {
    from: fromMonth,
    to: toMonth,
  },
  importModal: {
    modalId: 'importModal',
    isModalOpen: false,
    isAccountOpen: false,
    account: {},
    isSubmit: false,
  },
  importResultModal: {
    modalId: 'importResultModal',
    isModalOpen: false,
  },
  isImportLoading: false,
  isSubmit: false,
  formErrors: {}
};

export const fetchPaymentsAsync = createAsyncThunk(
  'salaryPayments/fetchPayments',
  async (arg, {
    rejectWithValue,
    getState
  }) => {
    try {
      const { salaryPayments: { query } } = getState();
      const {
        data,
        status
      } = await axios.get('/salary_payments', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const importPaymentsAsync = createAsyncThunk(
  'salaryPayments/importPayments',
  async (arg, {
    rejectWithValue,
    getState
  }) => {
    try {
      const { importModal: { account } } = getState().salaryPayments;
      const formErrors = validateErrors({ account: account?.id }, validateSalaryPayments);

      if (formErrors) {
        return rejectWithValue({
          isFormValidation: true,
          errors: formErrors
        });
      }

      const {
        data,
        status
      } = await axios.post(
        '/salary_payments/import_from_spreadsheet',
        { bank_account_id: account.id }
      );

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const paymentsSlice = createSlice({
  name: 'salaryPayments',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetModal: (state) => {
      state.importModal = initialState.importModal;
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setPageFilterData: (state, action) => {
      state.pageFilters = { ...state.pageFilters, ...action.payload };
    },
    setMonthPeriodData: (state, action) => {
      state.monthsPeriod = { ...state.monthsPeriod, ...action.payload };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload };
      state.formErrors = {};
    },
    setSubmit: (state, action) => {
      state.submit = action.payload;
    },
    setPage: (state, action) => {
      state.query.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaymentsAsync.pending, (state) => {
        state.isLoading = true;
        state.projects = undefined;
        state.error = undefined;
      })
      .addCase(fetchPaymentsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchPaymentsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.importModal.isSubmit = false;
      })
      .addCase(importPaymentsAsync.pending, (state) => {
        state.isImportLoading = true;
        state.projects = undefined;
        state.error = undefined;
      })
      .addCase(importPaymentsAsync.fulfilled, (state, action) => {
        state.isImportLoading = false;
        state.importModal.isModalOpen = false;
        state.importResultData = action.payload;
        state.importResultModal.isModalOpen = true;
        state.query.filters.bank_account_id = null;
        state.importModal.isSubmit = true;
      })
      .addCase(importPaymentsAsync.rejected, (state, action) => {
        state.isImportLoading = false;
        state.error = action.payload;
        state.formErrors = action.payload.errors;
        state.importModal.isSubmit = false;
      });
  },
});

export const {
  resetState,
  resetModal,
  setQueryFilter,
  setPageFilterData,
  setMonthPeriodData,
  setModalData,
  setSubmit,
  setPage
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
