import {
  PROJECT_INFO_FETCH_PROJECT, PROJECT_INFO_FETCH_STATISTIC,
  PROJECT_INFO_RESET_PROJECT, PROJECT_INFO_SET_ABORT, PROJECT_INFO_SET_DATA,
  PROJECT_INFO_SET_DATA_LOADING, PROJECT_INFO_SET_EDIT, PROJECT_INFO_SET_ERROR, PROJECT_INFO_SET_ERRORS,
  PROJECT_INFO_SET_PROJECT_LOADING, PROJECT_INFO_SET_SHOW_CLIENT_FIELDS, PROJECT_INFO_SET_SUBMIT
} from './types';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  isClientsLoading: true,
  isItemsLoading: true,
  isShowClientFields: false,
  project: {},
  projectStatistic: {},
  isEdit: false,
  projectEditProps: {
    client_product: null,
    client_company: null,
    status: '',
    type: '',
    type_custom_category: '',
    name: '',
    key: '',
    tracker: '',
    manager: null,
    isProductOpen: false,
    contract_type: null,
  },
  abortProps: {
    abortClients: {},
    abortItems: {},
    abortManagers: {}
  },
  errorMessage: '',
  errorStatus: null,
  errors: {},
  isClientDialogOpen: false,
};

export default function projectInfo(state = initialState, action) {
  switch (action.type) {
    case PROJECT_INFO_SET_ERRORS:
      return { ...state, errors: action.errors };
    case PROJECT_INFO_RESET_PROJECT:
      return initialState;
    case PROJECT_INFO_SET_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        errorStatus: action.status
      };
    case PROJECT_INFO_SET_SUBMIT:
      return {
        ...state,
        isSubmit: action.isSubmit
      };
    case PROJECT_INFO_SET_DATA_LOADING:
      return {
        ...state,
        [action.key]: action.value
      };
    case PROJECT_INFO_SET_PROJECT_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case PROJECT_INFO_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          [action.name]: action.controller
        }
      };
    case PROJECT_INFO_FETCH_PROJECT:
      return {
        ...state,
        project: action.project
      };
    case PROJECT_INFO_FETCH_STATISTIC:
      return {
        ...state,
        projectStatistic: action.projectStatistic
      };
    case PROJECT_INFO_SET_DATA:
      return {
        ...state,
        projectEditProps: {
          ...state.projectEditProps,
          ...action.projectData
        },
        errors: {}
      };
    case PROJECT_INFO_SET_EDIT:
      return {
        ...state,
        isEdit: action.isEdit,
        errors: {},
        projectEditProps: {
          ...state.project,
          sales_manager: state.project.client?.sales_manager,
          business_domains: state.project.business_domains || '',
          initial_client_problem: state.project.initial_client_problem || '',
          solution_we_provided: state.project.solution_we_provided || '',
          our_unique_expertise: state.project.our_unique_expertise || '',
          tracker: state.project.tracker || { name: '' },
          isHaveClient: !!state.project.client,
        }
      };
    case PROJECT_INFO_SET_SHOW_CLIENT_FIELDS:
      return {
        ...state,
        projectEditProps: {
          ...state.projectEditProps,
          client: null,
          client_product: null,
          contract_type: null,
        },
        errors: {
          ...state.errors,
          client: null,
          client_product: null,
          contract_type: null,
        },
        isShowClientFields: action.isClient,
      };
    default:
      return state;
  }
}
