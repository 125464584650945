import PropTypes from 'prop-types';

import styles from './sass/Badge.module.scss';

const Badge = ({ statuses = undefined, className = '', children }) => (
  <div className={styles.wrapper}>
    {statuses && (
      <div className={`${styles.statuses} ${className}`}>
        {Array.isArray(statuses) ? statuses.map((status, index) => (
          <div key={[status, index].join('_')}>
            {status}
          </div>
        )) : statuses}
      </div>
    )}
    {children}
  </div>
);

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  statuses: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
  ]),
};

export default Badge;
