import { useEffect } from 'react';
import { batch, shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import { useDispatch, useSelector } from '../../hooks/reduxToolkit';
import {
  fetchReferencesAsync,
  resetState,
  setModalData,
  setQueryParamsFilters,
} from '../../toolkitStore/helpCenter/slice';
import Question from '../Icons/Question';
import Portal from '../Portal/Portal';
import HelpCenterArticle from './HelpCenterArticle';
import HelpCenterDeleteModal from './HelpCenterDeleteModal';
import HelpCenterModal from './HelpCenterModal';
import HelpCenterReferences from './HelpCenterReferences';

import styles from './sass/HelpCenter.module.scss';

const HelpCenter = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const modalContainer = document.getElementById('modal-container');

  const { referenceVisible, isArticleOpen } = useSelector(({ helpCenter }) => ({
    referenceVisible: helpCenter.helpCenterModal.referenceVisible,
    isArticleOpen: helpCenter.helpCenterModal.isArticleOpen,
  }), shallowEqual);

  const isOpen = referenceVisible || isArticleOpen;

  const handleClickHelpButton = () => {
    if (isOpen) {
      dispatch(setModalData({ referenceVisible: false, isArticleOpen: false }));
    } else {
      batch(() => {
        dispatch(setQueryParamsFilters({ hydra_url: `${process.env.NEXT_PUBLIC_BASE_URL}${router.pathname}` }));
        dispatch(fetchReferencesAsync());
      });
    }
  };

  const resetStateOnRouteChange = () => {
    dispatch(resetState());
  };

  useEffect(() => {
    router.events.on('routeChangeStart', resetStateOnRouteChange);

    return () => {
      router.events.off('routeChangeStart', resetStateOnRouteChange);
    };
  }, []);

  return (
    <div className={styles.helpContainer}>
      <button
        type="button"
        onClick={handleClickHelpButton}
        className={`${styles.help} ${isOpen ? styles.open : ''}`}
        aria-label="Support"
      >
        <Question width={24} height={24} />
      </button>
      {isOpen && (
        <Portal container={modalContainer}>
          <HelpCenterReferences />
          <HelpCenterArticle />
          <HelpCenterModal />
          <HelpCenterDeleteModal />
        </Portal>
      )}
    </div>
  );
};

export default HelpCenter;
