import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import * as httpStatusCodes from '../../constants/httpStatusCodes';
import axios from '../../services/axios';
import { sortBy } from '../../utils/arrayHelpers';

export const initialState = {
  isLoading: true,
  isPermissionsUpdating: false,
  application: undefined,
  applicationPermissions: [],
  allPermissions: [],
  modal: {
    modalId: '',
    isModalOpen: false,
  },
  permissionsData: {
    permissions: [],
    selectedPermissions: [],
    addPermissions: [],
    removePermissions: [],
    disabledPermissions: [],
    group: '',
  },
  deletePermission: null,
  isDeleteAll: false,
};

export const getAllPermissions = createAsyncThunk(
  'applicationInfo/getAllPermissions',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/permissions');

      return status === httpStatusCodes.OK ? sortBy(data, 'group') : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPermissionsByApplicationId = createAsyncThunk(
  'applicationInfo/getApplicationPermissions',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/applications/${id}/permissions`);

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getApplicationById = createAsyncThunk(
  'applicationInfo/getApplicationById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/applications/${id}`);

      if (status === httpStatusCodes.OK) {
        await dispatch(getAllPermissions());
        await dispatch(getPermissionsByApplicationId({ id: data.id }));

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePermissionsForApplication = createAsyncThunk(
  'applicationInfo/updatePermissionsForApplication',
  async ({ addPermissions, removePermissions }, { dispatch, rejectWithValue, getState }) => {
    try {
      const { application } = getState().applicationInfo;

      const { data, status } = await axios.post(`/applications/${application.id}/permissions`, {
        add_permissions: addPermissions, remove_permissions: removePermissions
      });

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getPermissionsByApplicationId({ id: application.id }));
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const applicationInfoSlice = createSlice({
  name: 'applicationInfo',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, action) => ({
      ...state, ...action.payload
    }),
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setPermissionsData: (state, action) => {
      state.permissionsData = { ...state.permissionsData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplicationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApplicationById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.application = action.payload;
      })
      .addCase(getApplicationById.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(getPermissionsByApplicationId.fulfilled, (state, action) => {
        state.applicationPermissions = action.payload;
      });

    builder
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.allPermissions = action.payload;
      });

    builder
      .addCase(updatePermissionsForApplication.pending, (state) => {
        state.isPermissionsUpdating = true;
      })
      .addCase(updatePermissionsForApplication.fulfilled, (state) => {
        state.isPermissionsUpdating = false;
        state.permissionsData = initialState.permissionsData;
        state.modal = initialState.modal;
        state.deletePermission = initialState.deletePermission;
        state.isDeleteAll = initialState.isDeleteAll;
      })
      .addCase(updatePermissionsForApplication.rejected, (state) => {
        state.isPermissionsUpdating = false;
      });
  },
});

export const {
  resetState,
  setData,
  setModalData,
  setPermissionsData,
} = applicationInfoSlice.actions;

export default applicationInfoSlice.reducer;
