import React, { useEffect, useRef } from 'react';
import { batch, shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';
import Editor from 'rich-markdown-editor';

import { HELP_ARTICLE_MANAGEMENT } from '../../constants/permissions';
import { useDispatch, useSelector } from '../../hooks/reduxToolkit';
import useMediaQuery from '../../hooks/useMediaQuery';
import useOnKeydown from '../../hooks/useOnKeydown';
import {
  fetchArticleAsync,
  resetState,
  setDeleteModalData,
  setModalData,
  setModalFields,
} from '../../toolkitStore/helpCenter/slice';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';
import Close from '../Icons/Close';

import styles from './sass/HelpCenter.module.scss';

const HelpCenterArticle = () => {
  const ref = useRef(null);
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery('(max-width: 767px)');
  const dispatch = useDispatch();

  const {
    data,
    modalProps,
    article,
    userPermissions,
  } = useSelector(({ helpCenter, user }) => ({
    data: helpCenter.data,
    modalProps: helpCenter.helpCenterModal,
    article: helpCenter.article,
    userPermissions: user.permissions,
  }), shallowEqual);

  useEffect(() => {
    if (data[0]?.id) {
      dispatch(fetchArticleAsync({ id: data[0]?.id, url: data[0]?.help_url }));
    }
  }, [data]);

  useOnKeydown(ref, 'Escape', () => {
    if (modalProps.isArticleOpen) {
      dispatch(resetState());
    }
  });

  return (
    <div
      ref={ref}
      className={`
        ${styles.articleWr} 
        ${modalProps.isArticleOpen ? styles.active : ''} 
        ${isMobile ? styles.mobile : ''}
      `}
    >
      <div className={styles.title}>
        {article.title}
        <IconButton
          icon={<Close />}
          variant="ghost"
          ariaLabel="closeModal"
          onClick={() => dispatch(resetState())}
          className={styles.closeModal}
        />
      </div>
      {article?.text ? <Editor value={article.text} readOnly className={styles.articleContent} /> : null}
      <div className={styles.goToKnowlege}>
        <a href={modalProps.helpUrl} target="_blank" rel="noopener noreferrer">{t('goToKnowledgeBase')}</a>
        {userPermissions.includes(HELP_ARTICLE_MANAGEMENT) && (
          <div className={styles.bottomButtons}>
            <Button
              ariaLabel="editArticle"
              variant="outline"
              color="default"
              onClick={() => {
                batch(() => {
                  dispatch(setModalData({ isModalOpen: true, isEdit: true }));
                  dispatch(setModalFields({ id: data[0]?.id, link: data[0]?.help_url || '' }));
                });
              }}
            >
              {t('edit')}
            </Button>
            <Button
              ariaLabel="deleteArticle"
              variant="outline"
              color="error"
              onClick={() => dispatch(setDeleteModalData(
                { isModalOpen: true, deleteId: article.id, deleteTitle: article.title }
              ))}
            >
              {t('delete')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HelpCenterArticle;
