import {
  INVOICES_DEBTORS_FETCH_DATA, INVOICES_DEBTORS_SET_EXPANDED_ROW, INVOICES_DEBTORS_SET_QUERY_PARAMS,
  INVOICES_DEBTORS_SET_TABLE_HEAD_DATA 
} from './types';

export const initialState = {
  data: [],
  notApprovedHours: 0,
  expandedRow: null,
  queryParams: {
    filters: {
      manager_name: '',
    },
    sort: '',
  },
  tableHeadData: {
    managerName: '',
  },
};

export default function invoicesDebtors(state = initialState, action) {
  switch (action.type) {
    case INVOICES_DEBTORS_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...action.queryParams.params,
          filters: {
            ...state.queryParams.filters,
            ...action.queryParams.filters,
          },
        },
      };
    case INVOICES_DEBTORS_FETCH_DATA:
      return {
        ...state,
        data: action.data,
        notApprovedHours: action.notApprovedHours,
      };
    case INVOICES_DEBTORS_SET_TABLE_HEAD_DATA:
      return {
        ...state,
        tableHeadData: {
          ...state.tableHeadData,
          ...action.data
        },
      };
    case INVOICES_DEBTORS_SET_EXPANDED_ROW:
      return {
        ...state,
        expandedRow: action.row,
      };
    default:
      return state;
  }
}
