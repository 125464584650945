import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FIXED_HOLIDAY } from '../../constants/common';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { formatStringDate, queryStringify } from '../../utils/helpers';
import { validateAddOrEditHoliday, validateErrors } from '../../utils/validators';

const today = new Date();

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isHolidaySubmit: false,
  isDataLoading: true,
  isDeleteSubmit: false,
  deleteModalId: null,
  queryParams: {
    filters: {
      name: '',
      type: '',
      show_inactive: false,
      start_date: '',
      end_date: '',
    },
    sort: 'date',
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  modalProps: {
    modalId: 'holidayModal',
    isModalOpen: false
  },
  holidayFields: {
    holidayId: null,
    date: today,
    isDatepickerOpen: false,
    name: '',
    type: 'fixed',
    description: '',
    startYear: '',
    endYear: '',
    showTypes: false,
  },
  holidayErrors: {},
  tableHeadData: {
    holidayInputValue: '',
  },
  datepicker: undefined,
};

export const fetchHolidays = createAsyncThunk(
  'systemsHolidays/fetchHolidays',
  async (_, { rejectWithValue, getState, signal }) => {
    const { queryParams } = getState().systemsHolidays;

    try {
      const { data, status } = await axios.get(`/holidays?${queryStringify(queryParams)}`, { signal });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditHoliday = createAsyncThunk(
  'systemsHolidays/addOrEditHoliday',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const { holidayFields } = getState().systemsHolidays;
    const errors = validateErrors(
      holidayFields,
      validateAddOrEditHoliday,
      { type: holidayFields.type }
    );

    if (errors) {
      return rejectWithValue({ isFormValidation: true, errors });
    }

    try {
      const {
        holidayId, date, name, type, description, startYear, endYear
      } = holidayFields;
      let url = '/holidays';
      let method = httpRequestMethods.POST;

      if (holidayId) {
        url += `/${holidayId}`;
        method = httpRequestMethods.PUT;
      }

      const body = {
        date: formatStringDate(date, 'yyyy-MM-dd'),
        name,
        type,
        description
      };

      if (type === FIXED_HOLIDAY) {
        body.start_year = startYear;
        body.end_year = endYear;
      }

      const { data, status } = await axios({
        url,
        method,
        data: body
      });

      if (status === 200 || status === 201) {
        dispatch(fetchHolidays());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteHoliday = createAsyncThunk(
  'systemsHolidays/deleteHoliday',
  async ({ id }, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/holidays/${id}`);

      if (status === 204) {
        dispatch(fetchHolidays());

        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemsHolidaysSlice = createSlice({
  name: 'systemsHolidays',
  initialState,
  reducers: {
    resetFilters: (state) => {
      state.tableHeadData = initialState.tableHeadData;
      state.datepicker = initialState.datepicker;
      state.queryParams = initialState.queryParams;
    },
    resetDatepicker: (state) => {
      state.datepicker = initialState.datepicker;
    },
    setDatePickerData: (state, action) => {
      state.datepicker = action.payload;
    },
    setTableHeadData: (state, action) => {
      state.tableHeadData = { ...state.tableHeadData, ...action.payload };
    },
    toggleModal: (state, action) => {
      state.modalProps.isModalOpen = action.payload;

      if (!action.payload) {
        state.holidayFields = initialState.holidayFields;
        state.holidayErrors = initialState.holidayErrors;
        state.datepicker = initialState.datepicker;
      }
    },
    setHolidayErrors: (state, action) => {
      state.holidayErrors = action.payload;
    },
    setHolidayData: (state, action) => {
      state.holidayFields = { ...state.holidayFields, ...action.payload };
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHolidays.pending, (state) => {
        state.isDataLoading = true;
      })
      .addCase(fetchHolidays.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
        state.isDataLoading = false;
      })
      .addCase(fetchHolidays.rejected, (state) => {
        state.isDataLoading = false;
      });
    builder
      .addCase(addOrEditHoliday.pending, (state) => {
        state.isHolidaySubmit = true;
      })
      .addCase(addOrEditHoliday.fulfilled, (state) => {
        state.isHolidaySubmit = false;
        state.modalProps = initialState.modalProps;
        state.holidayFields = initialState.holidayFields;
        state.holidayErrors = initialState.holidayErrors;
        state.datepicker = initialState.datepicker;
      })
      .addCase(addOrEditHoliday.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.holidayErrors = action.payload.errors;
        }
        state.isHolidaySubmit = false;
      });
    builder
      .addCase(deleteHoliday.pending, (state) => {
        state.isDeleteSubmit = true;
      })
      .addCase(deleteHoliday.fulfilled, (state) => {
        state.isDeleteSubmit = false;
        state.deleteModalId = null;
      })
      .addCase(deleteHoliday.rejected, (state) => {
        state.isDeleteSubmit = false;
      });
  }
});

export const {
  resetFilters,
  setPage,
  resetDatepicker,
  setDatePickerData,
  setTableHeadData,
  setHolidayErrors,
  setQueryParamsFilters,
  setSort,
  setHolidayData,
  toggleModal,
  setData,
} = systemsHolidaysSlice.actions;

export default systemsHolidaysSlice.reducer;
