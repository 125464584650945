import PropTypes from 'prop-types';

import styles from '../sass/NotificationsList.module.scss';

const NotificationsDateSeparator = ({ date }) => {
  return (
    <div className={styles.separator}>
      <span>{date}</span>
    </div>
  );
};

NotificationsDateSeparator.propTypes = {
  date: PropTypes.string.isRequired,
};

export default NotificationsDateSeparator;
