import PropTypes from 'prop-types';

import useCopyToClipboard from '../../hooks/useCopyToClipboard';
import CheckCircleIcon from '../Icons/CheckCircleIcon';
import CopyIcon from '../Icons/CopyIcon';

import styles from './sass/ClipboardCopy.module.scss';

const ClipboardCopy = ({
  icon = null, children = null, className = '', copyText = null,
}) => {
  const [value, copy, setCopiedText] = useCopyToClipboard();

  const handleCopy = async ({ target }) => {
    if (target.nodeName !== 'A') {
      await copy(copyText);
    }
  };

  const handleMouseLeave = () => {
    setCopiedText(null);
  };

  return (
    <div
      role="presentation"
      onClick={handleCopy}
      onMouseLeave={handleMouseLeave}
      className={`${styles.wrapper} ${className}`}
    >
      {children}
      <span className={styles.icon}>
        {value ? <CheckCircleIcon /> : (icon || <CopyIcon />)}
      </span>
    </div>
  );
};

ClipboardCopy.propTypes = {
  children: PropTypes.node,
  copyText: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
};

export default ClipboardCopy;
