import { shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';

import Modal from '../../../components/Modal/Modal';
import { useDispatch, useSelector } from '../../../hooks/reduxToolkit';
import useDraggableScroll from '../../../hooks/useDraggableScroll';
import { resetModal } from '../../../toolkitStore/notificationsModal/slice';
import NotificationsChips from './NotificationsChips';
import NotificationList from './NotificationsList';
import NotificationsTabs from './NotificationsTabs';

import styles from '../sass/NotificationsModal.module.scss';

const NotificationsModal = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { containerRef, canScrollLeft, canScrollRight } = useDraggableScroll();

  const { modalProps } = useSelector(({ notificationsModal }) => ({
    modalProps: notificationsModal.modalProps,
  }), shallowEqual);

  return (
    <Modal
      id="notificationsModal"
      title={t('notifications')}
      modalProps={modalProps}
      bodyClassName={styles.body}
      contentClassName={styles.content}
      isShowCancelBtn={false}
      closeModal={() => dispatch(resetModal())}
    >
      <div className={styles.header}>
        <div className={styles.separator} />
        <NotificationsTabs />
        <div className={styles.separator} />
        <NotificationsChips
          containerRef={containerRef}
          canScrollLeft={canScrollLeft}
          canScrollRight={canScrollRight}
        />
      </div>
      <NotificationList />
    </Modal>
  );
};

export default NotificationsModal;
