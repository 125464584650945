import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GUARD_NAME_WEB, SORT_BY_NAME } from '../../constants/common';
import { STATUS_ACTIVE } from '../../constants/status';
import { LIST_SIZE_PER_PAGE_25 } from '../../constants/values';
import axios from '../../services/axios';

export const initialState = {
  isLoading: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      name: '',
      email: '',
      lead_name: '',
      manager_name: '',
      department_name: '',
      status: [STATUS_ACTIVE],
      roles: [],
      position_id: [],
    },
    with: ['manager', 'lead', 'roles', 'department', 'positions'],
    sort: SORT_BY_NAME,
    page: null,
    per_page: LIST_SIZE_PER_PAGE_25
  },
  isMobileFilterOpen: false,
  rolesList: [],
  tipProps: {
    show: false,
    id: '',
    style: {}
  }
};

export const fetchEmployees = createAsyncThunk(
  'employees/fetchEmployees',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { employees: { query } } = getState();
      const { data, status } = await axios.get('/users', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchRoles = createAsyncThunk(
  'employees/fetchRoles',
  async (arg, { rejectWithValue }) => {
    const params = arg?.isSystemPermissionsPage ? {} : { filters: { guard_name: GUARD_NAME_WEB } };

    try {
      const { data, status } = await axios.get('/roles/search', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
      state.query.page = initialState.query.page;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    toggleMobileFilter: (state, action) => {
      state.isMobileFilterOpen = action.payload;
    },
    showTooltip: (state, action) => {
      state.tipProps = { ...state.tipProps, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rolesList = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setData,
  resetState,
  toggleMobileFilter,
} = employeesSlice.actions;

export default employeesSlice.reducer;
