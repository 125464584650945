import PropTypes from 'prop-types';

import { useDispatch } from '../../hooks/reduxToolkit';
import useIsPresent from '../../hooks/useIsPresent';
import useTimeoutFn from '../../hooks/useTimeoutFn';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { dismissNotification } from '../../toolkitStore/notifications/slice';

import styles from './sass/NotificationItem.module.scss';

const notificationIcons = {
  success: <img src="/images/icons/alert-success.svg" alt="alert-success" />,
  error: <img src="/images/icons/alert-error.svg" alt="alert-error" />,
};

const NotificationItem = ({
  notification,
  notification: {
    id,
    autoHideDuration,
    autoHide = true,
    message,
    onClose,
    type = 'error',
    isUseCustomContent,
  },
  contentComponent = () => null,
}) => {
  const dispatch = useDispatch();
  const [isPresent, ref] = useIsPresent();

  const handleDismiss = () => {
    if (isPresent && autoHide) {
      dispatch(dismissNotification(id));
    }
  };

  const [, cancel, reset] = useTimeoutFn(
    handleDismiss,
    autoHideDuration ?? 6000
  );

  const onMouseEnter = () => {
    cancel();
  };

  const onMouseLeave = () => {
    reset();
  };

  useUpdateEffect(() => {
    if (!isPresent) {
      onClose?.();
    }
  }, [isPresent]);

  return (
    <li
      ref={ref}
      className={`${styles.item} ${styles[type]}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.contentWrapper}>
        {notificationIcons[type]}
        <div>
          {(isUseCustomContent && contentComponent)
            ? contentComponent({ notification })
            : message.map((item) => <span key={item}>{item}</span>)}
        </div>
      </div>
    </li>
  );
};

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    id: PropTypes.string,
    autoHideDuration: PropTypes.number,
    autoHide: PropTypes.bool,
    isUseCustomContent: PropTypes.bool,
    message: PropTypes.array,
    onClose: PropTypes.func,
    type: PropTypes.oneOf(['error', 'success']),
  }).isRequired,
  contentComponent: PropTypes.func,
};

export default NotificationItem;
