import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { newValidateErrors, validateAgreementService, validateErrors } from '../../utils/validators';

const today = new Date();

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      id: [],
      name: '',
      client_id: [],
      client_agreement_id: [],
      date: '',
      show_not_expired: false,
    },
    sort: '-start_date',
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    with: ['department'],
  },
  modalFields: {
    name: '',
    department: null,
    rate: '',
    rateType: '',
    overtimeRate: '',
    startDate: today,
    endDate: null
  },
  modalProps: {
    modalId: 'serviceModal',
    serviceId: null,
    isModalOpen: false,
    isStartDateOpen: false,
    isEndDateOpen: false,
    isRateTypeOpen: false,
    isDepartmentOpen: false,
    searchDepartmentValue: ''
  },
  deleteModal: {
    modalId: 'deleteModal',
    isModalOpen: false,
    service: null,
  },
  modalErrors: {},
};

export const fetchAgreementServicesAsync = createAsyncThunk(
  'agreementServices/fetchAgreementServices',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { query } = getState().agreementServices;
      const { data, status } = await axios.get('/agreement_services', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditServiceAsync = createAsyncThunk(
  'agreementServices/addOrEditService',
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      const { modalFields, modalProps } = getState().agreementServices;
      const errors = validateErrors(modalFields, validateAgreementService, {
        rateType: modalFields.rateType,
      });

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let url = '/agreement_services';

      if (modalProps.serviceId) {
        method = 'PUT';
        url += `/${modalProps.serviceId}`;
      }

      const { query } = Router;
      const { data, status } = await axios({
        method,
        url,
        data: {
          name: modalFields.name,
          department_id: modalFields.department.id,
          rate: modalFields.rate,
          rate_type: modalFields.rateType,
          overtime_hourly_rate: modalFields.overtimeRate,
          start_date: modalFields.startDate ? formatStringDate(modalFields.startDate, 'yyyy-MM-dd') : null,
          end_date: modalFields.endDate ? formatStringDate(modalFields.endDate, 'yyyy-MM-dd') : null,
          client_id: query.id,
          client_agreement_id: query.agreement
        }
      });

      if (status === 201 || status === 200) {
        dispatch(fetchAgreementServicesAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteServicesAsync = createAsyncThunk(
  'agreementServices/deleteServices',
  async ({ serviceId }, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/agreement_services/${serviceId}`);

      if (status === 204) {
        dispatch(fetchAgreementServicesAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const agreementServicesSlice = createSlice({
  name: 'agreementServices',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setModalFields: (state, action) => {
      state.modalFields = { ...state.modalFields, ...action.payload };
      state.modalErrors = {
        ...state.modalErrors,
        ...newValidateErrors({ ...action.payload }, validateAgreementService, {
          rateType: state.modalFields.rateType,
          endDate: state.modalFields.endDate
        })
      };
    },
    resetModalFields: (state) => {
      state.modalFields = initialState.modalFields;
      state.modalProps = initialState.modalProps;
      state.modalErrors = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgreementServicesAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAgreementServicesAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchAgreementServicesAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addOrEditServiceAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.modalErrors = {};
      })
      .addCase(addOrEditServiceAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.modalProps = initialState.modalProps;
        state.modalFields = initialState.modalFields;
        state.modalErrors = {};
      })
      .addCase(addOrEditServiceAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.modalErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      })
      .addCase(deleteServicesAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(deleteServicesAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.deleteModal = initialState.deleteModal;
      })
      .addCase(deleteServicesAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setModalData,
  setModalFields,
  setData,
  resetModalFields,
  resetState,
} = agreementServicesSlice.actions;

export default agreementServicesSlice.reducer;
