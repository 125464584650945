import PropTypes from 'prop-types';

import { USER_STATUSES } from '../../constants/status';
import Avatar from '../Avatar/Avatar';
import Badge from '../Badge/Badge';
import Vacation from '../Badge/Vacation';

const UserBadge = ({
  user,
  width = 24,
  height = 24,
  photoLinkSize = 48,
  sizeStatus = 'small'
}) => (
  <Badge statuses={user?.on_vacation
    ? (<Vacation vacation="vacation" sizeStatus={sizeStatus} />)
    : undefined}
  >
    <Avatar
      width={width}
      height={height}
      image={user[`photo${photoLinkSize}_link`] || '/images/avatar.svg'}
      alt={user.name}
    />
  </Badge>
);

UserBadge.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    status: PropTypes.oneOf(USER_STATUSES),
    on_vacation: PropTypes.bool,
  }).isRequired,
  photoLinkSize: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  sizeStatus: PropTypes.string
};

export default UserBadge;
