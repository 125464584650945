import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_BY_NAME } from '../../constants/common';
import { INVOICE_ITEM_CATEGORY_ENUM } from '../../constants/enums';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { validateErrors, validateInvoiceItemCategoryForm } from '../../utils/validators';
import { fetchEnumsAsync } from '../enums/slice';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isLoading: false,
  isSubmit: false,
  isActiveSubmit: false,
  queryParams: {
    filters: {
      name: '',
      show_inactive: false,
    },
    sort: SORT_BY_NAME,
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  nameHeadValue: '',
  modal: {
    modalId: 'invoiceItemCategory',
    isModalOpen: false,
  },
  formFields: {
    categoryId: null,
    name: '',
    xeroId: '',
    trashed: false,
  },
  formErrors: {},
};

export const getInvoiceItemCategories = createAsyncThunk(
  'invoiceItemCategory/getInvoiceItemCategories',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().invoiceItemCategory;
      const { data, status } = await axios.get('/invoice_item_categories', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeInvoiceItemCategory = createAsyncThunk(
  'invoiceItemCategory/removeInvoiceItemCategory',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/invoice_item_categories/${id}`);

      if (status === 204) {
        dispatch(getInvoiceItemCategories());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const restoreInvoiceItemCategory = createAsyncThunk(
  'invoiceItemCategory/restoreInvoiceItemCategory',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.put(`/invoice_item_categories/${id}/restore`);

      if (status === 200) {
        dispatch(getInvoiceItemCategories());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditInvoiceItemCategory = createAsyncThunk(
  'invoiceItemCategory/createOrEditInvoiceItemCategory',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().invoiceItemCategory;
      const errors = validateErrors(formFields, validateInvoiceItemCategoryForm);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/invoice_item_categories';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const { data, status } = await axios({
        url,
        method,
        data: {
          name: formFields.name,
          xero_account_code: formFields.xeroId,
        },
      });

      if (status === 201 || status === 200) {
        dispatch(getInvoiceItemCategories());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const invoiceItemCategorySlice = createSlice({
  name: 'invoiceItemCategory',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setTableHeadInput: (state, action) => {
      state.nameHeadValue = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateInvoiceItemCategoryForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoiceItemCategories.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getInvoiceItemCategories.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getInvoiceItemCategories.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(removeInvoiceItemCategory.pending, (state) => {
        state.error = undefined;
        state.isActiveSubmit = true;
      })
      .addCase(removeInvoiceItemCategory.fulfilled, (state) => {
        state.isActiveSubmit = false;
        state.formFields.trashed = true;
      })
      .addCase(removeInvoiceItemCategory.rejected, (state, action) => {
        state.error = action.payload;
        state.isActiveSubmit = false;
      });
    builder
      .addCase(restoreInvoiceItemCategory.pending, (state) => {
        state.error = undefined;
        state.isActiveSubmit = true;
      })
      .addCase(restoreInvoiceItemCategory.fulfilled, (state) => {
        state.isActiveSubmit = false;
        state.formFields.trashed = false;
      })
      .addCase(restoreInvoiceItemCategory.rejected, (state, action) => {
        state.error = action.payload;
        state.isActiveSubmit = false;
      });
    builder
      .addCase(createOrEditInvoiceItemCategory.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditInvoiceItemCategory.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createOrEditInvoiceItemCategory.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });

    builder
      .addCase(fetchEnumsAsync.pending, (state, { meta }) => {
        if (meta.arg.enumName === INVOICE_ITEM_CATEGORY_ENUM) {
          state.isLoading = true;
        }
      });
  }
});

export const {
  setModalData,
  setPage,
  setSort,
  setQueryParamsFilters,
  resetModal,
  setTableHeadInput,
  setFormFields,
  setFormErrors,
  setValidateFormField,
  resetState,
} = invoiceItemCategorySlice.actions;

export default invoiceItemCategorySlice.reducer;
