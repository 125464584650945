import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import {
  INVOICES_FETCH_CATEGORIES, INVOICES_FETCH_DATA, INVOICES_FETCH_INVOICE, INVOICES_FETCH_NUMBER_OF,
  INVOICES_RESET_CUSTOM_MODAL_DATA, INVOICES_SET_ABORT, INVOICES_SET_DATEPICKER_DATA, INVOICES_SET_EXPANDED_ROW,
  INVOICES_SET_INITIAL_STATE, INVOICES_SET_LOADING,
  INVOICES_SET_MODAL_DATA, INVOICES_SET_MODAL_ERROR,
  INVOICES_SET_QUERY_PARAMS,
  INVOICES_SET_SELECTED_INVOICES, INVOICES_SET_SENDING_INVOICES, INVOICES_SET_TABLE_HEAD_DATA,
  INVOICES_TOGGLE_TABLE_MORE
} from './types';

export const initialState = {
  isLoading: true,
  data: [],
  meta: {},
  isLinksLoading: true,
  expandedRow: null,
  numberOfMyDrafts: 0,
  numberOfRecommended: 0,
  categories: [],
  numberOfDebtors: 0,
  invoice: {},
  selectedInvoices: [],
  sendingInvoices: [],
  queryParams: {
    filters: {
      show_own: false,
      search: '',
      start_date: '',
      end_date: '',
      client: { id: [], name: '' },
      project: { id: [], name: '' },
      manager: { id: [], name: '' },
      payment_method: { id: [], name: '' },
      status: []
    },
    with: [
      'client',
      'payment_method',
      'projects',
      'client_company',
      'parts'
    ],
    sort: '-created_at',
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  customInvoiceModal: {
    isModalOpen: false,
    modalId: 'customInvoiceModal',
    category: null,
    company: null,
    paymentMethod: null,
    isCompanyOpen: false,
    isPaymentMethodOpen: false,
    companySearchValue: '',
    paymentMethodSearchValue: '',
    invoiceName: '',
    itemName: '',
    amount: '',
    currency: 'USD',
    isCurrencyOpen: false,
    isCategoryOpen: false,
    comment: '',
  },
  warningModal: {
    isModalOpen: false,
    modalId: 'warningModal',
    invoice: null,
  },
  modalErrors: {},
  tableHeadData: {
    invoiceName: '',
    clientName: '',
    projectName: '',
    pmName: '',
    isClientOpen: false,
    isProjectOpen: false,
    isPmOpen: false,
    isStatusOpen: false,
    isPaymentOpen: false,
  },
  abortProps: {
    abortInvoices: {},
  },
  datepicker: undefined,
  tableMoreProps: {
    isOpen: false,
    id: '',
    style: {}
  },
  isSubmitting: false,
};

export default function invoices(state = initialState, action) {
  switch (action.type) {
    case INVOICES_SET_LOADING:
      return {
        ...state,
        [action.key]: action.isLoading,
      };
    case INVOICES_SET_INITIAL_STATE:
      return {
        ...initialState,
        queryParams: {
          ...initialState.queryParams,
          filters: {
            ...initialState.queryParams.filters,
            show_own: state.queryParams.filters.show_own,
          },
        },
      };
    case INVOICES_SET_SENDING_INVOICES:
      return {
        ...state,
        sendingInvoices: action.sendingInvoices,
      };
    case INVOICES_TOGGLE_TABLE_MORE:
      return {
        ...state,
        tableMoreProps: action.tableMoreProps
      };
    case INVOICES_SET_SELECTED_INVOICES:
      return {
        ...state,
        selectedInvoices: action.selectedInvoices,
      };
    case INVOICES_SET_DATEPICKER_DATA:
      return {
        ...state,
        datepicker: action.data,
      };
    case INVOICES_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          ...action.params,
          filters: {
            ...state.queryParams.filters,
            ...action.params.filters
          }
        }
      };
    case INVOICES_FETCH_INVOICE:
      return {
        ...state,
        invoice: action.invoice,
      };
    case INVOICES_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    case INVOICES_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          [action.key]: action.controller
        }
      };
    case INVOICES_SET_TABLE_HEAD_DATA:
      return {
        ...state,
        tableHeadData: {
          ...state.tableHeadData,
          ...action.data
        },
      };
    case INVOICES_SET_EXPANDED_ROW:
      return {
        ...state,
        expandedRow: action.row,
      };
    case INVOICES_RESET_CUSTOM_MODAL_DATA:
      return {
        ...state,
        customInvoiceModal: initialState.customInvoiceModal,
        modalErrors: initialState.modalErrors,
      };
    case INVOICES_SET_MODAL_ERROR:
      return {
        ...state,
        modalErrors: action.errors,
      };
    case INVOICES_FETCH_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    case INVOICES_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case INVOICES_FETCH_NUMBER_OF:
      return {
        ...state,
        ...action.data
      };
    default:
      return state;
  }
}
