import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_BY_DEPARTMENT_NAME } from '../../constants/common';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { sortBy } from '../../utils/arrayHelpers';
import { createQueryUrl, formatStringDate, getMonthForQueryState } from '../../utils/helpers';

const date = new Date();
const { fromMonth, toMonth } = getMonthForQueryState();

export const initialState = {
  isLoading: false,
  error: undefined,
  tableData: {
    data: [],
    header: [],
    footer: [],
    reports: [],
  },
  dateInput: {
    start_month: '',
    isDateOpen: false,
    month: formatStringDate(date)
  },
  pageFilters: {
    isPartTime: false,
    isDynamic: false,
    departmentSearchValue: '',
    isDepartmentOpen: false,
    departmentIds: []
  },
  queryParams: {
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    sort: `${SORT_BY_DEPARTMENT_NAME}`,
    filters: {
      start_month: fromMonth,
      end_month: toMonth,
      department_name: ''
    }
  },
  monthsPeriod: {
    from: fromMonth,
    to: toMonth,
  }
};

export const fetchEmployeesReportAsync = createAsyncThunk(
  'reports/fetchEmployeesReportAsync',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { queryParams, queryParams: { sort, filters } } = getState().reportEmployees;

      const { data, status } = await axios.get('/reports/people_company', { params: queryParams });
      createQueryUrl(
        ['department_name', 'start_month', 'end_month'],
        [filters.department_name, filters.start_month, filters.end_month]
      );

      const { header, footer, reports } = data.reduce((acc, item) => ({
        header: [...acc.header, item.month],
        footer: [...acc.footer, {
          userCount: item.user_count,
          userCountPartTime: item.user_count_part_time,
          joinTotal: item.join_user_count,
          leftTotal: item.left_user_count
        }],
        reports: [...acc.reports, ...item.report]
      }), {
        header: [],
        footer: [],
        reports: []
      });

      const arrId = [...new Map(reports.map((e) => ([e.id, {
        id: e.id,
        name: e.name
      }])))];

      const parseData = arrId
        .reduce((acc, [id, obj]) => {
          const b = data.reduce((resAcc, { report }) => {
            const reportItem = report.find((item) => item.id === id);
            return [...resAcc, reportItem];
          }, []);

          return [...acc, { ...obj, data: b }];
        }, []);

      const sortData = sortBy(parseData, 'name');

      if (sort.includes('-')) {
        sortData.reverse();
      }

      return status === 200 ? {
        sortData,
        header,
        footer,
        reports
      } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const reportEmployeesSlice = createSlice({
  name: 'reportEmployees',
  initialState,
  reducers: {
    resetState: () => initialState,
    setSubmit: (state, { key, value }) => {
      state[key] = { ...state[key], ...value };
    },
    setPageFilterData: (state, action) => {
      state.pageFilters = { ...state.pageFilters, ...action.payload };
    },
    setMonthPeriodData: (state, action) => {
      state.monthsPeriod = { ...state.monthsPeriod, ...action.payload };
      if (!action.payload.from) {
        state.monthsPeriod.from = fromMonth;
      }
      if (!action.payload.to) {
        state.monthsPeriod.to = toMonth;
      }
    },
    setModalData: (state, action) => {
      state.dateInput = { ...state.dateInput, ...action.payload, };
    },
    modalSetErrors: (state, action) => { state.modalErrors = action.payload; },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload, };
    },
    setQueryParams: (state, action) => {
      state.queryParams = { ...state.queryParams, ...action.payload, };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeesReportAsync.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchEmployeesReportAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.tableData = {
          data: action.payload.sortData,
          header: action.payload.header,
          footer: action.payload.footer,
          reports: action.payload.reports
        };
      })
      .addCase(fetchEmployeesReportAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const {
  resetState,
  setSubmit,
  setPageFilterData,
  setMonthPeriodData,
  setModalData,
  modalSetErrors,
  setQueryParamsFilters,
  setQueryParams
} = reportEmployeesSlice.actions;

export default reportEmployeesSlice.reducer;
