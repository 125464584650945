import PropTypes from 'prop-types';

import styles from './sass/Badge.module.scss';

const Status = ({ status }) => (<div className={styles[status]} />);

Status.propTypes = {
  status: PropTypes.oneOf(['approved', 'rejected', 'pending']).isRequired,
};

export default Status;
