import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { validateErrors, validateSystemsPosition, validateSystemsPositionsInfo } from '../../utils/validators';

export const initialState = {
  position: undefined,
  data: [],
  links: {},
  meta: {},
  isSubmit: false,
  isDeleteSubmit: false,
  isLoader: false,
  queryParams: {
    filters: {
      position: {
        id: []
      },
      user: {
        name: ''
      },
      show_current: true,
    },
  },
  tableHeadInput: {
    userValue: ''
  },
  systemsPositionsInfoModal: {
    modalId: 'systemsPositionsInfoModal',
    isModalOpen: false,
    title: 'assignTo',
    isAssignOnPositions: false
  },
  formFields: {
    id: '',
    position: '',
    user: undefined,
    startDate: undefined,
    endDate: undefined,
  },
  positionsInfoDeleteDialog: {
    modalId: 'positionsInfoDeleteDialog',
    isModalOpen: false,
    id: '',
    name: ''
  },
  formErrors: {},
  singlePositionData: {},
};

export const fetchOneSystemsPositionInfo = createAsyncThunk(
  'positionsInfo/fetchPositionInfo',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/positions/${arg.id}`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSystemsPositionsInfo = createAsyncThunk(
  'positionsInfo/fetchPositionsInfo',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams } = getState().systemsPositionsInfo;

      const { data, status } = await axios.get('/users_positions', { params: queryParams });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editOrEditPositionsInfo = createAsyncThunk(
  'positionsInfo/editPositionsInfo',
  async (arg, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().systemsPositionsInfo;
      const errors = validateErrors(formFields, validateSystemsPositionsInfo);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/users_positions';
      let method = httpRequestMethods.POST;

      if (formFields.id && !formFields.isAssignOnPositions) {
        url += `/${formFields.id}`;
        method = httpRequestMethods.PUT;
      }

      const body = {
        user_id: formFields.employee.id,
        position_id: formFields.position.id,
        started_at: formatStringDate(formFields.startDate, 'y-MM-dd')
      };

      if (formFields.endDate) {
        body.completed_at = formatStringDate(formFields.endDate, 'y-MM-dd');
      }

      const { data, status } = await axios({ url, method, data: body });

      if (status === httpStatusCodes.CREATED || status === httpStatusCodes.OK) {
        dispatch(fetchSystemsPositionsInfo());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePositionsInfo = createAsyncThunk(
  'positionsInfo/deletePositionsInfo',
  async (arg, {
    dispatch, getState, fulfillWithValue, rejectWithValue
  }) => {
    try {
      const { positionsInfoDeleteDialog: { id } } = getState().systemsPositionsInfo;
      const { data, status } = await axios.delete(`/users_positions/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(fetchSystemsPositionsInfo());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemsPositionsInfoSlice = createSlice({
  name: 'systemsPositionsInfo',
  initialState,
  reducers: {
    setUserPosition: (state, action) => {
      state.position = action.payload;
    },
    setTableHeadInput: (state, action) => {
      state.tableHeadInput = { ...state.tableHeadInput, ...action.payload };
    },
    setQuery: (state, action) => {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateSystemsPosition(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };

      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = { ...state.formFields, ...action.payload };
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.systemsPositionsInfoModal = initialState.systemsPositionsInfoModal;
      state.positionsInfoDeleteDialog = initialState.positionsInfoDeleteDialog;
      state.formFields = initialState.formFields;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSystemsPositionsInfo.pending, (state) => {
        state.isLoader = true;
      })
      .addCase(fetchSystemsPositionsInfo.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.isLoader = false;
      })
      .addCase(fetchSystemsPositionsInfo.rejected, (state) => {
        state.isLoader = false;
      });
    builder
      .addCase(fetchOneSystemsPositionInfo.pending, (state) => {
        state.isLoader = true;
      })
      .addCase(fetchOneSystemsPositionInfo.fulfilled, (state, action) => {
        state.singlePositionData = action.payload;
        state.isLoader = false;
      })
      .addCase(fetchOneSystemsPositionInfo.rejected, (state) => {
        state.isLoader = false;
      });
    builder
      .addCase(editOrEditPositionsInfo.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(editOrEditPositionsInfo.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.systemsPositionsInfoModal = initialState.systemsPositionsInfoModal;
        state.isSubmit = false;
      })
      .addCase(editOrEditPositionsInfo.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(deletePositionsInfo.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
      })
      .addCase(deletePositionsInfo.fulfilled, (state) => {
        state.isSubmit = false;
        state.positionsInfoDeleteDialog = initialState.positionsInfoDeleteDialog;
      })
      .addCase(deletePositionsInfo.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
  }
});

export const {
  setUserPosition,
  setModalData,
  setPage,
  setSort,
  setQueryFilter,
  resetModal,
  setTableHeadInput,
  setFormFields,
  setFormErrors,
  setValidateFormField
} = systemsPositionsInfoSlice.actions;

export default systemsPositionsInfoSlice.reducer;
