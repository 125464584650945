import { useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import Loader from '../../components/Loaders/Loader';
import Notifications from '../../components/Notification/Notifications';
import Title from '../../components/Title/Title';
import { translateStatusCodeMap } from '../../constants/httpStatusCodes';
import { useSelector } from '../../hooks/redux';
import classNames from '../../utils/classNames';

import styles from './sass/Page.module.scss';

const Page = ({
  children,
  title = undefined,
  breadCrumbs = null,
  className = '',
  mainClassName = undefined,
  isLoading = false,
  navComponent = null,
  notificationComponent = null,
  bottomPanel = null,
  errorStatus = null,
  showBread = true,
  idBread = '',
  isLastCopy = false
}) => {
  const router = useRouter();
  const { t } = useTranslation(['common', 'error']);
  const isMinimized = useSelector(({ sidebar }) => sidebar.isMinimized, shallowEqual);
  const isSubMinimized = useSelector(({ sidebar }) => sidebar.isSubMinimized, shallowEqual);

  const createBreadCrumbs = useMemo(() => {
    const pathname = router.asPath.includes('?')
      ? router.asPath.substring(0, router.asPath.indexOf('?'))
      : router.asPath;

    const links = [...pathname.substring(1).split('/')];

    return links.map((item, index) => ({
      url: breadCrumbs?.replaceUrls?.[item] || links.slice(0, index + 1).join('/'),
      title: breadCrumbs?.[item] || t(item),
      active: index !== links.length - 1,
    }));
  }, [breadCrumbs]);

  const mainClasses = classNames(
    styles.main,
    mainClassName,
    {
      [styles.minSidebar]: isMinimized,
      [styles.minSubnav]: isSubMinimized && navComponent,
      [styles.subMenu]: !errorStatus && navComponent,
    }
  );

  return (
    <>
      <Head>
        <title>{title || t('title')}</title>
      </Head>
      {isLoading ? (
        <div className={styles.preloader}>
          <Loader />
        </div>
      ) : (
        <>
          <main className={mainClasses}>
            {!errorStatus && navComponent}
            {errorStatus ? (
              <div className={`${styles.content} ${styles.error}`}>
                <Title>{t(`error:${translateStatusCodeMap[errorStatus]}`)}</Title>
              </div>
            ) : (
              <div className={`${styles.content} ${className}`} id={idBread}>
                {showBread && (
                  <BreadCrumbs
                    isLastCopy={isLastCopy}
                    data={createBreadCrumbs}
                  />
                )}
                {children}
              </div>
            )}
            {bottomPanel}
          </main>
        </>
      )}
      <Notifications contentComponent={notificationComponent} />
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  breadCrumbs: PropTypes.shape({
    replaceUrls: PropTypes.shape({})
  }),
  className: PropTypes.string,
  mainClassName: PropTypes.string,
  isLoading: PropTypes.bool,
  navComponent: PropTypes.node,
  notificationComponent: PropTypes.func,
  bottomPanel: PropTypes.node,
  errorStatus: PropTypes.number,
  showBread: PropTypes.bool,
  idBread: PropTypes.string,
  isLastCopy: PropTypes.bool
};

export default Page;
