import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

export const initialState = {
  isConverting: false,
  error: undefined,
};

export const convertUpworkTransactionAsync = createAsyncThunk(
  'conversion/convertUpworkTransaction',
  async ({ file }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.post(
        '/converter/convert_upwork_transactions',
        { file },
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (status === 200) {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', file.name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const conversionSlice = createSlice({
  name: 'conversion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(convertUpworkTransactionAsync.pending, (state) => {
        state.isConverting = true;
      })
      .addCase(convertUpworkTransactionAsync.fulfilled, (state) => {
        state.isConverting = false;
      })
      .addCase(convertUpworkTransactionAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.isConverting = false;
      });
  },
});

export default conversionSlice.reducer;
