import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import {
  PROJECT_PEOPLE_ADD_MODAL_SET_DATA, PROJECT_PEOPLE_ADD_MODAL_SET_ERROR,
  PROJECT_PEOPLE_FETCH_GROUPS,
  PROJECT_PEOPLE_FETCH_PEOPLE, PROJECT_PEOPLE_FETCH_ROLES, PROJECT_PEOPLE_HANDLE_USER_INPUT, PROJECT_PEOPLE_SELECT_USER,
  PROJECT_PEOPLE_SET_ABORT, PROJECT_PEOPLE_SET_PAGE, PROJECT_PEOPLE_SET_QUERY_PARAMS_FILTERS, PROJECT_PEOPLE_SET_SORT,
  PROJECT_PEOPLE_SET_SUBMIT, PROJECT_PEOPLE_TOGGLE_ADD_MODAL,
  PROJECT_PEOPLE_TOGGLE_REMOVE_MODAL, PROJECT_PEOPLE_TOGGLE_TABLE_DROPDOWN,
} from './types';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  roles: [],
  groups: [],
  users: [],
  isSubmit: false,
  queryParams: {
    filters: {
      search: '',
      without_users_assigned_via_group: true,
      show_inactive: true,
    },
    sort: 'name',
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  tableSearch: {
    userInputValue: ''
  },
  removeModalProps: {
    isModalOpen: false,
    modalId: 'removeModal',
    data: {},
    isGroup: false
  },
  addModalProps: {
    isModalOpen: false,
    modalId: 'addModal',
    userName: '',
    isRolesOpen: false,
    user: {},
    group: null,
    isGroupOpen: false,
    role: null,
    isGroup: false,
  },
  addModalErrors: {},
  tableDropDown: {
    isOpen: false,
    id: '',
    style: {}
  },
  abortFetchPeople: {},
  abortFetchUsers: {},
};

export default function projectPeople(state = initialState, action) {
  switch (action.type) {
    case PROJECT_PEOPLE_SET_SUBMIT:
      return {
        ...state,
        isSubmit: action.isSubmit
      };
    case PROJECT_PEOPLE_FETCH_GROUPS:
      return {
        ...state,
        groups: action.groups
      };
    case PROJECT_PEOPLE_SET_QUERY_PARAMS_FILTERS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters,
          },
        }
      };
    case PROJECT_PEOPLE_TOGGLE_TABLE_DROPDOWN:
      return {
        ...state,
        tableDropDown: {
          ...action.tableDropDown
        }
      };
    case PROJECT_PEOPLE_ADD_MODAL_SET_ERROR:
      return {
        ...state,
        addModalErrors: action.errors,
      };
    case PROJECT_PEOPLE_ADD_MODAL_SET_DATA:
      return {
        ...state,
        addModalProps: {
          ...state.addModalProps,
          ...action.addModalData
        }
      };
    case PROJECT_PEOPLE_SELECT_USER:
      return {
        ...state,
        addModalProps: {
          ...state.addModalProps,
          user: action.user,
        }
      };
    case PROJECT_PEOPLE_FETCH_ROLES:
      return {
        ...state,
        roles: action.roles
      };
    case PROJECT_PEOPLE_TOGGLE_ADD_MODAL:
      return {
        ...state,
        addModalProps: {
          ...state.addModalProps,
          ...action.modalProps,
        }
      };
    case PROJECT_PEOPLE_TOGGLE_REMOVE_MODAL:
      return {
        ...state,
        removeModalProps: {
          ...state.removeModalProps,
          ...action.modalProps,
        }
      };
    case PROJECT_PEOPLE_FETCH_PEOPLE:
      return {
        ...state,
        ...action.people
      };
    case PROJECT_PEOPLE_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    case PROJECT_PEOPLE_SET_ABORT:
      return {
        ...state,
        abortFetchPeople: action.abortController,
      };
    case PROJECT_PEOPLE_HANDLE_USER_INPUT:
      return {
        ...state,
        tableSearch: {
          ...state.tableSearch,
          userInputValue: action.user
        }
      };
    case PROJECT_PEOPLE_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    default:
      return state;
  }
}
