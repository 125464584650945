import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import { formatISODate, formatStringDate } from '../../utils/helpers';
import { sendFiles } from '../../utils/sendFiles';
import {
  newValidateErrors, validateAgreementDocument, validateClientAgreement, validateErrors,
} from '../../utils/validators';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  document: undefined,
  documentFields: {
    name: '',
    date: formatStringDate(new Date()),
    comments: '',
    documentLink: '',
    documentFile: null,
  },
  documentFieldsErrors: {},
  isDateOpen: false,
  isEdit: false,
};

export const fetchAgreementDocumentAsync = createAsyncThunk(
  'agreementDocument/fetchAgreementDocument',
  async ({ documentId }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/agreement_documents/${documentId}`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAgreementDocumentAsync = createAsyncThunk(
  'agreementDocument/editAgreementDocument',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { document: { id: documentId }, documentFields } = getState().agreementDocumentInfo;
      const errors = validateErrors(documentFields, validateAgreementDocument);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const body = {
        name: documentFields.name,
        comments: documentFields.comments,
        document_link: documentFields.documentLink,
        date: formatStringDate(documentFields.date, 'y-MM-dd'),
      };

      if (documentFields.documentFile?.file) {
        const mediaIds = await sendFiles([documentFields.documentFile.file]);

        if (mediaIds.error) {
          return rejectWithValue(mediaIds);
        }

        mediaIds.data = mediaIds.data.filter((item) => item !== undefined);
        body.file_id = mediaIds.data[0] || null;
      } else if (!documentFields.documentFile) {
        body.file_id = null;
      }

      const { data, status } = await axios.put(`/agreement_documents/${documentId}`, body);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const agreementDocumentSlice = createSlice({
  name: 'agreementDocument',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setEdit: (state, action) => {
      if (action.payload === true) {
        state.documentFields = {
          name: state.document.name,
          date: formatISODate(state.document.date),
          comments: state.document.comments || '',
          documentLink: state.document.document_link || '',
          documentFile: state.document.document_file,
        };
      } else {
        state.documentFields = initialState.documentFields;
        state.documentFieldsErrors = initialState.documentFieldsErrors;
      }
      state.isEdit = action.payload;
    },
    setFieldsData: (state, action) => {
      state.documentFields = { ...state.documentFields, ...action.payload };
      state.documentFieldsErrors = {
        ...state.documentFieldsErrors,
        ...newValidateErrors({ ...action.payload }, validateClientAgreement)
      };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgreementDocumentAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAgreementDocumentAsync.fulfilled, (state, action) => {
        state.document = action.payload;
        state.documentFields = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAgreementDocumentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(editAgreementDocumentAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.documentFieldsErrors = initialState.documentFieldsErrors;
      })
      .addCase(editAgreementDocumentAsync.fulfilled, (state, action) => {
        state.document = action.payload;
        state.documentFields = initialState.documentFields;
        state.documentFieldsErrors = initialState.documentFieldsErrors;
        state.isEdit = false;
        state.isSubmit = false;
      })
      .addCase(editAgreementDocumentAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.documentFieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      });
  },
});

export const {
  setQueryFilter,
  resetState,
  setQuery,
  setEdit,
  setData,
  setFieldsData,
} = agreementDocumentSlice.actions;

export default agreementDocumentSlice.reducer;
