import { INVOICE_INFO_FETCH_DATA, INVOICE_INFO_SET_ABORT, INVOICE_INFO_SET_LOADED } from './types';

export const initialState = {
  invoiceIsLoaded: false,
  invoice: {},
  items: [],
  abortInvoice: {},
};

export default function invoiceInfo(state = initialState, action) {
  switch (action.type) {
    case INVOICE_INFO_SET_ABORT:
      return {
        ...state,
        abortInvoice: action.abortInvoice,
      };
    case INVOICE_INFO_SET_LOADED:
      return {
        ...state,
        invoiceIsLoaded: action.isLoaded,
      };
    case INVOICE_INFO_FETCH_DATA:
      return {
        ...state,
        invoiceIsLoaded: true,
        invoice: action.invoice,
        items: action.invoice?.items || []
      };
    default:
      return state;
  }
}
