import {
  SEARCH_RESET_STATE,
  SEARCH_SET_ABORT, SEARCH_SET_DATA, SEARCH_SET_PENDING 
} from './types';

export const initialState = {
  isSearchPending: true,
  projects: [],
  projectsItems: [],
  users: [],
  clients: [],
  paymentMethods: [],
  assetsCategories: [],
  departments: [],
  correctionTypes: [],
  tickets: [],
  technologies: [],
  qualities: [],
  clientCompanies: [],
  bankAccounts: [],
  assets: [],
  clientProducts: [],
  bonusCategories: [],
  abortProps: {
    abortProjects: {},
    abortProjectsItems: {},
    abortTickets: {},
    abortUsers: {},
    abortClients: {},
    abortPaymentMethods: {},
    abortAssetsCategories: {},
    abortDepartments: {},
    abortCorrectionTypes: {},
    abortTechnologies: {},
    abortQualities: {},
    abortClientCompanies: {},
    abortBankAccounts: {},
    abortAssets: {},
    abortClientProducts: {},
    abortBonusCategories: {},
  },
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case SEARCH_RESET_STATE:
      return initialState;
    case SEARCH_SET_PENDING:
      return {
        ...state,
        isSearchPending: action.pending,
      };
    case SEARCH_SET_DATA:
      return {
        ...state,
        [action.key]: action.data,
      };
    case SEARCH_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          [action.key]: action.controller
        }
      };
    default:
      return state;
  }
}
