import CaseIcon from '../../components/Icons/CaseIcon';
import ClockIcon from '../../components/Icons/ClockIcon';
import DocumentIcon from '../../components/Icons/DocumentIcon';
import FolderCloseIcon from '../../components/Icons/FolderCloseIcon';
import ReportIcon from '../../components/Icons/Report';
import SalaryIcon from '../../components/Icons/SalaryIcon';
import SubscriptionsIcon from '../../components/Icons/SubscriptionsIcon';
import SystemsIcon from '../../components/Icons/SystemsIcon';
import UserCheckIcon from '../../components/Icons/UserCheckIcon';
import UsersIcon from '../../components/Icons/UsersIcon';
import VacationIcon from '../../components/Icons/VacationIcon';
import WaitingIcon from '../../components/Icons/WaitingIcon';
import WorklogIocn from '../../components/Icons/WorklogIocn';
import {
  CLIENTS_PAGE_PERMISSIONS,
  EXPENSE_REPORT_PAGE_PERMISSIONS,
  INVOICES_PAGE_PERMISSIONS,
  PROJECTS_PAGE_PERMISSIONS,
  REVIEW_PAGE_PERMISSIONS,
  SALARY_PAGE_PERMISSIONS,
  SUBSCRIPTIONS_PAGE_PERMISSIONS,
  SYSTEMS_PAGE_PERMISSIONS,
  TIMESHEET_PAGE_PERMISSIONS,
  USERS_PAGE_PERMISSIONS,
  VACATION_PAGE_PERMISSIONS,
  WAITING_LIST_PAGE_PERMISSIONS,
  WORKLOG_PAGE_PERMISSIONS,
} from '../../constants/permissions';
import {
  SET_BADGES,
  SET_SUB_BADGES,
  TOGGLE_SIDEBAR_MINIMIZED,
  TOGGLE_SIDEBAR_MOBILE,
  TOGGLE_SUBNAV_MINIMIZED,
} from './types';

export const initialState = {
  isMinimized: false,
  isSubMinimized: false,
  isMobileOpen: false,
  badges: {},
  subBadges: {},
  sidebarMenu: [
    {
      tKey: 'vacation',
      href: '/vacation',
      icon: <VacationIcon />,
      permissions: VACATION_PAGE_PERMISSIONS,
      notification: true,
      activePath: ['vacation', 'vacation-old'],
    },
    {
      tKey: 'reports',
      href: '/reports/expense-reports',
      icon: <ReportIcon />,
      permissions: EXPENSE_REPORT_PAGE_PERMISSIONS,
      activePath: ['reports'],
    },
    {
      tKey: 'projects',
      href: '/projects',
      icon: (<FolderCloseIcon />),
      permissions: PROJECTS_PAGE_PERMISSIONS,
      activePath: ['projects'],
    },
    {
      tKey: 'clients',
      href: '/clients',
      icon: (<CaseIcon />),
      permissions: CLIENTS_PAGE_PERMISSIONS,
      activePath: ['clients'],
    },
    {
      tKey: 'invoices',
      href: '/invoices',
      icon: (<DocumentIcon />),
      permissions: INVOICES_PAGE_PERMISSIONS,
      activePath: ['invoices'],
      notification: true,
      badgeHref: '/invoices/recommended',
    },
    {
      tKey: 'employees',
      href: '/employees',
      icon: (<UsersIcon />),
      permissions: USERS_PAGE_PERMISSIONS,
      activePath: ['employees'],
    },
    {
      tKey: 'review',
      href: '/review',
      icon: (<UserCheckIcon />),
      permissions: REVIEW_PAGE_PERMISSIONS,
      notification: true,
      activePath: ['review'],
    },
    {
      tKey: 'timesheet',
      href: '/timesheet',
      icon: (<ClockIcon />),
      permissions: TIMESHEET_PAGE_PERMISSIONS,
      activePath: ['timesheet'],
    },
    {
      tKey: 'salary',
      href: '/salary',
      icon: (<SalaryIcon />),
      permissions: SALARY_PAGE_PERMISSIONS,
      activePath: ['salary'],
    },
    {
      tKey: 'subscriptions',
      href: '/subscriptions',
      icon: (<SubscriptionsIcon />),
      permissions: SUBSCRIPTIONS_PAGE_PERMISSIONS,
      activePath: ['subscriptions'],
      notification: true
    },
    {
      tKey: 'waitingList',
      href: '/waiting-list',
      icon: (<WaitingIcon />),
      permissions: WAITING_LIST_PAGE_PERMISSIONS,
      activePath: ['waiting-list'],
    },
    {
      tKey: 'worklog',
      href: '/worklog',
      icon: (<WorklogIocn />),
      permissions: WORKLOG_PAGE_PERMISSIONS,
      activePath: ['worklog'],
    },
    {
      tKey: 'systems',
      href: '/systems',
      icon: (<SystemsIcon />),
      permissions: SYSTEMS_PAGE_PERMISSIONS,
      activePath: ['systems'],
    },
  ],
  nestedMenu: undefined,
};

export default function sidebar(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR_MINIMIZED:
      return {
        ...state,
        isMinimized: action.isMinimized
      };
    case TOGGLE_SUBNAV_MINIMIZED:
      return {
        ...state,
        isSubMinimized: action.isMinimized
      };
    case TOGGLE_SIDEBAR_MOBILE:
      return {
        ...state,
        isMobileOpen: action.isMobileOpen
      };
    case SET_SUB_BADGES:
      return {
        ...state,
        subBadges: {
          ...state.subBadges,
          ...action.badges,
        }
      };
    case SET_BADGES:
      return {
        ...state,
        badges: {
          ...state.badges,
          ...action.badges,
        }
      };
    default:
      return state;
  }
}
