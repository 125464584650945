import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import { newValidateErrors, validateClientCompaniesModal, validateErrors } from '../../utils/validators';

export const initialState = {
  company: {},
  companyEditProps: {
    name: '',
    country_code: undefined,
    country_code_subdivision: undefined,
    address: '',
    upworkNames: [],
    comments: ''
  },
  isActive: false,
  errors: {},
  isEdit: false,
  isSubmit: false,
  isLoading: true,
  error: undefined
};

export const resetClientCompany = createAction('resetClient');

export const resetClientCompaniesEditForm = createAction('resetEditForm');

export const fetchCompanyAsync = createAsyncThunk(
  'clientCompaniesInfo/fetchClientCompany',
  async ({ companyId }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/client_companies/${companyId}`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const restoreCompany = createAsyncThunk(
  'clientCompaniesInfo/restoreClientCompany',
  async ({ companyId, isActive }, { dispatch, rejectWithValue }) => {
    try {
      let method = 'DELETE';
      let endPoint = `/client_companies/${companyId}`;

      if (!isActive) {
        method = 'PUT';
        endPoint += '/restore';
      }

      const { data, status } = await axios({ method, url: endPoint });

      if (status === 200 || status === 204) {
        dispatch(fetchCompanyAsync({ companyId }));
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editClientCompanyAsync = createAsyncThunk(
  'clientCompaniesInfo/editClientCompany',
  async ({ companyId }, { rejectWithValue, getState, dispatch }) => {
    try {
      const { companyEditProps } = getState().clientCompaniesInfo;
      const errors = validateErrors(companyEditProps, validateClientCompaniesModal);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const {
        name,
        country_code_subdivision: countryCodeSubdivision,
        country_code: countryCode,
        address,
        upworkNames,
        comments
      } = companyEditProps;

      const { data, status } = await axios.put(
        `/client_companies/${companyId}`,
        {
          name,
          address,
          country_code_subdivision_id: countryCodeSubdivision?.id,
          country_code_id: countryCode?.id,
          upwork_names: upworkNames.filter((item) => item !== ''),
          comments
        }
      );

      if (status === 200) {
        dispatch(resetClientCompany());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const clientCompaniesInfoSlice = createSlice({
  name: 'clientCompaniesInfo',
  initialState,
  reducers: {
    setEdit: (state, action) => {
      state.isEdit = action.payload;
      state.companyEditProps.upworkNames = state.company.upwork_names || [];
      state.companyEditProps.comments = state.company.comments || '';
      state.companyEditProps.country_code_subdivision = state.company.country_code_subdivision;
      state.companyEditProps.country_code = state.company.country_code;
      state.isActive = !state.company.trashed;
    },
    setFieldsData: (state, action) => {
      state.companyEditProps = { ...state.companyEditProps, ...action.payload };
      state.errors = {
        ...state.errors,
        ...newValidateErrors({ ...action.payload }, validateClientCompaniesModal)
      };
    },
    setStatus: (state, action) => {
      state.isActive = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyAsync.pending, (state) => {
        state.isLoading = true;
        state.clientCompaniesInfo = undefined;
        state.error = undefined;
      })
      .addCase(fetchCompanyAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.company = action.payload;
        state.meta = action.payload.meta;
      })
      .addCase(fetchCompanyAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(editClientCompanyAsync.pending, (state) => {
        state.clientCompanies = undefined;
        state.error = undefined;
        state.isSubmit = true;
        state.isLoading = true;
      })
      .addCase(editClientCompanyAsync.fulfilled, (state, action) => {
        state.isSubmit = false;
        state.isEdit = false;
        state.company = action.payload;
        state.errors = initialState.errors;
        state.isLoading = false;
      })
      .addCase(editClientCompanyAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isSubmit = false;
        if (action.payload?.isFormValidation) {
          state.errors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
      })
      .addCase(restoreCompany.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
        state.isSubmit = true;
      })
      .addCase(restoreCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSubmit = false;
        if (action.payload === '') {
          state.isEdit = false;
        }
      })
      .addCase(restoreCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.isSubmit = false;
      })
      .addCase(resetClientCompany, (state) => {
        state.companyEditProps = initialState.companyEditProps;
        state.errors = initialState.errors;
        state.isEdit = initialState.isEdit;
        state.company = initialState.company;
        state.isLoading = true;
      })
      .addCase(resetClientCompaniesEditForm, (state) => {
        state.companyEditProps = initialState.companyEditProps;
        state.errors = initialState.errors;
        state.isEdit = initialState.isEdit;
      });
  },
});

export const { setFieldsData, setEdit, setStatus } = clientCompaniesInfoSlice.actions;

export default clientCompaniesInfoSlice.reducer;
