import { combineReducers } from '@reduxjs/toolkit';

import alerts from './alerts/reducers';
import assetInfo from './assetInfo/reducers';
import assets from './assets/reducers';
import clientInfo from './clientInfo/reducers';
import clientProjects from './clientProjects/reducers';
import comments from './comments/reducers';
import datepicker from './datepicker/reducers';
import employeeInfo from './employeeInfo/reducers';
import employeePermissions from './employeePermissions/reducers';
import employeeProjects from './employeeProjects/reducers';
import employeeSkills from './employeeSkills/reducers';
import enums from './enums/reducers';
import general from './general/reducers';
import invoiceCreate from './invoiceCreate/reducers';
import invoiceEdit from './invoiceEdit/reducers';
import invoiceInfo from './invoiceInfo/reducers';
import invoiceRecommended from './invoiceRecommended/reducers';
import invoices from './invoices/reducers';
import invoicesDebtors from './invoicesDebtors/reducers';
import invoicesExport from './invoicesExport/reducers';
import projectDocumentation from './projectDocumentation/reducers';
import projectInfo from './projectInfo/reducers';
import projectManagers from './projectManagersHistory/reducers';
import projectPeople from './projectPeople/reducers';
import projectReport from './projectTimesheetReport/reducers';
import salary from './salary/reducers';
import salaryBase from './salaryBase/reducers';
import salaryBonus from './salaryBonus/reducers';
import salaryCorrection from './salaryCorrection/reducers';
import salaryFOP from './salaryFOP/reducers';
import salesManagers from './salesManagersHistory/reducers';
import search from './search/reducers';
import sidebar from './sidebar/reducers';
import technologiesTree from './technologiesTree/reducers';
import timeTracking from './timeTracking/reducers';

const reducers = {
  general,
  sidebar,
  alerts,
  projectInfo,
  clientInfo,
  employeeInfo,
  projectPeople,
  projectManagers,
  invoiceRecommended,
  salesManagers,
  salaryFOP,
  employeePermissions,
  invoices,
  invoiceInfo,
  invoicesExport,
  invoicesDebtors,
  invoiceEdit,
  invoiceCreate,
  search,
  salaryBase,
  comments,
  salaryBonus,
  enums,
  assets,
  assetInfo,
  projectDocumentation,
  salaryCorrection,
  clientProjects,
  employeeProjects,
  datepicker,
  employeeSkills,
  timeTracking,
  technologiesTree,
  projectReport,
  salary,
};

export default combineReducers(reducers);
