import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { formatStringDate, getDiffDays } from '../../utils/helpers';
import Calendar from '../Icons/Calendar';

import styles from './sass/UserHoverCardItemContent.module.scss';

const UserHoverCardItemContent = ({ data }) => {
  const { t } = useTranslation('common');

  return (
    <div key={data.id} className={styles.item}>
      <Calendar width={14} height={14} />
      <div>
        {`${formatStringDate(data.start_date, 'dd MMM')} - ${formatStringDate(data.end_date, 'dd MMM')} `}
        <span>
          {t('countDaysLeft', { count: getDiffDays(new Date(), new Date(data.end_date)) })}
        </span>
      </div>
    </div>
  );
};

UserHoverCardItemContent.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
  }).isRequired,
};

export default UserHoverCardItemContent;
