import { ENUMS_RESET_ENUMS, ENUMS_SET_ENUM } from './types';

export const initialState = {
  currencies: [],
  projectTypes: [],
  salaryCorrectionTypes: [],
  salaryAnomalyStatus: [],
  salaryEmailStatus: [],
  salaryXeroStatus: [],
  documentationType: [],
  salaryLoggingMode: [],
  rateTypes: [],
  usageLevel: [],
};

export default function enums(state = initialState, action) {
  switch (action.type) {
    case ENUMS_RESET_ENUMS:
      return initialState;
    case ENUMS_SET_ENUM:
      return {
        ...state,
        [action.key]: action.data,
      };
    default:
      return state;
  }
}
