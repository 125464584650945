import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import {
  addSecondsToDate,
  changeDateFormat,
  formatStringDate,
  parseWorklogTimeToSeconds,
  toDateString,
} from '../../utils/helpers';
import { validateCreateWorklogPermission, validateErrors } from '../../utils/validators';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isLoading: false,
  isPermissionSubmit: false,
  isDeleteSubmit: false,
  isOnlyActive: false,
  deleteModalId: null,
  queryParams: {
    filters: {
      user_name: '',
      project_name: '',
      from: null,
      to: null,
      only_active: true,
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  tableHeadInput: {
    userInputValue: '',
    projectInputValue: '',
  },
  modalProps: {
    isModalOpen: false,
    modalId: 'createPermission',
  },
  modalDatepicker: undefined,
  formFields: {
    employee: null,
    project: null,
    type: 'import',
    duration: '',
    reason: '',
  },
  formErrors: {}
};

export const fetchWorklog = createAsyncThunk(
  'worklog/fetchWorklog',
  async (arg, { getState, rejectWithValue }) => {
    const { queryParams } = getState().worklog;
    const params = {
      ...queryParams,
      filters: {
        ...queryParams.filters,
        ...(
          queryParams.filters.from
            ? {
              from: {
                gte: changeDateFormat(queryParams.filters.from, true)
              }
            } : {}
        ),
        ...(
          queryParams.filters.to
            ? {
              to: {
                lte: changeDateFormat(queryParams.filters.to, true)
              }
            } : {}
        ),
      }
    };

    try {
      const { data, status } = await axios.get('/worklog_manage', { params });

      if (status === httpStatusCodes.OK) {
        const newData = data.data
          .map((item) => ({
            ...item,
            from: toDateString(new Date(item.from)),
            to: toDateString(new Date(item.to)),
            expired_date: toDateString(addSecondsToDate(item.duration, new Date(item.created_at)))
          }));

        return { ...data, data: [...newData] };
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createWorklogPermission = createAsyncThunk(
  'worklog/createWorklogPermission',
  async (arg, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields, modalDatepicker: period } = getState().worklog;

      const errors = validateErrors({ ...formFields, period }, validateCreateWorklogPermission);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const { from } = period;
      const to = period.to || from;

      const body = {
        from: from ? formatStringDate(from, 'yyyy-MM-dd') : null,
        to: to ? formatStringDate(to, 'yyyy-MM-dd') : null,
        duration: parseWorklogTimeToSeconds(formFields.duration),
        reason: formFields.reason,
        project_id: formFields.project.id,
        user_id: formFields.employee.id,
        type: formFields.type,
      };

      const { data, status } = await axios({
        url: '/worklog_manage',
        method: httpRequestMethods.POST,
        data: body,
      });

      if (status === httpStatusCodes.OK || status === httpStatusCodes.CREATED) {
        dispatch(fetchWorklog());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteWorklogPermission = createAsyncThunk(
  'worklog/deleteWorklogPermission',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    const url = `/worklog_manage/${id}`;
    const method = httpRequestMethods.DELETE;

    try {
      const { data, status } = await axios({ method, url });

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(fetchWorklog());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const worklogSlice = createSlice({
  name: 'worklog',
  initialState,
  reducers: {
    setWorklogState: (state, action) => {
      return { ...state, ...action.payload };
    },
    setSubmit: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = initialState.queryParams.page;
    },
    setTableHeadInput: (state, action) => {
      state.tableHeadInput = {
        ...state.tableHeadInput,
        ...action.payload,
      };
    },
    setModalDatepickerData: (state, action) => {
      state.modalDatepicker = action.payload;
    },
    toggleModal: (state, { payload }) => {
      state.modalProps.isModalOpen = payload.isOpen;

      if (!payload.isOpen) {
        state.formErrors = initialState.formErrors;
        state.formFields = initialState.formFields;
        state.modalDatepicker = initialState.modalDatepicker;
      }
    },
    setFormFields: (state, action) => {
      state.formFields = { ...state.formFields, ...action.payload };
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setModalDataWithValidate: (state, action) => {
      const fieldError = validateCreateWorklogPermission(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    resetFilters: (state) => {
      state.tableHeadInput = initialState.tableHeadInput;
      state.modalDatepicker = initialState.modalDatepicker;
      state.queryParams = initialState.queryParams;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWorklog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWorklog.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.links = action.payload.links;
        state.meta = action.payload.meta;
        state.isLoading = false;
      })
      .addCase(fetchWorklog.rejected, (state, action) => {
        state.isLoading = false;
        state.formErrors = action.payload;
      });

    builder
      .addCase(createWorklogPermission.pending, (state) => {
        state.isPermissionSubmit = true;
        state.formErrors = initialState.formErrors;
      })
      .addCase(createWorklogPermission.fulfilled, (state) => {
        state.modalProps = initialState.modalProps;
        state.formFields = initialState.formFields;
        state.modalDatepicker = initialState.modalDatepicker;
        state.formErrors = initialState.formErrors;
        state.isPermissionSubmit = false;
      })
      .addCase(createWorklogPermission.rejected, (state, action) => {
        state.isPermissionSubmit = false;
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
      });

    builder
      .addCase(deleteWorklogPermission.pending, (state) => {
        state.isDeleteSubmit = true;
      })
      .addCase(deleteWorklogPermission.fulfilled, (state) => {
        state.isDeleteSubmit = false;
      })
      .addCase(deleteWorklogPermission.rejected, (state) => {
        state.isDeleteSubmit = false;
      });
  },
});

export const {
  setWorklogState,
  setSubmit,
  setQueryParamsFilters,
  setTableHeadInput,
  setModalDatepickerData,
  toggleModal,
  setFormFields,
  setPage,
  setFormErrors,
  setModalDataWithValidate,
  resetFilters,
  resetState,
} = worklogSlice.actions;

export default worklogSlice.reducer;
