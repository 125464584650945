import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { parseSalaryRateAndFopInfoData } from '../../utils/salary';
import { validateErrors, validateFopInfoForm } from '../../utils/validators';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isSubmit: false,
  isLoading: true,
  isDeleting: false,
  deleteModalId: null,
  queryParams: {
    filters: {
      id: [],
      show_inactive: false,
      start_month: 0,
      end_month: 0
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  nameHeadValue: '',
  modal: {
    modalId: 'fopInfo',
    isModalOpen: false,
    isMonthOpen: false,
  },
  formFields: {
    fopInfoId: null,
    month: null,
    fopRentUah: '',
    fopEsvUah: '',
    fopEn: '',
  },
  formErrors: {},
};

export const getFopInfo = createAsyncThunk(
  'fopInfo/getFopInfo',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().fopInfo;
      const { data, status } = await axios.get('/fop_info', { params });

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFopInfo = createAsyncThunk(
  'fopInfo/deleteFopInfo',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/fop_info/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getFopInfo());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditFopInfo = createAsyncThunk(
  'fopInfo/createOrEditFopInfo',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().fopInfo;
      const errors = validateErrors(formFields, validateFopInfoForm);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/fop_info';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const { data, status } = await axios({
        url,
        method,
        data: {
          month: formFields.month,
          fop_rent_uah: formFields.fopRentUah,
          fop_esv_uah: formFields.fopEsvUah,
          fop_en: formFields.fopEn,
        },
      });

      if (status === httpStatusCodes.CREATED || status === httpStatusCodes.OK) {
        dispatch(getFopInfo());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fopInfoSlice = createSlice({
  name: 'fopInfo',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setTableHeadInput: (state, action) => {
      state.nameHeadValue = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateFopInfoForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFopInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFopInfo.fulfilled, (state, action) => {
        state.data = parseSalaryRateAndFopInfoData(action.payload.data);
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getFopInfo.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(deleteFopInfo.pending, (state) => {
        state.error = undefined;
        state.isDeleting = true;
      })
      .addCase(deleteFopInfo.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(deleteFopInfo.rejected, (state) => {
        state.isDeleting = false;
      });
    builder
      .addCase(createOrEditFopInfo.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditFopInfo.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createOrEditFopInfo.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
  }
});

export const {
  setModalData,
  setPage,
  setSort,
  setQueryParamsFilters,
  resetModal,
  setTableHeadInput,
  setFormFields,
  setFormErrors,
  setValidateFormField,
  setData,
} = fopInfoSlice.actions;

export default fopInfoSlice.reducer;
