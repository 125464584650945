import {
  format,
  getDate,
  getMonth,
  getWeekOfMonth,
  getYear,
  lastDayOfMonth,
  parseISO,
} from 'date-fns';

import { MONTHS_SHORT } from '../constants/common';
import { INVOICE_ITEM_CUSTOM_TYPE } from '../constants/types';
import { sortBy } from './arrayHelpers';
import { numberDaysBetween } from './dateHelper';
import { formatStringDate, numberFormatter } from './helpers';

const getPaymentsFromParts = (data) => {
  const compareData = [{
    xero_paid_with_cash: data.xero_paid_with_cash,
    xero_paid_with_cash_date: data.xero_paid_with_cash_date,
    xero_paid_with_credit_notes: data.xero_paid_with_credit_notes,
    xero_paid_with_credit_notes_date: data.xero_paid_with_credit_notes_date,
    currency: data.currency,
    isTechnical: true,
  }, ...data.parts];

  return compareData.reduce((acc, part) => {
    if (part.xero_paid_with_cash) {
      acc.push({
        xeroPaidInt: part.xero_paid_with_cash,
        xeroPaid: numberFormatter.format(part.xero_paid_with_cash),
        paymentName: 'paidByCash',
        formatDate: part.xero_paid_with_cash_date ? formatStringDate(part.xero_paid_with_cash_date) : 'unknown',
        date: part.xero_paid_with_cash_date ? new Date(part.xero_paid_with_cash_date) : null,
        currency: part.currency,
        isCreditNotes: false,
        isTechnical: part.isTechnical || false,
      });
    }
    if (part.xero_paid_with_credit_notes) {
      acc.push({
        xeroPaidInt: part.xero_paid_with_credit_notes,
        xeroPaid: numberFormatter.format(part.xero_paid_with_credit_notes),
        paymentName: 'paidByCreditNotes',
        formatDate: part.xero_paid_with_credit_notes_date
          ? formatStringDate(part.xero_paid_with_credit_notes_date)
          : 'unknown',
        date: part.xero_paid_with_credit_notes_date ? new Date(part.xero_paid_with_credit_notes_date) : null,
        currency: part.currency,
        isCreditNotes: true,
        isTechnical: part.isTechnical || false,
      });
    }

    return acc;
  }, []);
};

const parseInvoicePart = (part) => {
  const tempData = {
    amount: numberFormatter.format(part.amount),
    cashDate: formatStringDate(part.date || part.xero_paid_with_cash_date),
    creditNotesDate: formatStringDate(part.date || part.xero_paid_with_credit_notes_date),
  };

  return { ...part, ...tempData };
};

export const parseInvoiceData = (data) => {
  let isCustomType = true;
  const totalQuantity = data.items.reduce((acc, item) => acc + item.billed_time, 0);
  const items = data.items.map((item) => {
    const amount = numberFormatter.format(item.amount);

    if (item.type !== INVOICE_ITEM_CUSTOM_TYPE) {
      isCustomType = false;
    }

    return { ...item, amount };
  });

  const parts = data?.parts?.map((part) => parseInvoicePart(part)) || [];
  const payments = data.parts ? sortBy(getPaymentsFromParts(data), 'date') : [];

  const tempData = {
    amount: numberFormatter.format(data.amount),
    items,
    totalQuantity,
    clientDebt: '0.00',
    isClientDebt: false,
    isCustomType,
    payments,
    parts
  };

  const clientDebt = data.amount - payments.reduce((acc, part) => acc + part.xeroPaidInt, 0);

  tempData.clientDebt = numberFormatter.format(clientDebt);
  tempData.isClientDebt = clientDebt > 0;

  return { ...data, ...tempData };
};

export const createInvoiceTitleDate = (startDate, endDate) => {
  let title = '';

  if (startDate === '') {
    const date = new Date();
    title = date.getFullYear();
  } else {
    const date = new Date(startDate);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const isWeek = numberDaysBetween(startDate, endDate) === 6;

    title = `${year}/${month < 10 ? `0${month}` : month}`;

    if (isWeek) {
      const week = getWeekOfMonth(date);
      title += ` #${week}`;
    }
  }

  return title;
};

export const getPeriodInvoice = (from, to) => {
  let period = '';
  if (from !== null && to !== null) {
    const parseFrom = parseISO(from);
    const parseTo = parseISO(to);
    const [fromYear, fromMonth, fromDay] = [getYear(parseFrom), getMonth(parseFrom), getDate(parseFrom)];
    const [toYear, toMonth, toDay] = [getYear(parseTo), getMonth(parseTo), getDate(parseTo)];

    if (fromYear === toYear && fromMonth === toMonth) {
      const lastMonthDay = lastDayOfMonth(parseFrom);

      if (fromDay === 1 && toDay === lastMonthDay) {
        period = format(parseFrom, 'MMM yyyy');
      } else {
        period = `${MONTHS_SHORT[fromMonth]}/${fromDay} - ${toDay}`;
      }
    } else {
      period = `${format(parseFrom, 'MMM/dd')} - ${format(parseTo, 'MMM/dd')}`;
    }

    return period;
  }
  return '-';
};
