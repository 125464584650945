import {
  CLIENT_PROJECTS_FETCH_DATA, CLIENT_PROJECTS_SET_ABORT, CLIENT_PROJECTS_SET_PAGE, CLIENT_PROJECTS_SET_QUERY_PARAMS,
  CLIENT_PROJECTS_SET_SORT,
  CLIENT_PROJECTS_SET_TABLE_HEAD_INPUT,
} from './types';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  tableHeadInput: {
    nameInputValue: '',
    managerInputValue: '',
  },
  queryParams: {
    filters: {
      project_name: '',
      manager_name: '',
    },
    with: ['manager'],
    sort: '-last_worklog_date',
    page: null,
    per_page: 100,
  },
  abortProps: {
    abortProjects: {},
  },
};

export default function clientProjects(state = initialState, action) {
  switch (action.type) {
    case CLIENT_PROJECTS_SET_TABLE_HEAD_INPUT:
      return {
        ...state,
        tableHeadInput: {
          ...state.tableHeadInput,
          ...action.tableHeadInput,
        }
      };
    case CLIENT_PROJECTS_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case CLIENT_PROJECTS_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    case CLIENT_PROJECTS_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          }
        }
      };
    case CLIENT_PROJECTS_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case CLIENT_PROJECTS_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    default:
      return state;
  }
}
