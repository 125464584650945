import {
  INVOICES_PAGE_PERMISSIONS, REVIEW, REVIEW_OWN, SUBSCRIPTIONS_MANAGEMENT,
  SUBSCRIPTIONS_MANAGEMENT_OWN_DEPARTMENT,
  VACATION_CONFIRMATION,
} from '../../constants/permissions';
import { STATUS_PENDING } from '../../constants/status';
import {
  VACATION_DAY_OFF, VACATION_SICKNESS, VACATION_VACATION
} from '../../constants/types';
import { authGet, authSet } from '../../utils/apiCall';
import { uniqueObjArray } from '../../utils/arrayHelpers';
import {
  filterQueryParams, formatStringDate, getSubscriptionsCount, isAllowed, queryStringify
} from '../../utils/helpers';
import {
  RESET_SUB_BADGES,
  SET_BADGES,
  SET_SUB_BADGES,
  TOGGLE_SIDEBAR_MINIMIZED,
  TOGGLE_SIDEBAR_MOBILE,
  TOGGLE_SUBNAV_MINIMIZED,
} from './types';

export const toggleSidebarMinimizedAction = (isMinimized) => ({ type: TOGGLE_SIDEBAR_MINIMIZED, isMinimized });

export const toggleSubnavMinimizedAction = (isMinimized) => ({ type: TOGGLE_SUBNAV_MINIMIZED, isMinimized });

export const toggleSidebarMobileAction = (isMobileOpen) => ({ type: TOGGLE_SIDEBAR_MOBILE, isMobileOpen });

export const setBadges = (badges) => ({ type: SET_BADGES, badges });

export const setSubBadges = (badges) => ({ type: SET_SUB_BADGES, badges });

export const resetSubBadges = () => ({ type: RESET_SUB_BADGES });

export const fetchMainBadges = (userPermissions, profileId) => {
  return (dispatch) => {
    const vacationParams = {
      filters: {
        confirmations: {
          user_id: [
            profileId
          ],
          status: [
            'pending'
          ],
        },
        status: [
          'pending'
        ],
      },
      per_page: 1
    };
    const reviewParams = {
      filters: {
        status: ['new', 'changed'],
        show_own: true
      },
      per_page: 1
    };
    const invoicesParams = { filters: { show_only_previous_period: true, show_own: true } };
    const subscriptionsParams = { filters: { status: [STATUS_PENDING] } };
    const promises = [];

    if (isAllowed([REVIEW, REVIEW_OWN], userPermissions)) {
      promises.push(
        authSet(
          'POST',
          `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/review`,
          reviewParams,
          {},
          { Accept: 'application/json', 'Content-Type': 'application/json' }
        ).then((res) => ({ mainReviewCount: res?.meta?.total || 0 }))
      );
    }

    if (isAllowed([VACATION_CONFIRMATION], userPermissions)) {
      promises.push(
        authGet(
          `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/vacations?${queryStringify(vacationParams)}`
        ).then((res) => ({ vacationCount: res?.meta?.total || 0 }))
      );
    }

    if (isAllowed([SUBSCRIPTIONS_MANAGEMENT], userPermissions)) {
      promises.push(
        authGet(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/subscriptions?${queryStringify(subscriptionsParams)}`).then(
          (res) => ({
            subscriptionsCount:
              (res?.meta?.department?.count_pending || 0) + (res?.meta?.individual?.count_pending || 0),
          })
        )
      );
    } else if (isAllowed([SUBSCRIPTIONS_MANAGEMENT_OWN_DEPARTMENT], userPermissions)) {
      promises.push(
        authGet(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/subscriptions?${queryStringify(subscriptionsParams)}`).then(
          (res) => ({
            subscriptionsCount: getSubscriptionsCount(res?.data, profileId)
          })
        )
      );
    }

    if (isAllowed(INVOICES_PAGE_PERMISSIONS, userPermissions)) {
      promises.push(
        authGet(
          `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/recommended_invoices?${queryStringify(invoicesParams)}`
        ).then((res) => ({ invoiceRecommendedCount: res?.meta?.total || 0 }))
      );
    }

    Promise
      .all(promises)
      .then((data) => {
        const combinedObject = data.reduce((acc, item) => ({ ...acc, ...item }), {});

        dispatch(setBadges(combinedObject));
      });
  };
};

export const fetchProjectBadges = (id, isReviewManagement = false) => {
  return (dispatch, getState) => {
    const { errorStatus, project } = getState().projectInfo;
    const promises = [];
    const invoiceParams = {
      filters: {
        project: { name: project.name },
        show_only_previous_period: true,
      },
      per_page: 1
    };
    const vacationParams = {
      filters: {
        start_date: formatStringDate(new Date(), 'y-MM-dd'),
        end_date: formatStringDate(new Date(), 'y-MM-dd'),
        user: {
          project: {
            id: [id],
            still_work: true,
          },
        },
        ticket: {
          key: [VACATION_SICKNESS, VACATION_VACATION, VACATION_DAY_OFF]
        },
      },
      with: ['user'],
    };
    const reviewParams = {
      filters: {
        project: { id: [id] },
        status: ['new'],
        per_page: 1,
        show_own: !isReviewManagement,
      }
    };

    if (!errorStatus) {
      const endpoints = [
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/recommended_invoices?${queryStringify(invoiceParams)}`,
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/review`,
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/worklogs?${queryStringify(vacationParams)}`,
      ];

      endpoints.forEach((endpoint, index) => {
        let request;

        switch (index) {
          case 0:
            request = project.permissions.view_invoices && authGet(endpoint).then((res) => res?.meta?.total);
            break;
          case 1:
            request = authSet(
              'POST',
              endpoint,
              filterQueryParams(reviewParams),
              {},
              {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              }
            )
              .then((res) => res?.meta?.total);
            break;
          case 2:
            request = project.permissions.view_people
              && authGet(endpoint).then((res) => uniqueObjArray(res?.data, 'user_id').length);
            break;
          default:
            request = Promise.resolve(0);
            break;
        }
        promises.push(request);
      });
    }

    return Promise.all(promises).then(([totalInvoices, totalReview, totalVacation]) => {
      dispatch(setSubBadges({
        review_count: totalReview,
        invoices_count: totalInvoices,
        vacation_count: totalVacation
      }));
    });
  };
};
