import { projectReportDetailsColumn, REPORT_TIME_FORMAT_OPTIONS } from '../../constants/common';
import { getThisMonth } from '../../utils/dateHelper';
import {
  PROJECT_REPORT_RESET_DATA, PROJECT_REPORT_SET_ABORT,
  PROJECT_REPORT_SET_BILLING_REPORTS,
  PROJECT_REPORT_SET_DATEPICKER_DATA, PROJECT_REPORT_SET_FILTER_FIELDS, PROJECT_REPORT_SET_LOADER,
  PROJECT_REPORT_SET_MODAL_REPORT, PROJECT_REPORT_SET_PROJECT_SUMMARY,
  PROJECT_REPORT_SET_QUERY_PARAMS_FILTERS,
  PROJECT_REPORT_SET_SORT,
  PROJECT_REPORT_SET_WORKLOGS, PROJECT_REPORT_SET_WORKLOGS_ID
} from './types';

const { firstDay, lastDay } = getThisMonth();

export const initialState = {
  abortController: {},
  abortProps: {
    abortReport: {}
  },
  workLogs: [],
  projectSummary: [],
  queryParams: {
    filters: {
      start_date: '',
      end_date: '',
      only_approved: true,
      project: { id: [], key: '', search: '', },
      milestone_item: { id: [], search: '', },
      ticket: { id: '', key: '', search: '', },
      user: { id: [], search: '', },
      client: { id: [], search: '', },
      department: { id: [], },
      comment: '',
    },
    id: [],
    per_page: 500,
    with: ['user', 'milestone_item'],
    sort: '-date'
  },
  billingReports: {
    worklogs: [],
  },
  datepicker: {
    from: firstDay,
    to: lastDay,
  },
  reportModal: {
    modalId: 'reportModal',
    isModalOpen: false,
    period: '',
  },
  reportModalTable: {
    modalId: 'reportModalTable',
    isModalOpen: false,
    filteredEmployees: [],
    filteredItems: [],
    timeFormat: REPORT_TIME_FORMAT_OPTIONS.hours,
  },
  reportExportModal: {
    modalId: 'reportExportModal',
    isModalOpen: false,
    summaryTotal: '',
    summaryTotalHours: '',
    detailsTotal: '',
    selectedColumn: projectReportDetailsColumn.filter((item) => (item !== 'epic' || item !== 'mergeComment')),
    summaryDataSheet: [],
    detailsData: [],
    detailsDataSheet: []
  },
  reportModalLoader: {
    modalId: 'reportModalLoader',
    isModalOpen: false,
  },
  reportModalResult: {
    modalId: 'reportModalResult',
    isModalOpen: false,
    spreadsheetUrl: null
  },
  filterFields: {
    isProjectItemOpen: false,
    tasks: [],
    taskName: '',
    isEmployeeOpen: false,
    employees: [],
    comments: [],
    commentName: '',
  },
  buttonLoader: false
};

export default function projectReport(state = initialState, action) {
  switch (action.type) {
    case PROJECT_REPORT_SET_ABORT:
      return {
        ...state,
        abortController: action.abortController,
      };
    case PROJECT_REPORT_SET_QUERY_PARAMS_FILTERS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters,
          }
        }
      };
    case PROJECT_REPORT_SET_BILLING_REPORTS:
      return {
        ...state,
        billingReports: {
          ...state.billingReports,
          ...action.filters,
        }
      };
    case PROJECT_REPORT_SET_MODAL_REPORT:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case PROJECT_REPORT_SET_DATEPICKER_DATA:
      return {
        ...state,
        datepicker: action.datepicker,
      };
    case PROJECT_REPORT_SET_WORKLOGS:
      return {
        ...state,
        workLogs: action.data,
        billingReports: {
          ...state.billingReports,
          worklogs: action.worklogs,
        },
        buttonLoader: action.isLoader
      };
    case PROJECT_REPORT_SET_WORKLOGS_ID:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            worklogs: action.worklogs,
          }
        }
      };
    case PROJECT_REPORT_SET_PROJECT_SUMMARY:
      return {
        ...state,
        projectSummary: action.projectSummary
      };
    case PROJECT_REPORT_SET_FILTER_FIELDS:
      return {
        ...state,
        filterFields: {
          ...state.filterFields,
          ...action.data,
        },
      };
    case PROJECT_REPORT_SET_LOADER:
      return {
        ...state,
        buttonLoader: action.buttonLoader
      };
    case PROJECT_REPORT_RESET_DATA:
      return {
        ...state,
        meta: {},
        workLogs: [],
        projectSummary: [],
        isDataLoading: true,
        queryParams: initialState.queryParams,
        filterFields: initialState.filterFields,
        reportExportModal: initialState.reportExportModal,
        datepicker: initialState.datepicker,
        buttonLoader: false
      };
    case PROJECT_REPORT_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    default:
      return state;
  }
}
