import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import downloadFile from '../../utils/downloadFile';
import { formatStringDate } from '../../utils/helpers';
import {
  newValidateErrors,
  validateAssetsModal,
  validateErrors
} from '../../utils/validators';

export const initialState = {
  assets: [],
  meta: {},
  isLoading: false,
  isSubmit: false,
  isExporting: false,
  queryParams: {
    filters: {
      id: [],
      name: '',
      inventory: '',
      serial: '',
      category_name: '',
      category_id: [],
      part_number: '',
      ean_number: 0,
      user_id: [],
      user_name: '',
      show_unassigned: false,
      activity: true,
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    with: ['asset_category', 'active_assignment'],
    sort: '-created_at'
  },
  pageFilters: {
    categoriesName: '',
    isCategoriesOpen: false,
    employeeName: '',
    isEmployeeOpen: false,
    isColumnsOpen: false
  },
  tableColumns: {
    category: true,
    createdAt: true,
    name: true,
    inventoryNumber: true,
    serialNumber: true,
    partNumber: false,
    eanNumber: false,
    employee: true,
    department: false,
    sharedDevice: false,
    amount: true,
    currency: false,
    currencyRate: false,
    description: true,
    action: true,
  },
  tableHeadInput: {
    nameInputValue: '',
    inventoryInputValue: '',
    serialInputValue: '',
    partInputValue: '',
    eanInputValue: '',
    departmentInputValue: ''
  },
  assetsModal: {
    modalId: 'assetsModal',
    isModalOpen: false,
    isCategoryOpen: false,
    isEmployeeOpen: false,
    isPartNumberOpen: false,
    isNameOpen: false,
    tittle: '',
    searchEmployeeValue: '',
    shared_device: false,
    isCurrencyOpen: false,
    currencies: [],
    isDepartmentOpen: false,
    departmentSearchValue: '',
    isAssign: false
  },
  assetsWarningModal: {
    modalId: 'assetsWarningModal',
    isModalOpen: false,
  },
  assetsFields: {
    asset_id: null,
    category: null,
    name: '',
    inventory_number: '',
    serial_number: '',
    part_number: '',
    ean_number: '',
    description: '',
    employee: {},
    user_id: '',
    amount: '',
    currency: '',
    currency_rate: '',
    department: {},
    activity: false,
    comment: '',
    isCommentRequired: false
  },
  abortProps: {
    abortAssets: {},
  },
  modalErrors: {},
};

export const fetchAssets = createAsyncThunk(
  'assets/fetchAssets',
  async (arg, { rejectWithValue, getState }) => {
    const { assets: { queryParams } } = getState();

    try {
      const { data, status } = await axios.get('assets', { params: queryParams });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exportAssets = createAsyncThunk(
  'assets/exportAssets',
  async (arg, { rejectWithValue }) => {
    try {
      await downloadFile('/assets/export', `Assets_${formatStringDate(new Date(), 'dd_MMM_yyyy')}`);

      return undefined;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCurrencyRate = createAsyncThunk(
  'assets/getCurrencyRate',
  async (arg, { rejectWithValue }) => {
    const params = {
      filters: { date: [formatStringDate(new Date(), 'yyyy-MM-dd')], currency: [arg] }
    };

    try {
      const { data, status } = await axios.get('/currency_rates', { params });
      const [fetchCurrency] = data.data;

      return status === 200 ? fetchCurrency?.rate : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrAssignAsset = createAsyncThunk(
  'assets/addOrAssignAsset',
  async (arg = false, { rejectWithValue, getState, dispatch }) => {
    try {
      const { assetsFields, assetsModal } = getState().assets;

      const errors = validateErrors(
        assetsFields,
        validateAssetsModal,
        {
          isDepartmentExist: assetsModal.shared_device,
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let url = '/assets';
      const body = {
        asset_id: assetsFields.asset_id,
        category_id: assetsFields.category.id,
        name: assetsFields.name,
        serial_number: assetsFields.serial_number,
        part_number: assetsFields.part_number,
        ean_number: assetsFields.ean_number,
        description: assetsFields.description,
        shared_device: assetsModal.shared_device,
        amount: assetsFields.amount,
        currency: assetsFields.currency,
        currency_rate: assetsFields.currency_rate,
        activity: assetsFields.activity
      };

      if (assetsFields.asset_id) {
        method = 'PATCH';
        url += `/${assetsFields.asset_id}`;
      }

      if (arg) {
        body.employee_id = null;
      } else if (assetsModal.shared_device) {
        body.department_id = assetsFields.department?.id;
      } else {
        body.employee_id = assetsFields.employee?.id;
      }

      const { data, status } = await axios({
        method,
        url,
        data: body
      });

      const statusOk = status === 200 || status === 201;

      if (statusOk) {
        dispatch(fetchAssets());
      }

      return statusOk ? data : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetModalData: (state) => {
      state.assetsModal = initialState.assetsModal;
      state.assetsWarningModal = initialState.assetsWarningModal;
      state.assetsFields = initialState.assetsFields;
      state.modalErrors = initialState.modalErrors;
    },
    setQueryFilter: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setPageFilterData: (state, action) => {
      state.pageFilters = { ...state.pageFilters, ...action.payload };
    },
    setTableColumns: (state, action) => {
      state.tableColumns = { ...state.tableColumns, ...action.payload };
    },
    setTableHeadInput: (state, action) => {
      state.tableHeadInput = { ...state.tableHeadInput, ...action.payload };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setModalFields: (state, action) => {
      const { assetsFields } = state;

      state.assetsFields = { ...state.assetsFields, ...action.payload };
      state.modalErrors = {
        ...state.modalErrors,
        ...newValidateErrors({ ...action.payload }, validateAssetsModal, {
          isValidateEmployee: !!assetsFields.asset_id,
          isCurrencyExist: assetsFields.currency !== 'USD' && assetsFields?.currency?.length > 0,
          isAmountExist: !!assetsFields.amount,
          isDepartmentExist: assetsFields.shared_device,
          isCommentRequired: assetsFields.isCommentRequired
        })
      };
    },
    setWarningModalData: (state, action) => {
      state.assetInfoWarningModal = { ...state.assetInfoWarningModal, ...action.payload };
      state.modalErrors = {
        ...state.modalErrors,
        ...newValidateErrors({ ...action.payload }, validateAssetsModal)
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(exportAssets.pending, (state) => {
        state.isExporting = true;
      })
      .addCase(exportAssets.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(exportAssets.rejected, (state, action) => {
        state.isExporting = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchAssets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAssets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.meta = action.payload.meta;
        state.assets = action.payload.data;
      })
      .addCase(fetchAssets.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(addOrAssignAsset.pending, (state) => {
        state.isLoading = true;
        state.isSubmit = true;
      })
      .addCase(addOrAssignAsset.fulfilled, (state) => {
        state.isLoading = false;
        state.isSubmit = false;
        state.assetsModal = initialState.assetsModal;
        state.assetsFields = initialState.assetsFields;
      })
      .addCase(addOrAssignAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.isSubmit = false;

        if (action.payload.isFormValidation) {
          state.modalErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
      });
    builder
      .addCase(getCurrencyRate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrencyRate.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          state.assetsFields.currency_rate = action.payload.toFixed(2).toString();
        }
      })
      .addCase(getCurrencyRate.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const {
  resetState,
  resetModalData,
  setQueryFilter,
  setQuery,
  setPageFilterData,
  setTableColumns,
  setPage,
  setTableHeadInput,
  setModalData,
  setModalFields
} = assetsSlice.actions;

export default assetsSlice.reducer;
