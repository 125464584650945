import { createListenerMiddleware } from '@reduxjs/toolkit';

import { mergeRouterQuery, queryStringify, removeRouterQueryByKeys } from '../../utils/routerQuery';
import { setQuery, setQueryFilter } from './slice';

const { startListening, middleware } = createListenerMiddleware();

startListening({
  predicate: (action) => {
    switch (action.type) {
      case setQueryFilter.toString(): {
        return true;
      }
      case setQuery.toString(): {
        return true;
      }
      default:
        break;
    }

    return false;
  },
  effect: async ({ type, payload }) => {
    switch (type) {
      case setQueryFilter.toString(): {
        mergeRouterQuery(queryStringify({ ...payload }));

        const removeQueryKeys = Object
          .keys(payload)
          .reduce((acc, key) => ([...acc, payload[key]?.length === 0 ? key : null]), [])
          .filter((key) => key !== null);

        if (removeQueryKeys.length > 0) {
          await removeRouterQueryByKeys(removeQueryKeys);
        }

        break;
      }
      case setQuery.toString(): {
        mergeRouterQuery(queryStringify({ ...payload }));

        break;
      }
      default:
        break;
    }
  },
});

export default middleware;
