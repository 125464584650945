import { SORT_BY_CREATED_AT } from '../../constants/common';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import { parseDate } from '../../utils/helpers';
import {
  SALARY_FOP_FETCH_DATA, SALARY_FOP_LIST_SET_SUBMIT, SALARY_FOP_RESET_DATEPICKER, SALARY_FOP_RESET_FOP_DATA,
  SALARY_FOP_RESET_STATE,
  SALARY_FOP_SET_ABORT, SALARY_FOP_SET_HOLIDAY_DATA, SALARY_FOP_SET_HOLIDAY_ERROR,
  SALARY_FOP_SET_MODAL_DATA, SALARY_FOP_SET_PAGE, SALARY_FOP_SET_PAGE_FILTER_DATA,
  SALARY_FOP_SET_QUERY_PARAMS, SALARY_FOP_SET_SORT,
  SALARY_FOP_SET_TABLE_COLUMNS, SALARY_FOP_SET_TABLE_HEAD_DATA
} from './types';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isFOPSubmit: false,
  isDeleteSubmit: false,
  tableColumns: {
    employee: true,
    month: true,
    fop: false,
    fopAddress: false,
    fopAddressUkr: false,
    bankSwift: false,
    bankName: false,
    rentPrepaid: false,
    compensateEsv: true,
    compensateRent: true,
    bankAccountCost: false,
    name: false,
    nameUkr: false,
    accountUSD: false,
    accountUAH: false,
    agreementType: false,
    en: false,
    taxIdNumber: false,
    passportNumber: false,
    createdAt: false,
    action: true,
  },
  queryParams: {
    filters: {
      id: [],
      user_name: '',
      user_id: [],
      bank_account: '',
      actual_fop_on_date: null,
      show_inactive: false,
      active_users: true,
      start_month: null,
      end_month: null,
    },
    with: ['user'],
    sort: `-${SORT_BY_CREATED_AT}`,
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  pageFilters: {
    employeeSearchValue: '',
    isEmployeeOpen: false,
    selectedEmployeeName: [],
    isColumnsOpen: false,
    isMonthOpen: false,
    year: null,
  },
  modalProps: {
    modalId: 'fopModal',
    isModalOpen: false,
    fopId: null,
    isEmployeeOpen: false,
    isDateOpen: false,
    isMonthPickerOpen: false,
    searchEmployeeValue: '',
  },
  fopWarningModal: {
    modalId: 'fopWarningModal',
    isModalOpen: false,
    fopId: null,
  },
  salaryFOPFields: {
    user: null,
    month: undefined,
    is_fop: true,
    is_rent_prepaid: true,
    fop_compensate_esv: true,
    fop_compensate_rent: true,
    fop_bank_account_cost_uah: '',
    name: '',
    name_ukr: '',
    account_usd: '',
    account_uah: '',
    fop_address: '',
    fop_address_ukr: '',
    bank_swift: '',
    bank_name: '',
    agreement_type: '',
    en: '',
    tax_id_number: '',
    passport_number: '',
  },
  salaryFOPErrors: {},
  tableHeadData: {
    userInputValue: '',
  },
  datepicker: {
    from: new Date(),
    to: new Date(),
    enteredTo: new Date(),
    inputFrom: parseDate(new Date()),
    inputEnteredTo: parseDate(new Date()),
    isSelectRange: false,
    isOpen: false,
    style: {}
  },
  abortProps: {
    abortSalaryBase: {},
  },
};

export default function salaryFOP(state = initialState, action) {
  switch (action.type) {
    case SALARY_FOP_RESET_STATE:
      return initialState;
    case SALARY_FOP_SET_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          ...action.columns
        }
      };
    case SALARY_FOP_SET_PAGE_FILTER_DATA:
      return {
        ...state,
        pageFilters: {
          ...state.pageFilters,
          ...action.data
        }
      };
    case SALARY_FOP_LIST_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case SALARY_FOP_RESET_DATEPICKER:
      return {
        ...state,
        datepicker: initialState.datepicker
      };
    case SALARY_FOP_SET_TABLE_HEAD_DATA:
      return {
        ...state,
        tableHeadData: {
          ...state.tableHeadData,
          ...action.data
        }
      };
    case SALARY_FOP_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data,
        }
      };
    case SALARY_FOP_SET_HOLIDAY_ERROR:
      return {
        ...state,
        salaryFOPErrors: action.errors,
      };
    case SALARY_FOP_RESET_FOP_DATA:
      return {
        ...state,
        salaryFOPFields: initialState.salaryFOPFields,
        salaryFOPErrors: initialState.salaryFOPErrors,
        datepicker: initialState.datepicker,
      };
    case SALARY_FOP_SET_HOLIDAY_DATA:
      return {
        ...state,
        salaryFOPFields: {
          ...state.salaryFOPFields,
          ...action.data
        }
      };
    case SALARY_FOP_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SALARY_FOP_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    case SALARY_FOP_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case SALARY_FOP_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    case SALARY_FOP_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          }
        }
      };
    default:
      return state;
  }
}
