import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { COMMENT_STATUSES } from '../../constants/emoji';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { CREATED, OK } from '../../constants/httpStatusCodes';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { ROLE_ADMIN } from '../../constants/roles';
import axios from '../../services/axios';
import { getNewImages, sendFiles } from '../../utils/sendFiles';
import {
  validateCommentModal,
  validateErrors,
} from '../../utils/validators';

export const initialState = {
  commentsData: [],
  modalErrors: {},
  isSubmit: false,
  isLoading: false,
  commentProps: {
    commentable_type: '',
    commentable_id: null,
  },
  commentModal: {
    modalId: 'commentModal',
    isModalOpen: false,
    isUpdateData: false,
    date: '',
    commentId: '',
    comment: '',
    comment_json: undefined,
    editCommentJson: undefined,
    employeeName: '',
    status: COMMENT_STATUSES.VERY_GOOD,
    roles: [ROLE_ADMIN],
    client: {},
    isProjectOpen: false,
    project: null,
    problem: '',
    images: [],
  },
  commentWarningModal: {
    modalId: 'commentWarningModal',
    isModalOpen: false,
  },
  moreProps: {
    isOpen: false,
    id: '',
    style: {},
  },
  editCommentModal: {
    isModalOpen: false,
    modalId: 'editCommentModal',
  },
};

export const fetchCommentsAsync = createAsyncThunk(
  'comments/fetchComments',
  async (arg, { rejectWithValue, getState }) => {
    const { commentProps } = getState().comments;

    try {
      const params = {
        filters: {
          commentable_type: commentProps.commentable_type,
          commentable_id: commentProps.commentable_id
        }
      };

      const { data, status } = await axios.get('/model_comments', { params });

      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditCommentAsync = createAsyncThunk(
  'comments/addOrEditComment',
  async ({ commentId, commentType, commentableId: id = null }, { getState, rejectWithValue, dispatch }) => {
    try {
      const {
        commentProps: {
          commentable_type: commentableType,
          commentable_id: commentableId
        },
        commentModal
      } = getState().comments;

      const {
        comment_json: commentJson,
        editCommentJson,
        status: commentStatus,
        roles,
        problem,
      } = commentModal;

      const errors = validateErrors(
        commentModal,
        validateCommentModal,
        !!commentId && commentableType === 'SalaryBase'
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = httpRequestMethods.POST;
      let url = '/model_comments';
      let newFiles = commentModal.images;
      let prevMediaIds = [];

      if (commentId) {
        method = httpRequestMethods.PUT;
        url += `/${commentId}`;
        prevMediaIds = newFiles
          .filter(({ file }) => !(file instanceof File && file))
          .map(({ file }) => file.id);
      } else {
        newFiles = await getNewImages(newFiles);
      }

      const files = newFiles.filter(({ file }) => file instanceof File && file).map(({ file }) => file);

      let mediaIds = {
        data: files,
      };

      if (files.length > 0) {
        mediaIds = await sendFiles(files);
      }

      if (mediaIds.error) {
        return rejectWithValue(mediaIds);
      }

      const body = {
        comment_json: editCommentJson || commentJson,
        commentable_type: commentType || commentableType,
        commentable_id: id || commentableId,
        status: commentStatus,
        roles,
        status_title: problem || null,
        file_ids: commentId ? [...prevMediaIds, ...mediaIds.data] : [...mediaIds.data],
      };

      const { data, status } = await axios({ url, method, data: body });

      if (status === CREATED || status === OK) {
        dispatch(fetchCommentsAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCommentAsync = createAsyncThunk(
  'comments/deleteComment',
  async ({ commentId }, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await axios.delete(`/model_comments/${commentId}`,);
      let res = null;

      if (status === httpStatusCodes.NO_CONTENT) {
        res = data;
        dispatch(fetchCommentsAsync());
      } else {
        return rejectWithValue(data.message);
      }

      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetModal: (state) => {
      state.commentModal = initialState.commentModal;
      state.commentWarningModal = initialState.commentWarningModal;
      state.editCommentModal = initialState.editCommentModal;
    },
    setCommentProps: (state, action) => {
      state.commentProps = action.payload;
    },
    setModalData: (state, action) => {
      state.commentModal = { ...state.commentModal, ...action.payload };
    },
    setWarningModalData: (state, action) => {
      state.commentWarningModal = { ...state.commentWarningModal, ...action.payload };
    },
    setEditModalData: (state, action) => {
      state.editCommentModal = { ...state.editCommentModal, ...action.payload };
    },
    setCommentsData: (state, action) => {
      state.commentsData = action.payload;
    },
    setCommentErrors: (state, action) => {
      state.modalErrors = { ...state.modalErrors, ...action.payload };
    },
    toggleCommentAction: (state, action) => {
      state.moreProps = { ...state.moreProps, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommentsAsync.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(fetchCommentsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.commentsData = action.payload?.map((item) => {
          const images = item.files?.map((file) => ({ file, src: file.original_url, name: file.name })) || [];

          return { ...item, images };
        });
        state.meta = action.payload.meta;
      })
      .addCase(fetchCommentsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(addOrEditCommentAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
      })
      .addCase(addOrEditCommentAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.commentModal = initialState.commentModal;
        state.commentWarningModal = initialState.commentWarningModal;
        state.editCommentModal = initialState.editCommentModal;
      })
      .addCase(addOrEditCommentAsync.rejected, (state, action) => {
        state.isSubmit = false;
        if (action.payload.isFormValidation) {
          state.modalErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
      });
    builder
      .addCase(deleteCommentAsync.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(deleteCommentAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteCommentAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  resetState,
  resetModal,
  setCommentProps,
  setModalData,
  setWarningModalData,
  setEditModalData,
  toggleCommentAction,
  setCommentsData,
  setCommentErrors
} = commentsSlice.actions;

export default commentsSlice.reducer;
