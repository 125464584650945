import { isSameDay, isToday, parseISO } from 'date-fns';

import { ISO_BASIC_TIME, WEEKDAYS_SHORT } from '../constants/common';
import {
  VACATION_APPROVE,
  VACATION_FORCE_APPROVE,
  VACATION_FORCE_REJECT,
  VACATION_REJECT,
} from '../constants/types';
import { VACATION_CALENDAR_HEIGHT } from '../constants/values';
import { sortBy } from './arrayHelpers';
import { numberDaysBetween } from './dateHelper';
import {
  capitalizeFirstLetter,
  formatStringDate,
  getDiffDays,
  parseHolidaysData,
} from './helpers';
import { getDayType } from './timesheet';

export const getDays = (startDate, endDate, holidays) => {
  const dates = [];
  const date = new Date(`${startDate}${ISO_BASIC_TIME}`);
  const numberDays = numberDaysBetween(startDate, endDate);

  for (let i = 0; i <= numberDays; i += 1) {
    const day = date.getDay();

    dates.push({
      day: date.getDate().toString(),
      dayName: capitalizeFirstLetter(WEEKDAYS_SHORT[day].toLowerCase()),
      dayType: getDayType(date, holidays),
      isToday: isToday(date),
      month: +date.getMonth().toString()
    });
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const isPastDate = (firstDate, secondDate) => {
  return new Date(formatStringDate(new Date(firstDate))) > new Date(formatStringDate(new Date(secondDate)));
};

export const generationWorklogs = (startDate, endDate, holidays, data) => {
  const worklogs = [];
  const numberDays = numberDaysBetween(startDate, endDate);
  const date = parseISO(startDate);

  for (let i = 0; i <= numberDays; i += 1) {
    const currentWorklog = data.find((item) => isSameDay(parseISO(item.date), date));

    worklogs.push({
      date: date.toString(),
      dayType: getDayType(date, holidays),
      isLoggedTime: currentWorklog?.reported > 0 || currentWorklog?.over_reported > 0,
      startDate: currentWorklog?.start_date || '',
      endDate: currentWorklog?.end_date || '',
      isRange: currentWorklog?.isRange,
    });
    date.setDate(date.getDate() + 1);
  }

  return worklogs;
};

const dateRange = (startDate, endDate, steps = 1) => {
  const dateArray = [];
  const currentDate = new Date(startDate);
  const end = new Date(endDate);

  while (currentDate <= end) {
    dateArray.push(new Date(currentDate));
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray;
};

export const generateTooltipStyle = (index, rows) => {
  let top = 122;

  if (index !== 1) {
    if (index === rows) {
      top = 16;
    }

    top += ((index - 1) * VACATION_CALENDAR_HEIGHT);
  }

  return top;
};

const generationUsersRequests = (data) => {
  return data
    .map((item) => item.user)
    .filter((value, index, self) => {
      return self.findIndex((el) => el.id === value.id) === index;
    });
};

const generationUserVacationFlag = (data) => {
  return data.map((item) => ({
    ...item,
    isVacation: item.worklogs.length > 0
  }));
};

const getNowadaysWorklogs = (worklogs, startDate) => {
  return worklogs.filter((worklog) => (
    formatStringDate(worklog.date, 'MM') === formatStringDate(startDate, 'MM')
  ));
};

const sortByVacation = (arr) => {
  const newArr = [...arr];

  return newArr.sort((a, b) => {
    const worklogsA = a.worklogs.filter((log) => log.isLoggedTime);
    const worklogsB = b.worklogs.filter((log) => log.isLoggedTime);

    if (worklogsA.length === 0 && worklogsB.length === 0) {
      return 0;
    }

    if (worklogsA.length === 0) {
      return 1;
    }

    if (worklogsB.length === 0) {
      return -1;
    }

    const dateA = new Date(worklogsA[0].date);
    const dateB = new Date(worklogsB[0].date);

    return dateA - dateB;
  });
};

const updateWorklogsForRows = (rows, startDate, endDate, holidays) => {
  return rows.map((item) => ({
    ...item,
    worklogs: generationWorklogs(
      startDate,
      endDate,
      parseHolidaysData(holidays, new Date(startDate), new Date(endDate)),
      item.worklogs,
    ),
  }));
};

const generateSingleWorklog = (item, isRange, date) => {
  return {
    approved: 28800,
    billed: 28800,
    reported: 28800,
    user_id: item.user_id,
    worklog_ids: [],
    isRange,
    start_date: new Date(formatStringDate(item.start_date)).toString(),
    end_date: new Date(formatStringDate(item.end_date)).toString(),
    date: formatStringDate(isRange ? date : item.start_date, 'yyyy-MM-dd'),
  };
};

export const generationRows = (data, holidays, startDate, endDate) => {
  const req = generationUsersRequests(data)
    .reduce((acc, item) => {
      const worklogs = [];

      data
        .filter((worklog) => worklog.user_id === item.id)
        .forEach((el) => {
          if (el.start_date === el.end_date) {
            worklogs.push(generateSingleWorklog(el, false));
          }
          if (el.start_date !== el.end_date) {
            dateRange(el.start_date, el.end_date)
              .forEach((date) => {
                worklogs.push(generateSingleWorklog(el, true, date));
              });
          }
        });

      return [...acc, { ...item, worklogs: getNowadaysWorklogs(worklogs, startDate) }];
    }, []);

  const rows = generationUserVacationFlag(req);

  return data.length
    ? sortByVacation(updateWorklogsForRows(rows, startDate, endDate, holidays))
    : rows;
};

export const getVacationPercent = (spent, limit) => {
  if (spent === 0) {
    return 100;
  }
  return (100 - (spent / limit) * 100);
};

export const setSubmitType = (isForce, confirm) => {
  if (isForce && confirm) {
    return VACATION_FORCE_APPROVE;
  } if (isForce && !confirm) {
    return VACATION_FORCE_REJECT;
  } if (!isForce && confirm) {
    return VACATION_APPROVE;
  } if (!isForce && !confirm) {
    return VACATION_REJECT;
  }
  return '';
};

export const getVacationRange = (startDate = new Date(), endDate = new Date()) => {
  return `${formatStringDate(startDate, 'dd MMM')} - ${formatStringDate(endDate, 'dd MMM')}`;
};

export const getPeriodLists = (list) => {
  const now = new Date().setHours(0, 0, 0, 0);

  const rightNowList = sortBy(list
    .filter((item) => now >= new Date(item.start_date).setHours(0, 0, 0, 0)
          && now <= new Date(item.end_date).setHours(0, 0, 0, 0))
    .map((item) => ({ ...item, daysLeft: getDiffDays(new Date(), new Date(item.end_date)) })), 'daysLeft');
  const upcomingList = sortBy(list
    .filter((item) => now < new Date(item.start_date).setHours(0, 0, 0, 0))
    .map((item) => ({ ...item, daysLeft: getDiffDays(new Date(), new Date(item.start_date)) })), 'daysLeft');

  return { rightNowList, upcomingList };
};

export const getHighlightedText = (text, highlight, id) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {parts.map((part, i) => {
        if (part.toLowerCase() === highlight.toLowerCase()) {
          const key = `${id}_${i}`;
          return <b key={key}>{part}</b>;
        }
        return part;
      })}
    </span>
  );
};
