import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GUARD_NAME_WEB } from '../../constants/common';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { validateErrors, validateRoleFrom } from '../../utils/validators';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isLoading: false,
  isSubmit: false,
  isDeleting: false,
  deleteModalId: null,
  queryParams: {
    filters: {
      guard_name: '',
      name: '',
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  nameHeadValue: '',
  modal: {
    modalId: 'roles',
    isModalOpen: false,
  },
  formFields: {
    roleId: null,
    name: '',
    type: GUARD_NAME_WEB,
    description: '',
  },
  formErrors: {},
};

export const getRoles = createAsyncThunk(
  'roles/getRoles',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().roles;
      const { data, status } = await axios.get('/roles', { params });

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRole = createAsyncThunk(
  'roles/removeRole',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/roles/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getRoles());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createRole = createAsyncThunk(
  'role/createRole',
  async (arh, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().roles;
      const errors = validateErrors(formFields, validateRoleFrom);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const { data, status } = await axios.post('/roles', {
        name: formFields.name,
        guard_name: formFields.type,
        description: formFields.description,
      });

      if (status === httpStatusCodes.CREATED || status === httpStatusCodes.OK) {
        dispatch(getRoles());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, action) => ({
      ...state, ...action.payload
    }),
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setTableHeadInput: (state, action) => {
      state.nameHeadValue = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateRoleFrom(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(deleteRole.pending, (state) => {
        state.error = undefined;
        state.isDeleting = true;
      })
      .addCase(deleteRole.fulfilled, (state) => {
        state.isDeleting = false;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.error = action.payload;
        state.isDeleting = false;
      });
    builder
      .addCase(createRole.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createRole.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createRole.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
  }
});

export const {
  resetState,
  setData,
  setModalData,
  setPage,
  setSort,
  setQueryParamsFilters,
  resetModal,
  setTableHeadInput,
  setFormFields,
  setFormErrors,
  setValidateFormField,
} = rolesSlice.actions;

export default rolesSlice.reducer;
