import Link from 'next/link';
import PropTypes from 'prop-types';

import useMenuClose from '../../../hooks/useMenuClose';

import styles from '../sass/Sidebar.module.scss';

const SidebarLink = ({
  text, link, icon = null, active = false, numberNotification, onBadgeClick = undefined,
}) => {
  const handleMenuClose = useMenuClose();

  return (
    <li className={styles.navItem}>
      <Link
        href={link}
        className={`${styles.navLink} ${active ? styles.active : ''}`}
        data-text-content={text}
        onClick={handleMenuClose}
      >
        <div className={styles.wrapper}>
          {icon ? (
            <span role="img" className={styles.icon}>
              {icon}
            </span>
          ) : null}
          {text}
        </div>
        {numberNotification > 0 ? (
          <button className={styles.badgeButton} type="button" onClick={onBadgeClick}>
            <p className={styles.navLinkCount}>{numberNotification}</p>
          </button>
        ) : null}
      </Link>
    </li>
  );
};

SidebarLink.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string.isRequired,
  numberNotification: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onBadgeClick: PropTypes.func,
};

export default SidebarLink;
