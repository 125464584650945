import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import {
  ASSETS_LIST_SET_SUBMIT, ASSETS_RESET_FILTERS,
  ASSETS_RESET_MODAL, ASSETS_SET_ABORT, ASSETS_SET_ASSETS, ASSETS_SET_EXPORT_LOADING,
  ASSETS_SET_LOADING,
  ASSETS_SET_LOG_ERROR, ASSETS_SET_MODAL_DATA, ASSETS_SET_MODAL_FIELDS, ASSETS_SET_PAGE, ASSETS_SET_PAGE_FILTER_DATA,
  ASSETS_SET_QUERY_PARAMS, ASSETS_SET_SORT, ASSETS_SET_TABLE_COLUMNS,
  ASSETS_SET_TABLE_HEAD_INPUT
} from './types';

export const initialState = {
  isExporting: false,
  isDataLoading: true,
  data: [],
  links: {},
  meta: {},
  isSubmit: false,
  isDeleteSubmit: false,
  modalErrors: {},
  categoriesData: [],
  queryParams: {
    filters: {
      id: [],
      name: '',
      inventory: '',
      serial: '',
      category_name: '',
      category_id: [],
      part_number: '',
      ean_number: 0,
      user_id: [],
      user_name: '',
      show_unassigned: false,
      show_deleted: false,
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    with: ['asset_category', 'active_assignment'],
    sort: '-created_at'
  },
  pageFilters: {
    categoriesName: '',
    isCategoriesOpen: false,
    employeeName: '',
    isEmployeeOpen: false,
    isColumnsOpen: false
  },
  tableColumns: {
    category: true,
    createdAt: true,
    name: true,
    inventoryNumber: true,
    serialNumber: true,
    partNumber: true,
    eanNumber: true,
    employee: true,
    department: true,
    sharedDevice: true,
    amount: true,
    currency: true,
    currencyRate: true,
    description: true,
    action: true,
  },
  tableHeadInput: {
    nameInputValue: '',
    inventoryInputValue: '',
    serialInputValue: '',
    partInputValue: '',
    eanInputValue: '',
    departmentInputValue: '',
  },
  assetsModal: {
    modalId: 'assetsModal',
    isModalOpen: false,
    isCategoryOpen: false,
    isEmployeeOpen: false,
    isPartNumberOpen: false,
    isNameOpen: false,
    tittle: '',
    searchEmployeeValue: '',
    shared_device: false,
    isCurrencyOpen: false,
    currencies: [],
    isDepartmentOpen: false,
    departmentSearchValue: '',
    isAssign: false
  },
  assetsWarningModal: {
    modalId: 'assetsWarningModal',
    isModalOpen: false,
  },
  assetsFields: {
    asset_id: null,
    category: null,
    name: '',
    inventory_number: '',
    serial_number: '',
    part_number: '',
    ean_number: '',
    description: '',
    employee: {},
    user_id: '',
    amount: '',
    currency: '',
    currency_rate: '',
    department: {}
  },
  abortProps: {
    abortAssets: {},
  },
};

export default function assets(state = initialState, action) {
  switch (action.type) {
    case ASSETS_SET_EXPORT_LOADING:
      return {
        ...state,
        isExporting: action.isExporting,
      };
    case ASSETS_RESET_FILTERS:
      return {
        ...state,
        data: [],
        meta: {},
        isDataLoading: true,
        tableHeadInput: initialState.tableHeadInput,
        pageFilters: initialState.pageFilters,
        queryParams: initialState.queryParams,
      };
    case ASSETS_SET_LOADING:
      return {
        ...state,
        meta: {},
        queryParams: {
          ...state.queryParams,
          page: null,
        },
        isDataLoading: action.isLoading,
      };
    case ASSETS_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case ASSETS_SET_ASSETS:
      return {
        ...state,
        isDataLoading: false,
        ...action.assets,
      };
    case ASSETS_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    case ASSETS_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    case ASSETS_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          }
        }
      };
    case ASSETS_SET_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          ...action.columns
        }
      };
    case ASSETS_SET_TABLE_HEAD_INPUT:
      return {
        ...state,
        tableHeadInput: {
          ...state.tableHeadInput,
          ...action.tableHeadInput,
        }
      };
    case ASSETS_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case ASSETS_RESET_MODAL:
      return {
        ...state,
        assetsFields: initialState.assetsFields,
        modalErrors: initialState.modalErrors,
        assetsModal: initialState.assetsModal,
      };
    case ASSETS_SET_MODAL_FIELDS:
      return {
        ...state,
        assetsFields: {
          ...state.assetsFields,
          ...action.data
        }
      };
    case ASSETS_LIST_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case ASSETS_SET_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    case ASSETS_SET_PAGE_FILTER_DATA:
      return {
        ...state,
        pageFilters: {
          ...state.pageFilters,
          ...action.data
        }
      };
    default:
      return state;
  }
}
