import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';
import { parseJsonOrGetString } from '../../utils/helpers';
import { sendFiles } from '../../utils/sendFiles';

export const initialState = {
  data: {
    assignee: null,
    summary: '',
    description: null,
    status: '',
    images: [],
    ticketId: '',
    key: '',
    permissions: {},
  },
  commentAttachments: [],
  comments: [],
  modalProps: {
    isModalOpen: false,
    modalId: 'closeTicket',
    commentToDeleteId: null,
  },
  isLoading: false,
  isCommentLoading: false,
  isFilesLoading: false,
  isSubmit: false,
  errors: [],
};

export const fetchTicket = createAsyncThunk(
  'projectSingleTicket/fetchTicket',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { projectSingleTicket: { data: { ticketId } } } = getState();
      const { data } = await axios.get(`project_tickets/${ticketId}`);
      const images = data.files.map((file) => ({ file, src: file.original_url, name: file.file_name }));
      const parsedDescription = parseJsonOrGetString(data.description);
      return {
        ...data, description: parsedDescription, images
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchComments = createAsyncThunk(
  'projectSingleTicket/fetchComments',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { projectSingleTicket: { data: { ticketId } } } = getState();
      const params = {
        filters: { commentable_type: 'ProjectTicket', commentable_id: ticketId },
        with: ['media']
      };

      const { data } = await axios.get('model_comments', { params });

      return data.map((item) => ({
        ...item,
        attachments: item.files.map((file) => ({ file, src: file.original_url, name: file.file_name }))
      }));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTicketStatus = createAsyncThunk(
  'projectSingleTicket/updateTicketStatus',
  async ({ status }, { rejectWithValue, getState }) => {
    const { data: { ticketId } } = getState().projectSingleTicket;

    try {
      const { data } = await axios.put(`project_tickets/${ticketId}`, { status });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const attachFilesToTicket = createAsyncThunk(
  'projectSingleTicket/attachFilesToTicket',
  async ({ ticketId, files }, { rejectWithValue }) => {
    try {
      let mediaIds = [];
      const filesToUpload = files.filter(({ file: { id } }) => {
        if (id) {
          mediaIds.push(id);
          return false;
        }
        return true;
      });
      if (filesToUpload.length > 0) {
        const mediaData = await sendFiles(filesToUpload.map(({ file }) => file));

        if (mediaData.error) {
          return rejectWithValue(mediaData);
        }

        mediaIds = [...mediaIds, ...mediaData.data];
        mediaIds = mediaIds.filter((item) => item);
      }

      const { data } = await axios.put(`project_tickets/${ticketId}`, { file_ids: mediaIds });

      return {
        ...data,
        description: data.description_json,
        images: data.files.map((file) => ({ file, src: file.original_url, name: file.file_name }))
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTicket = createAsyncThunk(
  'projectSingleTicket/updateTicket',
  async ({ ...data }, { rejectWithValue, getState }) => {
    const {
      data: { ticketId }
    } = getState().projectSingleTicket;

    try {
      const { data: ticket } = await axios.put(`project_tickets/${ticketId}`, data);

      return {
        ...ticket,
        description: ticket.description_json,
        images: ticket.files.map((file) => ({ file, src: file.original_url, name: file.file_name }))
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAssignee = createAsyncThunk(
  'projectSingleTicket/updateAssignee',
  async ({ assignee }, { rejectWithValue, getState }) => {
    const { data: { ticketId } } = getState().projectSingleTicket;

    try {
      const { data } = await axios.put(`project_tickets/${ticketId}`, { assignee_id: assignee?.id || null });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postComment = createAsyncThunk(
  'projectSingleTicket/postComment',
  async ({ comment }, { rejectWithValue, getState }) => {
    try {
      const {
        projectSingleTicket: {
          data: { ticketId },
          commentAttachments: files,
        },
      } = getState();

      let mediaIds = [];

      if (files.length > 0) {
        const mediaData = await sendFiles(files.map(({ file }) => file));

        if (mediaData.error) {
          return rejectWithValue(mediaData);
        }
        mediaIds = [...mediaData.data];
      }

      const { data } = await axios.post('model_comments', {
        commentable_type: 'ProjectTicket',
        commentable_id: ticketId,
        comment_json: comment,
        file_ids: mediaIds,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateComment = createAsyncThunk(
  'projectSingleTicket/updateComment',
  async ({ id, comment }, { rejectWithValue, getState }) => {
    try {
      const { projectSingleTicket: { comments } } = getState();
      const commentFiles = comments.find((item) => item.id === id).attachments;

      let fileIds = [];

      const filesToUpload = commentFiles.filter(({ file: { id: fileId } }) => {
        if (fileId) {
          fileIds.push(fileId);
          return false;
        }
        return true;
      });

      if (filesToUpload.length > 0) {
        const mediaData = await sendFiles(filesToUpload.map(({ file }) => file));

        if (mediaData.error) {
          return rejectWithValue(mediaData);
        }

        fileIds = [...fileIds, ...mediaData.data].filter(Boolean);
      }

      const { data } = await axios.put(`model_comments/${id}`, {
        comment_json: comment,
        file_ids: fileIds,
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateFilesByComment = createAsyncThunk(
  'projectSingleTicket/updateFilesByComment',
  async ({ commentId, files }, { rejectWithValue }) => {
    try {
      let fileIds = [];
      const filesToUpload = files.filter(({ file: { id } }) => {
        if (id) {
          fileIds.push(id);
          return false;
        }
        return true;
      });
      if (filesToUpload.length > 0) {
        const { data: uploadIds } = await sendFiles(filesToUpload.map(({ file }) => file));
        fileIds = [...fileIds, ...uploadIds];
        fileIds = fileIds.filter(Boolean);
      }

      const { data } = await axios.put(`model_comments/${commentId}`, {
        file_ids: fileIds,
      });

      return {
        ...data,
        attachments: data.files.map((file) => ({ file, src: file.original_url, name: file.file_name })),
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteComment = createAsyncThunk(
  'projectSingleTicket/deleteComment',
  async (commentId, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(`model_comments/${commentId}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const projectSingleTicketSlice = createSlice({
  name: 'projectSingleTicket',
  initialState,
  reducers: {
    resetState: () => initialState,
    setCommentAttachments: (state, action) => {
      state.isFilesLoading = true;
      if (action.payload.id) {
        const findIndex = state.comments.findIndex(({ id }) => id === action.payload.id);

        if (findIndex !== -1) {
          state.comments[findIndex] = { ...state.comments[findIndex], attachments: action.payload.files };
        }
      } else {
        state.commentAttachments = action.payload.files;
      }
      state.isFilesLoading = false;
    },
    setFieldsData: (state, action) => {
      state.data[action.payload.fieldName] = action.payload.fieldValue;
    },
    toggleModal: (state, action) => {
      state.modalProps.isModalOpen = action.payload.isModalOpen;
      state.modalProps.modalId = action.payload.modalId;
      state.modalProps.commentToDeleteId = action.payload.commentToDeleteId;
    },
    setBtnLoader: (state, action) => {
      state.isCommentLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateFilesByComment.pending, (state) => {
        state.isFilesLoading = true;
      })
      .addCase(updateFilesByComment.fulfilled, (state, action) => {
        const findIndex = state.comments.findIndex(({ id }) => id === action.meta.arg.commentId);

        if (findIndex !== -1) {
          state.comments[findIndex] = { ...action.payload };
        }
        state.isFilesLoading = false;
      })
      .addCase(updateFilesByComment.rejected, (state) => {
        state.isFilesLoading = false;
      });

    builder
      .addCase(fetchTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTicket.fulfilled, (state, action) => {
        state.data = { ...action.payload, ticketId: action.payload.id };
        state.isLoading = false;
      })
      .addCase(fetchTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      });

    builder
      .addCase(updateTicket.fulfilled, (state, action) => {
        state.data = { ...action.payload, ticketId: action.payload.id };
      });

    builder
      .addCase(updateTicketStatus.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(updateTicketStatus.fulfilled, (state, action) => {
        state.data = { ...state.data, status: action.payload.status };
        state.modalProps.isModalOpen = false;
        state.isSubmit = false;
      });

    builder
      .addCase(updateAssignee.fulfilled, (state, action) => {
        state.data.assignee = action.payload?.assignee;
      });

    builder
      .addCase(fetchComments.pending, (state) => {
        state.isCommentLoading = true;
      })
      .addCase(fetchComments.fulfilled, (state, action) => {
        state.isCommentLoading = false;
        state.comments = action.payload;
      })
      .addCase(fetchComments.rejected, (state, action) => {
        state.isCommentLoading = false;
        state.errors = action.payload;
      });

    builder
      .addCase(postComment.pending, (state) => {
        state.isCommentLoading = true;
      })
      .addCase(postComment.fulfilled, (state) => {
        state.isCommentLoading = false;
      })
      .addCase(postComment.rejected, (state) => {
        state.isCommentLoading = false;
      });

    builder
      .addCase(updateComment.pending, (state) => {
        state.isCommentLoading = true;
      })
      .addCase(updateComment.fulfilled, (state) => {
        state.isCommentLoading = false;
      })
      .addCase(updateComment.rejected, (state) => {
        state.isCommentLoading = false;
      });

    builder
      .addCase(deleteComment.pending, (state) => {
        state.isCommentLoading = true;
      })
      .addCase(deleteComment.fulfilled, (state) => {
        state.isCommentLoading = false;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.isCommentLoading = false;
        state.errors = action.payload;
      });

    builder
      .addCase(attachFilesToTicket.rejected, (state, action) => {
        const idsToRemove = action.payload.data.map(({ id }) => id);

        state.data.images = state.data.images.filter(({ name }) => !idsToRemove.includes(name));
      });
  }
});

export const {
  resetState,
  setFieldsData,
  toggleModal,
  setCommentAttachments,
  setBtnLoader
} = projectSingleTicketSlice.actions;

export default projectSingleTicketSlice.reducer;
