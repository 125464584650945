import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import {
  EMPLOYEE_PROJECTS_FETCH_DATA, EMPLOYEE_PROJECTS_SET_PAGE, EMPLOYEE_PROJECTS_SET_QUERY_PARAMS,
  EMPLOYEE_PROJECTS_SET_TABLE_HEAD_INPUT
} from './types';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  tableHeadInput: {
    nameInputValue: '',
  },
  queryParams: {
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    filters: {
      project_name: '',
    },
  },
};

export default function employeeProjects(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_PROJECTS_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case EMPLOYEE_PROJECTS_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page,
        },
      };
    case EMPLOYEE_PROJECTS_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          }
        }
      };
    case EMPLOYEE_PROJECTS_SET_TABLE_HEAD_INPUT:
      return {
        ...state,
        tableHeadInput: {
          ...state.tableHeadInput,
          ...action.tableHeadInput,
        }
      };
    default:
      return state;
  }
}
