import { shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { COPYRIGHT_START_YEAR } from '../../../constants/values';
import { useSelector } from '../../../hooks/redux';
import { getLocaleString } from '../../../utils/helpers';

import styles from '../sass/Sidebar.module.scss';

const Versions = ({ isMinimized }) => {
  const { t } = useTranslation('common');
  const apiInfo = useSelector(({ general }) => general.apiInfo, shallowEqual);

  return (
    <div className={styles.versions}>
      {process.env.NEXT_PUBLIC_VERSION && process.env.NEXT_PUBLIC_TIMESTAMP && (
        <p>
          {`${t('client')}: ${process.env.NEXT_PUBLIC_VERSION}`
          + ` ${isMinimized ? '' : `(${getLocaleString(process.env.NEXT_PUBLIC_TIMESTAMP * 1000)})`}`}
        </p>
      )}
      {apiInfo.version && (
        <p>
          {`${t('API')}: ${apiInfo.version}`
          + ` ${isMinimized ? '' : `(${getLocaleString(apiInfo.version_timestamp * 1000)})`}`}
        </p>
      )}
      {!isMinimized && (
        <span className={styles.copyright}>
          {`(c) Onix ${COPYRIGHT_START_YEAR}-${new Date().getFullYear()}`}
        </span>
      )}
    </div>
  );
};

Versions.propTypes = {
  isMinimized: PropTypes.bool.isRequired,
};

export default Versions;
