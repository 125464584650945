import { MAX_FETCH_DATA_PER_PAGE } from '../../constants/values';
import {
  PROJECT_MANAGERS_FETCH_HISTORY, PROJECT_MANAGERS_SET_MANAGER_DATA, PROJECT_MANAGERS_SET_MODAL_DATA,
  PROJECT_MANAGERS_SET_MODAL_ERROR, PROJECT_MANAGERS_SET_QUERY,
  PROJECT_MANAGERS_TOGGLE_AND_SET_MODAL_WARNING, PROJECT_MANAGERS_TOGGLE_MODAL 
} from './types';

export const initialState = {
  managersHistory: [],
  meta: {},
  query: {
    page: null,
    per_page: MAX_FETCH_DATA_PER_PAGE,
  },
  modalProps: {
    modalId: 'projectManagerModal',
    isModalOpen: false,
    isManagerOpen: false,
    managerSearchValue: '',
    isSubmit: false
  },
  managersFields: {
    historyId: null,
    date: new Date(),
    actualDay: new Date(),
    disabledDay: new Date(),
    managerId: '',
    manager: null,
    isDatePickerOpen: false,
    disabledInput: false
  },
  modalWarningProps: {
    modalId: 'warningManagersHistoryModal',
    isModalOpen: false,
    historyItemId: null,
    managerName: null,
    isSubmit: false
  },
  modalErrors: {}
};

export default function projectManagers(state = initialState, action) {
  switch (action.type) {
    case PROJECT_MANAGERS_SET_QUERY:
      return {
        ...state,
        query: { ...state.query, ...action.query }
      };
    case PROJECT_MANAGERS_FETCH_HISTORY:
      return {
        ...state,
        managersHistory: action.history.data,
        meta: action.history.meta,
      };
    case PROJECT_MANAGERS_TOGGLE_MODAL:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          isModalOpen: action.isOpen
        },
      };
    case PROJECT_MANAGERS_SET_MANAGER_DATA:
      return {
        ...state,
        managersFields: {
          ...state.managersFields,
          ...action.data
        }
      };
    case PROJECT_MANAGERS_SET_MODAL_DATA:
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          ...action.data
        },
      };
    case PROJECT_MANAGERS_TOGGLE_AND_SET_MODAL_WARNING:
      return {
        ...state,
        modalWarningProps: {
          ...state.modalWarningProps,
          ...action.data
        },
      };
    case PROJECT_MANAGERS_SET_MODAL_ERROR:
      return {
        ...state,
        modalErrors: action.errors,
      };
    default:
      return state;
  }
}
