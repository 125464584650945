import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import Avatar from '../../../components/Avatar/Avatar';
import { EMPLOYEE_WORK_STATUS_EMOJI } from '../../../constants/emoji';

import styles from '../sass/Header.module.scss';

const HeaderAvatar = ({ profile }) => {
  const { t } = useTranslation('common');
  const isVacation = profile.on_vacation;

  return (
    <div
      className={styles.profileBtnAvatar}
      style={{ width: isVacation ? '80px' : '40px' }}
    >
      <div className={`${profile.on_vacation ? styles.statusPlace : ''}`}>
        {profile.on_vacation ? EMPLOYEE_WORK_STATUS_EMOJI.vacation : undefined}
        {Object.keys(profile).length > 0 ? (
          <Avatar image={profile.photo48_link} alt={profile.username} width={40} height={40} />
        ) : <img src="/images/avatar.svg" alt={t('avatar')} width="40" height="40" />}
      </div>
    </div>
  );
};

HeaderAvatar.propTypes = {
  profile: PropTypes.shape({
    photo48_link: PropTypes.string,
    username: PropTypes.string,
    on_vacation: PropTypes.bool,
  }).isRequired,
};

export default HeaderAvatar;
