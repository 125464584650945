import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';

import {
  EXPENSE_REPORTS,
  EXPENSE_REPORTS_ID,
  EXPENSE_REPORTS_ID_APPROVE,
  EXPENSE_REPORTS_ID_REJECT
} from '../../constants/apiReqUrls';
import {
  CURRENCIES, EXPENSE_REPORT_MODAL_TYPE, SORT_BY_CREATED_AT
} from '../../constants/common';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { CREATED, NO_CONTENT, OK } from '../../constants/httpStatusCodes';
import { EXPENSE_REPORT_STATUSES } from '../../constants/status';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { getNewImages, sendFiles } from '../../utils/sendFiles';
import {
  validateApproveExpenseReport,
  validateCreateExpenseReport,
  validateErrors,
} from '../../utils/validators';
import { createNonConcurrentAsyncThunk } from '../utils/createNonConcurrentAsyncThunk';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  queryParams: {
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    filters: {
      user: {
        id: [],
        search: '',
      },
      salary_correction_type: { id: [] },
      comment: '',
      search: '',
      created_at: '',
      month: null,
      status: EXPENSE_REPORT_STATUSES,
      show_own: false,
    },
    with: ['user', 'salary_correction_type'],
    sort: `-${SORT_BY_CREATED_AT}`,
  },
  expenseModal: {
    modalId: 'expenseModal',
    isModalOpen: false,
    reportId: null,
    type: EXPENSE_REPORT_MODAL_TYPE.create,
  },
  rejectModal: {
    modalId: 'rejectModal',
    isModalOpen: false,
    reportId: null,
    comment: '',
  },
  deleteModal: {
    modalId: 'deleteModal',
    isModalOpen: false,
    reportId: null,
  },
  formErrors: {},
  formFields: {
    employee: undefined,
    salaryCorrectionType: undefined,
    date: new Date(),
    amount: '',
    currency: CURRENCIES.UAH,
    userComment: '',
    images: [],
    approvedAmount: '',
    currencyRate: '',
  },
  isLoading: false,
  isSubmitting: false,
  error: undefined,
};

export const getExpenseReports = createNonConcurrentAsyncThunk(
  'expenseReports/getExpenseReports',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { queryParams: params } = getState().expenseReports;

      const { data, status } = await axios.get(EXPENSE_REPORTS, { params });

      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getExpenseReportById = createNonConcurrentAsyncThunk(
  'expenseReports/getExpenseReportById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(EXPENSE_REPORTS_ID.replace('{expense_report_id}', id));

      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createNewOrEditReport = createAsyncThunk(
  'expenseReports/createNewOrEditReport',
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { formFields } = getState().expenseReports;
      const formErrors = validateErrors(formFields, validateCreateExpenseReport);

      if (formErrors) {
        return rejectWithValue({ isFormValidation: true, errors: formErrors });
      }

      let url = EXPENSE_REPORTS;
      let method = httpRequestMethods.POST;
      let newFiles = formFields.images;
      let prevMediaIds = [];

      if (id) {
        url = EXPENSE_REPORTS_ID.replace('{expense_report_id}', id);
        method = httpRequestMethods.PUT;
        prevMediaIds = newFiles
          .filter(({ file }) => !(file instanceof File && file))
          .map(({ file }) => file.id);
      } else {
        newFiles = await getNewImages(newFiles);
      }

      const files = newFiles
        .filter(({ file }) => file instanceof File && file)
        .map(({ file }) => file);

      let mediaIds = {
        data: files,
      };

      if (files.length > 0) {
        mediaIds = await sendFiles(files);
      }

      if (mediaIds.error) {
        return rejectWithValue(mediaIds);
      }

      const { data, status } = await axios({
        url,
        method,
        data: {
          amount: formFields.amount,
          currency: formFields.currency,
          user_comment: formFields.userComment,
          salary_correction_type_id: formFields.salaryCorrectionType?.id,
          file_ids: id ? [...prevMediaIds, ...mediaIds.data] : [...mediaIds.data],
          date: formatStringDate(formFields.date, 'yyyy-MM-dd')
        },
      });

      if (status === CREATED || status === OK) {
        dispatch(getExpenseReports());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const approveExpenseReport = createAsyncThunk(
  'expenseReports/approveExpenseReport',
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { formFields } = getState().expenseReports;

      const formErrors = validateErrors(formFields, validateApproveExpenseReport);

      if (formErrors) {
        return rejectWithValue({ isFormValidation: true, errors: formErrors });
      }

      const { data, status } = await axios.post(EXPENSE_REPORTS_ID_APPROVE.replace('{expense_report_id}', id), {
        salary_correction_type_id: formFields.salaryCorrectionType?.id,
        approved_amount: formFields.approvedAmount,
        currency_rate: formFields.currencyRate,
        user_comment: formFields.userComment,
      });

      if (status === OK) {
        dispatch(getExpenseReports());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const rejectExpenseReportById = createAsyncThunk(
  'expenseReports/rejectExpenseReportById',
  async (id, { rejectWithValue, getState, dispatch }) => {
    try {
      const { rejectModal: { comment } } = getState().expenseReports;

      const { data, status } = await axios.post(EXPENSE_REPORTS_ID_REJECT.replace('{expense_report_id}', id), {
        accountant_comment: comment
      });

      if (status === OK) {
        dispatch(getExpenseReports());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteExpenseReportById = createAsyncThunk(
  'expenseReports/deleteExpenseReportById',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      const { data, status } = await axios.delete(EXPENSE_REPORTS_ID.replace('{expense_report_id}', id));

      if (status === NO_CONTENT) {
        dispatch(getExpenseReports());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const expenseReportsSlice = createSlice({
  name: 'expenseReports',
  initialState,
  reducers: {
    resetState: () => initialState,

    setQueryParams: (state, action) => {
      const { filters, ...rest } = action.payload;
      state.queryParams = {
        ...state.queryParams,
        ...rest,
        filters: { ...state.queryParams.filters, ...filters },
      };
      if (!action.payload?.sort) {
        state.queryParams.page = null;
      }
    },

    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },

    setModalData: (state, action) => {
      const { key, ...rest } = action.payload;
      state[key] = { ...state[key], ...rest };
    },

    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.expenseModal = initialState.expenseModal;
      state.rejectModal = initialState.rejectModal;
      state.deleteModal = initialState.deleteModal;
      state.formFields = initialState.formFields;
    },

    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },

    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },

    setValidateFormField: (state, action) => {
      const validateFunction = state.expenseModal.type === EXPENSE_REPORT_MODAL_TYPE.review
        ? validateApproveExpenseReport
        : validateCreateExpenseReport;
      const fieldError = validateFunction(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExpenseReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExpenseReports.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getExpenseReports.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });

    builder
      .addCase(getExpenseReportById.fulfilled, (state, action) => {
        state.expenseModal = {
          ...state.expenseModal,
          isModalOpen: true,
          reportId: action.payload.id,
          stateModal: action.meta.arg.type
        };
        state.formFields = {
          ...state.formFields,
          employee: action.payload.user,
          salaryCorrectionType: action.payload.salary_correction_type,
          images: action.payload.files?.map((file) => ({ file, src: file.original_url, name: file.name })) || [],
          date: parseISO(action.payload.date),
          amount: action.payload.amount,
          userComment: action.payload.user_comment || '',
          currency: action.payload.currency,
          approved_amount: action.payload.approvedAmount || '',
          currency_rate: action.payload.currencyRate || '',
        };
      });

    builder
      .addCase(rejectExpenseReportById.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(rejectExpenseReportById.fulfilled, (state) => {
        state.expenseModal = initialState.expenseModal;
        state.rejectModal = initialState.rejectModal;
        state.formFields = initialState.formFields;
        state.isSubmitting = false;
      })
      .addCase(rejectExpenseReportById.rejected, (state) => {
        state.isSubmitting = false;
      });

    builder
      .addCase(approveExpenseReport.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(approveExpenseReport.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.expenseModal = initialState.expenseModal;
        state.isSubmitting = false;
      })
      .addCase(approveExpenseReport.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmitting = false;
      });

    builder
      .addCase(deleteExpenseReportById.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(deleteExpenseReportById.fulfilled, (state) => {
        state.deleteModal = initialState.deleteModal;
        state.isSubmitting = false;
      })
      .addCase(deleteExpenseReportById.rejected, (state) => {
        state.isSubmitting = false;
      });

    builder
      .addCase(createNewOrEditReport.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(createNewOrEditReport.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.expenseModal = initialState.expenseModal;
        state.isSubmitting = false;
      })
      .addCase(createNewOrEditReport.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmitting = false;
      });
  },
});

export const {
  resetState,
  setQueryParams,
  setPage,
  setModalData,
  resetModal,
  setFormFields,
  setValidateFormField,
} = expenseReportsSlice.actions;

export default expenseReportsSlice.reducer;
