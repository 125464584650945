import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { validateApplicationFrom, validateErrors } from '../../utils/validators';

export const initialState = {
  isLoading: false,
  isSubmit: false,
  isDeleteSubmit: false,
  data: [],
  meta: {},
  query: {
    filters: {
      id: [],
      name: '',
      client_id: '',
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  nameInputValue: '',
  modal: {
    modalId: 'applicationModal',
    isModalOpen: false,
  },
  formFields: {
    applicationId: '',
    name: '',
    clientId: '',
  },
  formErrors: {},
  deleteModalId: null,
};

export const getApplications = createAsyncThunk(
  'applications/getApplications',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { query: params } = getState().applications;
      const { data, status } = await axios.get('/applications', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditApplication = createAsyncThunk(
  'applications/createOrEditApplication',
  async ({ id }, { dispatch, rejectWithValue, getState }) => {
    try {
      const { formFields } = getState().applications;

      const errors = validateErrors(formFields, validateApplicationFrom);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = httpRequestMethods.POST;
      let url = '/applications';

      if (id) {
        method = httpRequestMethods.PATCH;
        url = `/applications/${id}`;
      }

      const { data, status } = await axios({
        method,
        url,
        data: { name: formFields.name, client_id: formFields.clientId },
      });

      if (status === httpStatusCodes.OK || status === httpStatusCodes.CREATED) {
        dispatch(getApplications());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteApplication = createAsyncThunk(
  'applications/deleteApplication',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const { status, data } = await axios.delete(`/applications/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getApplications());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, action) => ({
      ...state, ...action.payload,
    }),
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
      state.query.page = null;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
      state.query.page = null;
    },
    setPage: (state, action) => {
      state.query.page = action.payload;
    },
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    resetModal: (state) => {
      state.modal = initialState.modal;
      state.formErrors = initialState.formErrors;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateApplicationFrom(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getApplications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApplications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(getApplications.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createOrEditApplication.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditApplication.fulfilled, (state) => {
        state.isSubmit = false;
        state.modal = initialState.modal;
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
      })
      .addCase(createOrEditApplication.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(deleteApplication.pending, (state) => {
        state.isDeleteSubmit = true;
      })
      .addCase(deleteApplication.fulfilled, (state) => {
        state.isDeleteSubmit = false;
        state.deleteModalId = null;
      })
      .addCase(deleteApplication.rejected, (state) => {
        state.isDeleteSubmit = false;
      });
  },
});

export const {
  resetState,
  setQueryFilter,
  setQuery,
  setPage,
  setFormErrors,
  setFormFields,
  setValidateFormField,
  setModalData,
  setData,
  resetModal
} = applicationsSlice.actions;

export default applicationsSlice.reducer;
