import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import Router from 'next/router';

import {
  MILESTONE_DEDICATED_TEAM_TYPE, MILESTONE_FP_HIDE_HOURS_RATES,
  MILESTONE_FP_SHOW_HOURS_RATES,
  MILESTONE_SUPPORT_TYPE,
  MILESTONE_TM_SCOPE_BASED_TYPE,
} from '../../constants/types';
import { MILESTONE_BILLABILITY_LIMIT, SECONDS_IN_HOUR } from '../../constants/values';
import axios from '../../services/axios';
import autoCalcPlannedBilledTime, { autoCalcTotalPlannedApprovedTime, multiplyDecimals } from '../../utils/aggrements';
import {
  formatStringDate,
  parseWorklogTimeToSeconds,
  toHoursAndMinutes
} from '../../utils/helpers';
import { newValidateErrors, validateErrors, validateMilestoneItem } from '../../utils/validators';
import { calculateWorkingHours } from '../workingHours/slice';

const date = new Date();

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  milestoneItem: undefined,
  isCalculateWorkingHours: false,
  isEdit: false,
  isSkipCalculateWorkingHours: true,
  errors: {},
  fieldsForm: {
    name: 'Auto',
    invoiceItemText: '',
    startDate: date,
    endDate: undefined,
    plannedApprovedHours: '40h',
    plannedApprovedTime: 0,
    plannedBilledTime: 0,
    totalPlannedApprovedTime: 0,
    plannedBilledHours: '',
    plannedBillability: '',
    reasonPartialBillability: '',
    users: [],
    workloadType: null,
    service: null,
    autogenerateName: true,
    autogenerateInvoiceItemText: true,
  },
  formProps: {
    isStartDateOpen: false,
    isEndDateOpen: false,
    isServiceOpen: false,
  },
};

const iSupport = (state) => state.milestoneItem?.client_product_milestone?.type === MILESTONE_SUPPORT_TYPE;
const isBillabilityReq = (type) => type === MILESTONE_DEDICATED_TEAM_TYPE || type === MILESTONE_TM_SCOPE_BASED_TYPE;

export const fetchMilestoneItemAsync = createAsyncThunk(
  'milestoneItemInfo/fetchMilestoneItem',
  async ({ milestoneItemId }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`/milestone_items/${milestoneItemId}`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditMilestoneItemAsync = createAsyncThunk(
  'milestoneItemInfo/addOrEditMilestoneItem',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const {
        milestoneItemInfo: { fieldsForm, milestoneItem, isEdit },
        milestoneItems: { modalProps },
        milestoneInfo,
      } = getState();
      const milestone = milestoneItem?.client_product_milestone || milestoneInfo?.milestone;
      const { query } = Router;
      const type = milestone?.type;
      const errors = validateErrors(
        fieldsForm,
        validateMilestoneItem,
        {
          isBilledHoursReq: milestone.type === MILESTONE_FP_SHOW_HOURS_RATES
            || fieldsForm.type === MILESTONE_FP_HIDE_HOURS_RATES,
          isBillabilityReq: milestone.type === MILESTONE_DEDICATED_TEAM_TYPE
            || fieldsForm.type === MILESTONE_TM_SCOPE_BASED_TYPE,
          isSupportMilestone: milestone.type === MILESTONE_SUPPORT_TYPE,
          isReason: fieldsForm.plannedBillability < MILESTONE_BILLABILITY_LIMIT
            && (isBillabilityReq(type) || type === MILESTONE_SUPPORT_TYPE),
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let url = '/milestone_items';

      if ((milestoneItem && isEdit) || modalProps.milestoneItemId) {
        method = 'PUT';
        url += `/${milestoneItem.id}`;
      }

      const params = {
        name: fieldsForm.name,
        client_agreement_service_id: fieldsForm.service.id,
        client_product_milestone_id: query.milestone || milestone.id,
        start_date: formatStringDate(fieldsForm.startDate, 'yyyy-MM-dd'),
        end_date: formatStringDate(fieldsForm.endDate, 'yyyy-MM-dd'),
        user_ids: fieldsForm.users.map(({ id }) => id),
        planned_approved_time_type: fieldsForm.workloadType,
        invoice_item_text: fieldsForm.invoiceItemText,
        autogenerate_name: fieldsForm.autogenerateName,
        autogenerate_invoice_item_text: fieldsForm.autogenerateInvoiceItemText,
        total_planned_approved_time: fieldsForm.totalPlannedApprovedTime
          ? fieldsForm.totalPlannedApprovedTime.toString()
          : '0',
        planned_approved_time: milestone.type === MILESTONE_SUPPORT_TYPE
          ? null
          : fieldsForm.plannedApprovedTime.toString(),
        planned_billed_time: fieldsForm.plannedBilledTime
          ? Math.round(fieldsForm.plannedBilledTime).toString() : '0',
        billability: fieldsForm.plannedBillability?.toString() || null,
        reason_partial_billability: fieldsForm.reasonPartialBillability?.toString() || null
      };

      const { data, status } = await axios({
        method,
        url,
        data: params
      });

      if (status === 201 || status === 200) {
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const reCalculatePlannedBilled = (state, workingHours, plannedTime) => {
  const data = {
    workhours: workingHours,
    plannedApprovedTime: plannedTime,
    timeType: state.fieldsForm.workloadType,
    isSupport: iSupport(state),
    billibility: iSupport(state) ? 100 : state.fieldsForm.plannedBillability,
  };
  const plannedBilledTime = autoCalcPlannedBilledTime(
    {
      ...data,
      isHours: false
    }
  );

  state.fieldsForm.plannedBilledTime = iSupport(state)
    ? multiplyDecimals(plannedBilledTime, SECONDS_IN_HOUR)
    : plannedBilledTime;
  state.fieldsForm.plannedBilledHours = toHoursAndMinutes(state.fieldsForm.plannedBilledTime);
};

const reCalculateTotalPlannedApprovedTime = (state, workingHours, plannedApprovedTime) => {
  state.fieldsForm.totalPlannedApprovedTime = autoCalcTotalPlannedApprovedTime(
    {
      workhours: workingHours,
      plannedApprovedTime,
      timeType: state.fieldsForm.workloadType,
      isSupport: iSupport(state),
      isHours: false
    }
  );
};

const reCalculateTime = (state, workingHours, currentPlannedApprovedHours = false) => {
  let plannedApprovedTime = 0;

  if (iSupport(state)) {
    state.fieldsForm.plannedApprovedHours = '';
    state.fieldsForm.plannedApprovedTime = plannedApprovedTime;
    state.fieldsForm.totalPlannedApprovedTime = plannedApprovedTime;
    reCalculatePlannedBilled(state, workingHours, 0);
  } else if (state.fieldsForm.workloadType === 'per-milestone') {
    const plannedApprovedHours = currentPlannedApprovedHours
      || toHoursAndMinutes(workingHours * SECONDS_IN_HOUR);

    plannedApprovedTime = parseWorklogTimeToSeconds(plannedApprovedHours);
    state.fieldsForm.plannedApprovedHours = plannedApprovedHours;
    state.fieldsForm.plannedApprovedTime = plannedApprovedTime;
    if (isBillabilityReq(state.milestoneItem?.client_product_milestone?.type)) {
      reCalculatePlannedBilled(state, workingHours, plannedApprovedTime);
    }
    state.errors = {
      ...state.errors,
      ...newValidateErrors(
        { plannedApprovedHours },
        validateMilestoneItem,
      )
    };
    reCalculateTotalPlannedApprovedTime(state, workingHours, plannedApprovedTime);
  } else {
    plannedApprovedTime = parseWorklogTimeToSeconds(state.fieldsForm.plannedApprovedHours);
    state.fieldsForm.plannedApprovedTime = plannedApprovedTime;
    if (isBillabilityReq(state.milestoneItem?.client_product_milestone?.type)) {
      reCalculatePlannedBilled(state, workingHours, plannedApprovedTime);
    }
    reCalculateTotalPlannedApprovedTime(state, workingHours, plannedApprovedTime);
  }
};

export const milestoneItemInfoSlice = createSlice({
  name: 'milestoneItemInfo',
  initialState,
  reducers: {
    resetState: () => initialState,
    setEdit: (state, action) => {
      if (action.payload.isEdit) {
        state.isSkipCalculateWorkingHours = true;
        state.fieldsForm = {
          name: state.milestoneItem.name,
          invoiceItemText: state.milestoneItem.invoice_item_text,
          startDate: parseISO(state.milestoneItem.start_date),
          endDate: state.milestoneItem.end_date ? parseISO(state.milestoneItem.end_date) : undefined,
          plannedBillability: state.milestoneItem.billability ?? '',
          reasonPartialBillability: state.milestoneItem.reason_partial_billability ?? '',
          users: state.milestoneItem.users || [],
          workloadType: state.milestoneItem.planned_approved_time_type,
          plannedApprovedHours: state.milestoneItem.planned_approved_time !== null
            ? toHoursAndMinutes(state.milestoneItem.planned_approved_time) : '',
          plannedApprovedTime: state.milestoneItem.planned_approved_time !== null
            ? state.milestoneItem.planned_approved_time
            : 0,
          plannedBilledHours: state.milestoneItem.planned_billed_time !== null
            ? toHoursAndMinutes(state.milestoneItem.planned_billed_time) : '',
          plannedBilledTime: state.milestoneItem.planned_billed_time !== null
            ? state.milestoneItem.planned_billed_time : 0,
          totalPlannedApprovedTime: state.milestoneItem.total_planned_approved_time !== null
            ? state.milestoneItem.total_planned_approved_time : 0,
          service: state.milestoneItem.agreement_service,
          autogenerateName: state.milestoneItem.autogenerate_name,
          autogenerateInvoiceItemText: state.milestoneItem.autogenerate_invoice_item_text,
        };
      } else {
        state.fieldsForm = initialState.fieldsForm;
        state.errors = initialState.errors;
      }
      state.isEdit = action.payload.isEdit;
      state.isCalculateWorkingHours = true;
    },
    calculateTimeFromHours: (state, action) => {
      state.fieldsForm = { ...state.fieldsForm, ...action.payload };
      reCalculateTime(state, 0, action.payload.plannedApprovedTime);
    },
    calcPlannedBiilledTimeFromHours: (state, action) => {
      state.fieldsForm = { ...state.fieldsForm, ...action.payload };
      state.fieldsForm.plannedBilledTime = parseWorklogTimeToSeconds(state.fieldsForm.plannedBilledHours);
    },
    setFormFields: (state, action) => {
      state.fieldsForm = { ...state.fieldsForm, ...action.payload };
      state.errors = {
        ...state.errors,
        ...newValidateErrors(
          { ...action.payload },
          validateMilestoneItem,
          {
            isBilledHoursReq: state.milestoneItem.client_product_milestone.type === MILESTONE_FP_SHOW_HOURS_RATES
              || state.milestoneItem.client_product_milestone.type === MILESTONE_FP_HIDE_HOURS_RATES,
            isBillabilityReq: isBillabilityReq(state.milestoneItem.client_product_milestone.type),
            isSupportMilestone: iSupport(state),
            isReason: state.fieldsForm.plannedBillability < 100
              && (isBillabilityReq(state.milestoneItem.client_product_milestone.type) || iSupport(state))
          }
        )
      };
    },
    setFormProps: (state, action) => {
      state.formProps = { ...state.formProps, ...action.payload };
    },
    setMilestoneItem: (state, action) => {
      state.milestoneItem = action.payload;
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMilestoneItemAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMilestoneItemAsync.fulfilled, (state, action) => {
        state.milestoneItem = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchMilestoneItemAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(addOrEditMilestoneItemAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.errors = {};
      })
      .addCase(addOrEditMilestoneItemAsync.fulfilled, (state, action) => {
        state.isEdit = false;
        state.isSubmit = false;
        state.formProps = initialState.formProps;
        state.fieldsForm = initialState.fieldsForm;
        state.milestoneItem = action.payload;
        state.errors = {};
      })
      .addCase(addOrEditMilestoneItemAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.errors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(calculateWorkingHours.fulfilled, (state, action) => {
        if (action.meta.arg.isCalculateWorkingHours) {
          reCalculateTime(state, action.payload.number_working_hours);
        }
      });
  },
});

export const {
  resetState,
  setEdit,
  setFormFields,
  setFormProps,
  setMilestoneItem,
  setData,
  calculateTimeFromHours,
  calcPlannedBiilledTimeFromHours
} = milestoneItemInfoSlice.actions;

export default milestoneItemInfoSlice.reducer;
