import {
  ADD_ALERT, ADD_ALERTS,
  REMOVE_ALERT, REMOVE_ALERTS 
} from './types';

export const initialState = {
  alerts: []
};

export default function alerts(state = initialState, action) {
  switch (action.type) {
    case ADD_ALERT:
      return {
        alerts: [
          ...state.alerts,
          { ...action.alert }
        ],
      };
    case ADD_ALERTS:
      return {
        alerts: [
          ...state.alerts,
          ...action.alerts
        ],
      };
    case REMOVE_ALERT:
      return {
        alerts: state.alerts.filter((alert) => alert.id !== action.id)
      };
    case REMOVE_ALERTS:
      return {
        alerts: state.alerts.filter((alert) => action.alerts.includes((alert.id)))
      };
    default:
      return state;
  }
}
