import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { endOfMonth, startOfMonth } from 'date-fns';

import { STATUS_ACTIVE } from '../../constants/status';
import {
  VACATION_DAY_OFF, VACATION_SICKNESS, VACATION_VACATION
} from '../../constants/types';
import { MAX_FETCH_DATA_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import {
  formatISODate, formatStringDate, parseHolidaysData, queryStringify
} from '../../utils/helpers';
import {
  composeNPATimes, getDays, getWorklogType, updateNPATimes
} from '../../utils/timesheet';

const date = new Date();
const startDate = startOfMonth(date);
const endDate = endOfMonth(date);

export const initialState = {
  isLoading: true,
  error: undefined,
  data: [],
  worklogs: [],
  dataEmployees: [],
  holidays: [],
  days: getDays(startDate, endDate),
  team: [],
  rows: [],
  monthlyTotal: {
    [VACATION_SICKNESS]: 0,
    [VACATION_VACATION]: 0,
    [VACATION_DAY_OFF]: 0,
  },
  meta: {},
  query: {
    filters: {
      start_date: formatStringDate(startDate, 'y-MM-dd'),
      end_date: formatStringDate(endDate, 'y-MM-dd'),
      project: {
        id: []
      },
      user: {
        id: []
      },
      ticket: {
        key: [VACATION_SICKNESS, VACATION_VACATION, VACATION_DAY_OFF]
      },
    },
    with: ['user'],
    per_page: MAX_FETCH_DATA_PER_PAGE
  },
  queryEmployees: {
    filters: {
      search: '',
      without_groups: true,
      status: [STATUS_ACTIVE],
    },
    sort: 'name',
    page: null,
    per_page: MAX_FETCH_DATA_PER_PAGE
  },
  datepicker: {
    from: startDate,
    to: endDate,
  },
};

export const fetchProjectEmployees = createAsyncThunk(
  'projects/fetchProjectEmployees',
  async (id, { rejectWithValue, getState }) => {
    const { queryEmployees } = getState().projectsVacation;
    const params = {
      ...queryEmployees,
      filters: { ...queryEmployees.filters, project: { id: [id] } }
    };

    try {
      const { data, status } = await axios.get('/project_people', { params });

      return status === 200 ? { parseData: data.data } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchProjectVacationAsync = createAsyncThunk(
  'projects/fetchProjectVacation',
  async (id, { rejectWithValue, getState }) => {
    try {
      const {
        projectsVacation: {
          query,
          team,
          days,
          query: { filters: { ticket: { key } } }
        }
      } = getState();

      let res = {
        data: { data: initialState.data },
        status: 200,
      };

      if (key.length !== 0) {
        res = await axios.get(`/worklogs?${queryStringify(query)}`);
      }
      const { data, status } = res;

      const vacationTeam = [...new Map(team
        .reduce((acc, item) => {
          if (data.data.some((el) => item.user.id === el.user_id)) {
            acc.push(item);
          }

          return acc;
        }, [])
        .map((item) => [item.user.id, item]))
        .values()];

      const parseTeam = vacationTeam
        .map((item) => {
          const worklogs = days.map((day) => {
            return data.data.reduce((dataAcc, elem) => {
              if (item.user.id === elem.user_id) {
                if (day.date.toLocaleDateString() === new Date(formatISODate(elem.date)).toLocaleDateString()) {
                  const type = getWorklogType(elem);
                  const npaTimes = updateNPATimes(
                    composeNPATimes(type, dataAcc.npaTimes?.times || [], {
                      reported: elem.reported_time,
                      over_reported: 0,
                      approved: elem.approved_time,
                      over_approved: 0,
                      billed: elem.billed_time,
                      over_billed: 0,
                    })
                  );

                  return ({
                    ...dataAcc,
                    ...elem,
                    day,
                    npaTimes,
                    type,
                  });
                }

                return { ...dataAcc, day };
              }

              return { ...dataAcc };
            }, {});
          });

          const sumReportedTime = worklogs.reduce((acc, elem) => {
            return elem.ticket?.key ? { ...acc, [elem.ticket.key]: acc[elem.ticket.key] + elem.reported_time } : acc;
          }, {
            [VACATION_SICKNESS]: 0,
            [VACATION_VACATION]: 0,
            [VACATION_DAY_OFF]: 0,
          });

          return ({ ...item, sumReportedTime, worklogs });
        });

      const totalVacations = parseTeam.reduce((acc, { sumReportedTime }) => ({
        [VACATION_SICKNESS]: acc[VACATION_SICKNESS] + sumReportedTime[VACATION_SICKNESS],
        [VACATION_VACATION]: acc[VACATION_VACATION] + sumReportedTime[VACATION_VACATION],
        [VACATION_DAY_OFF]: acc[VACATION_DAY_OFF] + sumReportedTime[VACATION_DAY_OFF],
      }), {
        [VACATION_SICKNESS]: 0,
        [VACATION_VACATION]: 0,
        [VACATION_DAY_OFF]: 0,
      });

      return status === 200 ? {
        data,
        parseTeam,
        monthlyTotal: totalVacations,
      } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchHolidaysAsync = createAsyncThunk(
  'projects/fetchHolidays',
  async (_, { rejectWithValue, getState }) => {
    const { query: { filters } } = getState().projectsVacation;
    const params = {
      filters: {
        start_date: filters.start_date,
        end_date: filters.end_date,
      }
    };

    try {
      const { data, status } = await axios.get('/holidays', { params });

      if (status === 200) {
        const start = new Date(filters.start_date);
        const end = new Date(filters.end_date);
        const parseHolidays = parseHolidaysData(data.data, start, end);
        const days = getDays(start, end, parseHolidays);

        return ({ parseHolidays, days });
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const projectsVacationSlice = createSlice({
  name: 'projectsVacation',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetDatepicker: (state) => {
      state.datepicker.from = state.query.filters.start_date;
      state.datepicker.to = state.query.filters.end_date;
    },
    setInitialData: (state) => {
      state.data = initialState.data;
      state.holidays = initialState.holidays;
      state.rows = initialState.rows;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setDatepicker: (state, action) => {
      state.datepicker = action.payload;
    },
    setPageFilters: (state, action) => {
      const setFilters = [...state.query.filters.ticket.key];

      if (setFilters.includes(action.payload)) {
        const index = setFilters.indexOf(action.payload);
        if (index > -1) {
          setFilters.splice(index, 1);
        }
      } else {
        setFilters.push(action.payload);
      }

      state.query.filters.ticket.key = setFilters;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectVacationAsync.pending, (state) => {
        state.isLoading = true;
        state.projects = undefined;
        state.error = undefined;
      })
      .addCase(fetchProjectVacationAsync.fulfilled, (state, action) => {
        state.rows = action.payload.parseTeam;
        state.monthlyTotal = action.payload.monthlyTotal;
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchProjectVacationAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchProjectEmployees.pending, (state) => {
        state.isLoading = true;
        state.projects = undefined;
        state.error = undefined;
      })
      .addCase(fetchProjectEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.team = action.payload.parseData;
      })
      .addCase(fetchProjectEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchHolidaysAsync.pending, (state) => {
        state.isLoading = true;
        state.projects = undefined;
        state.error = undefined;
      })
      .addCase(fetchHolidaysAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.holidays = action.payload.parseHolidays;
        state.days = action.payload.days;
      })
      .addCase(fetchHolidaysAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setInitialData,
  setQueryFilter,
  setQuery,
  setData,
  resetState,
  setDatepicker,
  setPageFilters,
  resetDatepicker,
} = projectsVacationSlice.actions;

export default projectsVacationSlice.reducer;
