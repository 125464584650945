import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_BY_NAME } from '../../constants/common';
import { SALARY_BONUS_CATEGORY_ENUM } from '../../constants/enums';
import httpRequestMethods from '../../constants/httpRequestMethods';
import { DEFAULT_BONUS_CATEGORY_NAMES } from '../../constants/salaryBonusCategory';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { validateErrors, validateSalaryBonusCategoryForm } from '../../utils/validators';
import { fetchEnumsAsync } from '../enums/slice';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isLoading: false,
  isSubmit: false,
  isActiveSubmit: false,
  queryParams: {
    filters: {
      name: '',
      show_inactive: false,
    },
    with: ['creator'],
    sort: SORT_BY_NAME,
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  nameHeadValue: '',
  modal: {
    modalId: 'salaryBonusCategory',
    isModalOpen: false,
  },
  formFields: {
    correctionTypeId: null,
    name: '',
    xeroId: '',
    includeGrossPnl: false,
    includeHodMargin: false,
    description: '',
    trashed: false,
  },
  formErrors: {},
};

export const getSalaryBonusCategories = createAsyncThunk(
  'salaryBonusCategory/getSalaryBonusCategories',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams: params } = getState().salaryBonusCategory;
      const { data, status } = await axios.get('/salary_bonus_categories', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const removeSalaryBonusCategory = createAsyncThunk(
  'salaryBonusCategory/removeSalaryBonusCategory',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/salary_bonus_categories/${id}`);

      if (status === 204) {
        dispatch(getSalaryBonusCategories());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const restoreSalaryBonusCategory = createAsyncThunk(
  'salaryBonusCategory/restoreSalaryBonusCategory',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.put(`/salary_bonus_categories/${id}/restore`);

      if (status === 200) {
        dispatch(getSalaryBonusCategories());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createOrEditSalaryBonusCategory = createAsyncThunk(
  'salaryBonusCategory/createOrEditSalaryBonusCategory',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().salaryBonusCategory;
      const isXeroReq = !DEFAULT_BONUS_CATEGORY_NAMES.includes(formFields.name);
      const errors = validateErrors(formFields, validateSalaryBonusCategoryForm, { isXeroReq });

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/salary_bonus_categories';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const body = {
        name: formFields.name,
        include_gross_pnl: formFields.includeGrossPnl,
        include_hod_margin: formFields.includeHodMargin,
        description: formFields.description,
      };

      if (isXeroReq) {
        body.xero_account_code = formFields.xeroId;
      }

      const { data, status } = await axios({ url, method, data: body });

      if (status === 201 || status === 200) {
        dispatch(getSalaryBonusCategories());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const salaryBonusCategorySlice = createSlice({
  name: 'salaryBonusCategory',
  initialState,
  reducers: {
    setModalData: (state, action) => {
      state.modal = { ...state.modal, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = action.payload;
    },
    setTableHeadInput: (state, action) => {
      state.nameHeadValue = action.payload;
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.modal = initialState.modal;
      state.formFields = initialState.formFields;
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateSalaryBonusCategoryForm(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSalaryBonusCategories.pending, (state) => {
        state.isLoading = true;
        state.error = undefined;
      })
      .addCase(getSalaryBonusCategories.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.link = action.payload.link;
        state.isLoading = false;
      })
      .addCase(getSalaryBonusCategories.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(removeSalaryBonusCategory.pending, (state) => {
        state.error = undefined;
        state.isActiveSubmit = true;
      })
      .addCase(removeSalaryBonusCategory.fulfilled, (state) => {
        state.isActiveSubmit = false;
        state.formFields.trashed = true;
      })
      .addCase(removeSalaryBonusCategory.rejected, (state, action) => {
        state.error = action.payload;
        state.isActiveSubmit = false;
      });
    builder
      .addCase(restoreSalaryBonusCategory.pending, (state) => {
        state.error = undefined;
        state.isActiveSubmit = true;
      })
      .addCase(restoreSalaryBonusCategory.fulfilled, (state) => {
        state.isActiveSubmit = false;
        state.formFields.trashed = false;
      })
      .addCase(restoreSalaryBonusCategory.rejected, (state, action) => {
        state.error = action.payload;
        state.isActiveSubmit = false;
      });
    builder
      .addCase(createOrEditSalaryBonusCategory.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(createOrEditSalaryBonusCategory.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.modal = initialState.modal;
        state.isSubmit = false;
      })
      .addCase(createOrEditSalaryBonusCategory.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });

    builder
      .addCase(fetchEnumsAsync.pending, (state, { meta }) => {
        if (meta.arg.enumName === SALARY_BONUS_CATEGORY_ENUM) {
          state.isLoading = true;
        }
      });
  }
});

export const {
  setModalData,
  setPage,
  setSort,
  setQueryParamsFilters,
  resetModal,
  setTableHeadInput,
  setFormFields,
  setFormErrors,
  setValidateFormField,
  resetState,
} = salaryBonusCategorySlice.actions;

export default salaryBonusCategorySlice.reducer;
