import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../services/axios';

export const initialState = {
  error: undefined,
  totalWorkingHours: undefined
};

export const calculateWorkingHours = createAsyncThunk(
  'workingHours/calculateWorkingHours',
  async (arg = { filters: {} }, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get('/app/working_hours', { params: { filters: arg.filters } });

      return status === 200 ? data : rejectWithValue(data);
    } catch ({ response: { data } }) {
      return rejectWithValue(data);
    }
  }
);

export const workingHoursSlice = createSlice({
  name: 'workingHours',
  initialState,
  reducers: {
    clearHours: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(calculateWorkingHours.pending, (state) => {
        state.isPending = true;
      })
      .addCase(calculateWorkingHours.fulfilled, (state, action) => {
        state.isPending = false;
        state.totalWorkingHours = action.payload.number_working_hours;
      })
      .addCase(calculateWorkingHours.rejected, (state) => {
        state.isPending = false;
      });
  },
});

export const { clearHours } = workingHoursSlice.actions;

export default workingHoursSlice.reducer;
