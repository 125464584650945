import { authGet } from '../../utils/apiCall';
import { FETCH_API_INFO, SET_IS_MOBILE, SET_IS_UNREAD_NOTIFICATION } from './types';

export const setIsMobile = (isMobile) => ({ type: SET_IS_MOBILE, isMobile });

export const setIsUnreadNotification = (isUnreadNotification) => ({
  type: SET_IS_UNREAD_NOTIFICATION,
  isUnreadNotification
});

export const fetchApiInfo = () => {
  return (dispatch) => {
    authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/app/info`,
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      if (res && !res.message) {
        dispatch({ type: FETCH_API_INFO, apiInfo: res });
      }
    });
  };
};
