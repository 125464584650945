import { shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { useSelector } from '../../hooks/reduxToolkit';
import NotificationItem from './NotificationItem';
import NotificationList from './NotificationList';

const Notifications = ({ contentComponent = () => null }) => {
  const notifications = useSelector((state) => state.notifications.notifications, shallowEqual);

  return (
    notifications.length > 0 ? (
      <NotificationList>
        {notifications.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            contentComponent={contentComponent}
          />
        ))}
      </NotificationList>
    ) : null
  );
};

Notifications.propTypes = {
  contentComponent: PropTypes.func,
};

export default Notifications;
