import { batch } from 'react-redux';
import Link from 'next/link';
import PropTypes from 'prop-types';

import ArrowLink from '../../../components/Icons/ArrowLink';
import { useDispatch } from '../../../hooks/reduxToolkit';
import {
  markAsReadNotificationAsync,
  setModalData,
  setNotificationIds
} from '../../../toolkitStore/notificationsModal/slice';
import classNames from '../../../utils/classNames';
import { parseDatepicker } from '../../../utils/helpers';

import styles from '../sass/NotificationsListItem.module.scss';

const NotificationsListItem = ({ item, className = '' }) => {
  const dispatch = useDispatch();
  const listItemClasses = classNames(
    styles.listItem,
    className,
    {
      [styles.noRead]: !item.is_read,
    }
  );

  const handleMarkAsRead = (id) => {
    batch(() => {
      dispatch(setNotificationIds([id]));
      dispatch(markAsReadNotificationAsync());
    });
  };

  return (
    <li
      className={listItemClasses}
      key={item.id}
    >
      <button
        type="button"
        className={styles.description}
        onClick={() => handleMarkAsRead(item.id)}
      >
        {item?.data?.internalLink && (
          <Link
            href={item.data.internalLink}
            onClick={() => dispatch(setModalData({ isModalOpen: false }))}
          >
            <ArrowLink className={styles.link} />
          </Link>
        )}
        {item?.data?.externalLink && (
          <a
            href={item.data.externalLink}
            aria-label="External link"
            target="_blank"
            rel="noreferrer"
          >
            <ArrowLink className={styles.link} />
          </a>
        )}
        <div className={styles.title}>
          <span>
            {item.data.title}
          </span>
        </div>
        {!!item.data.message && (
          <div className={styles.message}>
            <span>
              {item.data.message}
            </span>
          </div>
        )}
        <div className={styles.date}>
          <span>{parseDatepicker(new Date(item.created_at), true, true)}</span>
        </div>
      </button>
    </li>
  );
};

NotificationsListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.shape({
      message: PropTypes.string,
      title: PropTypes.string,
      vacation_id: PropTypes.string,
      user_id: PropTypes.string,
      user_name: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      worklogs_count: PropTypes.number,
      success: PropTypes.bool,
      internalLink: PropTypes.string,
      externalLink: PropTypes.string,
    }).isRequired,
    is_read: PropTypes.bool.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      photo48_link: PropTypes.string,
    }).isRequired,
    link: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

export default NotificationsListItem;
