import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FINANCE_RATE, FINANCE_RATE_ID } from '../../constants/apiReqUrls';
import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { parseSalaryRateAndFopInfoData } from '../../utils/salary';
import { validateErrors, validateFinanceRateForm } from '../../utils/validators';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isSubmitting: false,
  isLoading: true,
  isDeleteSubmit: false,
  queryParams: {
    filters: {
      id: [],
      show_inactive: false,
      start_month: 0,
      end_month: 0
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  rateModal: {
    modalId: 'rateModal',
    isModalOpen: false,
    isMonthOpen: false,
  },
  formFields: {
    id: null,
    bankRate: '',
    cardRate: '',
    fopRate: '',
    month: undefined,
  },
  formErrors: {},
  rateWarningModal: {
    modalId: 'rateWarningModal',
    isModalOpen: false,
    rateId: null,
  },
};

export const getFinanceRate = createAsyncThunk(
  'financeRate/getFinanceRate',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { queryParams: params } = getState().financeRate;
      const { data, status } = await axios.get(FINANCE_RATE, { params });

      return status === httpStatusCodes.OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditFinanceRate = createAsyncThunk(
  'financeRate/addOrEditFinanceRate',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().financeRate;
      const errors = validateErrors(formFields, validateFinanceRateForm);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = FINANCE_RATE;
      let method = httpRequestMethods.POST;

      if (id) {
        url = FINANCE_RATE_ID.replace('{finance_rate_id}', id);
        method = httpRequestMethods.PUT;
      }

      const { data, status } = await axios({
        url,
        method,
        data: {
          month: formFields.month,
          bank_rate: formFields.bankRate,
          card_rate: formFields.cardRate,
          fop_rate: formFields.fopRate,
        },
      });

      if (status === httpStatusCodes.CREATED || status === httpStatusCodes.OK) {
        dispatch(getFinanceRate());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFinanceRateById = createAsyncThunk(
  'financeRate/deleteFinanceRateById',
  async ({ id }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(FINANCE_RATE_ID.replace('{finance_rate_id}', id));

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(getFinanceRate());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const financeRateSlice = createSlice({
  name: 'financeRate',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.queryParams.page = action.payload.page;
    },
    setSubmit: (state, { key, value }) => {
      state[key] = { ...state[key], ...value };
    },
    setQueryParamsFilters: (state, action) => {
      state.queryParams.filters = {
        ...state.queryParams.filters,
        ...action.payload
      };
      state.queryParams.page = null;
    },
    setModalData: (state, action) => {
      state[action.payload.key] = {
        ...state[action.payload.key],
        ...action.payload.data,
      };
    },
    setFormFields: (state, action) => {
      state.formFields = { ...state.formFields, ...action.payload };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setValidateData: (state, action) => {
      const { key, data } = action.payload;
      const error = validateFinanceRateForm(key, data);

      state.formFields = {
        ...state.formFields,
        [key]: data,
      };
      state.formErrors = {
        ...state.formErrors,
        [key]: error,
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFinanceRate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFinanceRate.fulfilled, (state, action) => {
        state.data = parseSalaryRateAndFopInfoData(action.payload.data);
        state.links = action.payload.links;
        state.meta = action.payload.meta;
        state.isLoading = false;
      })
      .addCase(getFinanceRate.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(addOrEditFinanceRate.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(addOrEditFinanceRate.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.rateModal = initialState.rateModal;
        state.isSubmitting = false;
      })
      .addCase(addOrEditFinanceRate.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmitting = false;
      });

    builder
      .addCase(deleteFinanceRateById.pending, (state) => {
        state.isDeleteSubmit = true;
      })
      .addCase(deleteFinanceRateById.fulfilled, (state) => {
        state.rateWarningModal = initialState.rateWarningModal;
        state.isDeleteSubmit = false;
      })
      .addCase(deleteFinanceRateById.rejected, (state) => {
        state.isDeleteSubmit = false;
      });
  }
});

export const {
  setPage,
  setQueryParamsFilters,
  setFormErrors,
  setModalData,
  setFormFields,
  setValidateData,
} = financeRateSlice.actions;

export default financeRateSlice.reducer;
