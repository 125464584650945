import { SORT_BY_CREATED_AT } from '../../constants/common';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import {
  SALARY_CORRECTION_FETCH_DATA, SALARY_CORRECTION_LIST_SET_SUBMIT,
  SALARY_CORRECTION_RESET_MODAL, SALARY_CORRECTION_RESET_STATE, SALARY_CORRECTION_SET_ABORT,
  SALARY_CORRECTION_SET_LOG_ERROR,
  SALARY_CORRECTION_SET_MODAL_DATA, SALARY_CORRECTION_SET_MODAL_FIELDS,
  SALARY_CORRECTION_SET_PAGE, SALARY_CORRECTION_SET_PAGE_FILTER_DATA, SALARY_CORRECTION_SET_QUERY_PARAMS,
  SALARY_CORRECTION_SET_SORT, SALARY_CORRECTION_SET_TABLE_COLUMNS, SALARY_CORRECTION_SET_TABLE_HEAD_DATA
} from './types';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isSubmit: false,
  isDeleteSubmit: false,
  modalErrors: {},
  tableColumns: {
    employee: true,
    month: true,
    amount: true,
    amountRate: true,
    amountUah: true,
    type: true,
    department: true,
    createdAt: true,
    createdBy: false,
    comment: false,
    action: true,
  },
  queryParams: {
    filters: {
      id: [],
      start_month: null,
      end_month: '',
      type_id: [],
      user_name: '',
      user_id: [],
      active_users: true,
      show_inactive: false,
      comment: ''
    },
    with: ['salary_correction_type', 'creator'],
    sort: `-${SORT_BY_CREATED_AT}`,
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  pageFilters: {
    employeeName: '',
    isEmployeeOpen: false,
    selectedEmployeeName: [],
    isColumnsOpen: false,
    isMonthOpen: false,
  },
  tableHeadData: {
    isTypeOpen: false,
    typeInputValue: '',
    departmentInputValue: '',
  },
  correctionModal: {
    modalId: 'correctionModal',
    isModalOpen: false,
    isEmployeeOpen: false,
    searchEmployeeValue: '',
    isMonthOpen: false,
    isTypeOpen: false,
    searchTypeValue: '',
    searchDepartmentValue: '',
    correctionId: null,
  },
  warningModal: {
    modalId: 'warningModal',
    isModalOpen: false,
    bonusId: ''
  },
  salaryCorrectionFields: {
    user: null,
    type: null,
    month: null,
    amount: '',
    comment: '',
    amount_uah: '',
    amount_rate: '',
    joint_purchase: false,
  },
  abortProps: {
    abortSalaryCorrection: {},
  },
};

export default function salaryCorrection(state = initialState, action) {
  switch (action.type) {
    case SALARY_CORRECTION_RESET_STATE:
      return initialState;
    case SALARY_CORRECTION_SET_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: {
          ...state.tableColumns,
          ...action.columns
        }
      };
    case SALARY_CORRECTION_SET_PAGE_FILTER_DATA:
      return {
        ...state,
        pageFilters: {
          ...state.pageFilters,
          ...action.data
        }
      };
    case SALARY_CORRECTION_SET_TABLE_HEAD_DATA:
      return {
        ...state,
        tableHeadData: {
          ...state.tableHeadData,
          ...action.data
        },
      };
    case SALARY_CORRECTION_SET_SORT:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          sort: action.sort,
        }
      };
    case SALARY_CORRECTION_LIST_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case SALARY_CORRECTION_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data,
        }
      };
    case SALARY_CORRECTION_SET_MODAL_FIELDS:
      return {
        ...state,
        salaryCorrectionFields: {
          ...state.salaryCorrectionFields,
          ...action.data
        },
      };
    case SALARY_CORRECTION_SET_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    case SALARY_CORRECTION_RESET_MODAL:
      return {
        ...state,
        salaryCorrectionFields: initialState.salaryCorrectionFields,
        modalErrors: initialState.modalErrors,
        correctionModal: initialState.correctionModal,
      };
    case SALARY_CORRECTION_FETCH_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case SALARY_CORRECTION_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page
        },
      };
    case SALARY_CORRECTION_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case SALARY_CORRECTION_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          }
        }
      };
    default:
      return state;
  }
}
