import PropTypes from 'prop-types';

import Portal from '../Portal/Portal';

import styles from './sass/NotificationList.module.scss';

const NotificationList = ({ children }) => (
  <Portal>
    <ul className={styles.list}>
      {children}
    </ul>
  </Portal>
);

NotificationList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationList;
