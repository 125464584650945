import {
  EMPLOYEE_FETCH_STATISTIC,
  EMPLOYEE_INFO_FETCH_EMPLOYEE, EMPLOYEE_INFO_FETCH_POSITIONS, EMPLOYEE_INFO_FETCH_ROLES, EMPLOYEE_INFO_SET_DATA,
  EMPLOYEE_INFO_SET_EDIT, EMPLOYEE_INFO_SET_EDIT_PROPS,
  EMPLOYEE_INFO_SET_FORM_ERRORS, EMPLOYEE_INFO_SET_MODAL_DATA,
  EMPLOYEE_INFO_SET_SUBMIT
} from './types';

export const initialState = {
  employee: {},
  isEditSubmit: false,
  isFollowSubmit: false,
  employeeEditProps: {
    jira_account_id: [],
    name_ukr: '',
    xero_contact_id: '',
    isPaymentMethodOpen: false,
    isRolesOpen: false,
    selectedRolesNames: [],
    isLeadOpen: false,
    isManagerOpen: false,
    isSupervisorOpen: false,
    isSalesManagerOpen: false,
    searchValue: '',
    lead: {},
    projectManager: {},
    supervisor: {},
    salesManager: {},
    peoplePartner: {},
    selectedPositionsIds: [],
  },
  statistic: {
    salary_changes: [],
    efficiency: {
      utilization: {},
      logs: [],
    },
  },
  isEdit: false,
  formErrors: {},
  roles: [],
  positions: [],
  permissionModal: {
    modalId: 'permissionModal',
    isModalOpen: false,
    isAccepted: false,
  },
};

export default function employeeInfo(state = initialState, action) {
  switch (action.type) {
    case EMPLOYEE_FETCH_STATISTIC:
      return {
        ...state,
        statistic: action.statistic,
      };
    case EMPLOYEE_INFO_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.isSubmit,
      };
    case EMPLOYEE_INFO_SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: action.errors,
      };
    case EMPLOYEE_INFO_FETCH_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case EMPLOYEE_INFO_FETCH_POSITIONS:
      return {
        ...state,
        positions: action.positions,
      };
    case EMPLOYEE_INFO_SET_DATA:
      return {
        ...state,
        employeeEditProps: {
          ...state.employeeEditProps,
          ...action.employeeData,
        },
      };
    case EMPLOYEE_INFO_FETCH_EMPLOYEE:
      return {
        ...state,
        employee: action.employee,
      };
    case EMPLOYEE_INFO_SET_EDIT:
      return {
        ...state,
        isEdit: action.isEdit,
      };
    case EMPLOYEE_INFO_SET_EDIT_PROPS:
      return {
        ...state,
        employeeEditProps: {
          ...state.employeeEditProps,
          ...action.editProps,
        },
      };
    case EMPLOYEE_INFO_SET_MODAL_DATA:
      return {
        ...state,
        permissionModal: {
          ...state.permissionModal,
          ...action.data,
        },
      };
    default:
      return state;
  }
}
