import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { OK } from '../../constants/httpStatusCodes';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { validateErrors, validateProjectBudget } from '../../utils/validators';

export const initialState = {
  statisticData: {},
  queryParams: {
    filters: {
      start_date: null,
      end_date: null,
      currency: null
    },
  },
  budgetFields: {
    budgetValue: '',
    currency: '',
    startDate: '',
    endDate: '',
    billedAmount: '',
    balanceAmount: 0,
    balancePercent: 0
  },
  formErrors: {},
  isSubmit: false,
  isClearSubmit: false,
  isLoading: false,
  clearDataModal: {
    modalId: 'clearDataModal',
    isModalOpen: false,
  },
  isRecalculate: false,
};

export const fetchProjectStatisticAsync = createAsyncThunk(
  'projectBudget/fetchProjectStatisticAsync',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`projects/${arg.projectId}/statistic`);

      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const calculateProjectBudget = createAsyncThunk(
  'projectBudget/calculateProjectBudget',
  async (arg, { getState, rejectWithValue }) => {
    const { budgetFields } = getState().projectBudget;
    const errors = validateErrors(budgetFields, validateProjectBudget);

    if (errors && !arg?.isClearData) {
      return rejectWithValue({ isFormValidation: true, errors });
    }

    const body = {
      cost_calculation: arg?.isClearData ? null : {
        budget: budgetFields.budgetValue,
        currency: budgetFields.currency,
        start_date: formatStringDate(budgetFields.startDate, 'yyyy-MM-dd'),
        end_date: formatStringDate(budgetFields.endDate, 'yyyy-MM-dd'),
      }
    };

    try {
      const { data, status } = await axios.post(
        `/projects/${arg.projectId}/cost_calculation`,
        { ...body }
      );

      return status === OK ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const projectBudgetSlice = createSlice({
  name: 'projectBudget',
  initialState,
  reducers: {
    resetState: () => initialState,
    projectBudgetSetFieldData: (state, action) => {
      const fieldError = validateProjectBudget(action.payload.key, action.payload.value);

      state.budgetFields = {
        ...state.budgetFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };
    },
    setQueryParams: (state, action) => {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setModalData: (state, action) => {
      state.clearDataModal = { ...state.clearDataModal, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectStatisticAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProjectStatisticAsync.fulfilled, (state, action) => {
        const { cost_calculation: costCalculation } = action.payload;

        state.isLoading = false;
        state.statisticData = action.payload;
        state.budgetFields = {
          budgetValue: costCalculation?.budget || '',
          currency: costCalculation?.currency || '',
          startDate: costCalculation?.start_date || '',
          endDate: costCalculation?.end_date || '',
          billedAmount: costCalculation?.sum_invoices_billed || 0,
          balanceAmount: costCalculation?.balance_amount || 0,
          balancePercent: costCalculation?.balance_percent || 0,
        };
      })
      .addCase(fetchProjectStatisticAsync.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(calculateProjectBudget.pending, (state, action) => {
        if (action.meta.arg?.isClearData) {
          state.isClearSubmit = true;
        } else {
          state.isSubmit = true;
        }
        state.isRecalculate = false;
      })
      .addCase(calculateProjectBudget.fulfilled, (state, action) => {
        const { cost_calculation: costCalculation } = action.payload;

        state.statisticData = action.payload;
        state.budgetFields = {
          budgetValue: costCalculation?.budget || '',
          currency: costCalculation?.currency || '',
          startDate: costCalculation?.start_date || '',
          endDate: costCalculation?.end_date || '',
          billedAmount: costCalculation?.sum_invoices_billed || 0,
          balanceAmount: costCalculation?.balance_amount || 0,
          balancePercent: costCalculation?.balance_percent || 0,
        };

        if (action.meta.arg?.isClearData) {
          state.isClearSubmit = false;
        } else {
          state.isSubmit = false;
        }
        state.isRecalculate = true;
        state.formErrors = {};
      })
      .addCase(calculateProjectBudget.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.formErrors = action.payload.errors;
        }

        if (action.meta.arg?.isClearData) {
          state.isClearSubmit = false;
        } else {
          state.isSubmit = false;
        }
        state.isRecalculate = true;
      });
  }
});

export const {
  resetState,
  projectBudgetSetFieldData,
  setQueryParams,
  setModalData
} = projectBudgetSlice.actions;

export default projectBudgetSlice.reducer;
