import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { STATUS_ACTIVE } from '../../constants/status';
import { LIST_SIZE_PER_PAGE_25 } from '../../constants/values';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { removeEmptyValues } from '../../utils/objectHelpers';
import { replaceRouterQuery } from '../../utils/routerQuery';

const today = new Date();
const formatToday = formatStringDate(today, 'dd.MM.yyyy');
export const initialState = {
  isLoading: true,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      search: '',
      type: '',
      contract_type: '',
      manager_name: '',
      client_name: '',
      client_product_name: '',
      client_sales_manager_name: '',
      show_own: true,
      department_id: [],
      status: [STATUS_ACTIVE],
      first_worklog_date: {
        gte: null,
        lte: null,
      },
    },
    with: ['manager', 'client', 'client.sales_manager', 'department', 'client_product'],
    sort: 'name',
    page: null,
    per_page: LIST_SIZE_PER_PAGE_25,
  },
  isTypeOpen: false,
  isDepartmentOpen: false,
  departmentSearchValue: '',
  tableColumns: {
    project: true,
    client: true,
    product: true,
    contractType: true,
    manager: true,
    salesManager: true,
    type: true,
    department: true,
    lastWorklog: true,
    firstWorklog: false,
    approvedHours: false,
    billedHours: false,
  },
  datepicker: {
    from: today,
    to: today,
    enteredTo: today,
    inputFrom: formatToday,
    inputEnteredTo: formatToday,
    isSelectRange: false,
    isOpen: false,
    style: {},
  },
};

export const fetchProjectsAsync = createAsyncThunk(
  'projects/fetchProjects',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { projects: { query } } = getState();
      const { data, status } = await axios.get('/projects', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetQueryFilter: (state) => {
      state.query.filters = initialState.query.filters;
      replaceRouterQuery(removeEmptyValues(state.query.filters));
    },
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
      state.query.page = null;
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setTableColumns: (state, action) => {
      state.tableColumns = { ...state.tableColumns, ...action.payload };
    },
    setDatePickerData: (state, action) => {
      state.datepicker = action.payload;
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsAsync.pending, (state) => {
        state.isLoading = true;
        state.projects = undefined;
        state.error = undefined;
      })
      .addCase(fetchProjectsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchProjectsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setData,
  resetQueryFilter,
  resetState,
  setTableColumns,
  setDatePickerData
} = projectsSlice.actions;

export default projectsSlice.reducer;
