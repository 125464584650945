import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_BY_NAME } from '../../constants/common';
import httpRequestMethods from '../../constants/httpRequestMethods';
import * as httpStatusCodes from '../../constants/httpStatusCodes';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { validateErrors, validateSystemsPosition, validateSystemsPositionsInfo } from '../../utils/validators';

export const initialState = {
  data: [],
  links: {},
  meta: {},
  isSubmit: false,
  isDeleteSubmit: false,
  reportsToData: [],
  isReportsToLoading: false,
  queryParams: {
    filters: {
      reports_to: {
        id: []
      },
      department: {
        name: ''
      },
      name: '',
    },
    with: ['users', 'department', 'reports_to'],
    sort: SORT_BY_NAME,
    page: null,
    per_page: LIST_SIZE_PER_PAGE
  },
  reportsToQueryParams: {
    filters: {
      department: {
        name: ''
      },
      name: '',
    },
    with: ['department'],
    sort: SORT_BY_NAME
  },
  tableHeadInput: {
    nameValue: '',
    departmentValue: ''
  },
  systemsPositionsModal: {
    modalId: 'systemsPositionsModal',
    isModalOpen: false,
    name: ''
  },
  systemsPositionsAssignModal: {
    modalId: 'systemsPositionsAssignModal',
    isModalOpen: false,
  },
  formFields: {
    id: '',
    position: '',
    abbreviation: '',
    department: {},
    reportsTo: {},
    fullTime: true,
    urlToDescription: '',
    employee: undefined,
    startDate: undefined,
    endDate: undefined,
  },
  positionDeleteDialog: {
    modalId: 'positionDeleteDialog',
    isModalOpen: false,
    id: '',
    position: ''
  },
  formErrors: {},
};

export const fetchSystemsPositions = createAsyncThunk(
  'systemsPositions/fetchSystemsPositions',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { queryParams, reportsToQueryParams } = getState().systemsPositions;

      let params = queryParams;

      if (arg?.isReportsTo) {
        params = reportsToQueryParams;
      }

      const { data, status } = await axios.get('/positions', { params });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditPosition = createAsyncThunk(
  'systemsPositions/addOrEditPosition',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().systemsPositions;
      const errors = validateErrors(formFields, validateSystemsPosition);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let url = '/positions';
      let method = httpRequestMethods.POST;

      if (id) {
        url += `/${id}`;
        method = httpRequestMethods.PUT;
      }

      const body = {
        name: formFields.position,
        abbreviation: formFields.abbreviation,
        department_id: formFields.department?.id || '',
        reports_to_id: formFields.reportsTo?.id || '',
        full_time: formFields.fullTime,
        url_to_description: formFields.urlToDescription || ''
      };

      const { data, status } = await axios({ url, method, data: body });

      if (status === httpStatusCodes.CREATED || status === httpStatusCodes.OK) {
        dispatch(fetchSystemsPositions());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assignPositions = createAsyncThunk(
  'systemsPositions/assignPositions',
  async (arg, { dispatch, getState, rejectWithValue }) => {
    try {
      const { formFields } = getState().systemsPositions;
      const errors = validateErrors(formFields, validateSystemsPositionsInfo);

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const body = {
        user_id: formFields.employee.id,
        position_id: formFields.id,
        started_at: formatStringDate(formFields.startDate, 'y-MM-dd'),
        completed_at: formFields.endDate ? formatStringDate(formFields.endDate, 'y-MM-dd') : null,
      };

      const { data, status } = await axios({
        url: '/users_positions',
        method: httpRequestMethods.POST,
        data: body
      });

      if (status === httpStatusCodes.CREATED || status === httpStatusCodes.OK) {
        dispatch(fetchSystemsPositions());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deletePosition = createAsyncThunk(
  'systemsPositions/deletePosition',
  async (arg, {
    dispatch, getState, fulfillWithValue, rejectWithValue
  }) => {
    try {
      const { positionDeleteDialog: { id } } = getState().systemsPositions;
      const { data, status } = await axios.delete(`/positions/${id}`);

      if (status === httpStatusCodes.NO_CONTENT) {
        dispatch(fetchSystemsPositions());
        return fulfillWithValue();
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const systemsPositionsSlice = createSlice({
  name: 'systemsPositions',
  initialState,
  reducers: {
    setTableHeadInput: (state, action) => {
      state.tableHeadInput = { ...state.tableHeadInput, ...action.payload };
    },
    setQuery: (state, action) => {
      state.queryParams = { ...state.queryParams, ...action.payload };
    },
    setQueryFilter: (state, action) => {
      state.queryParams.filters = { ...state.queryParams.filters, ...action.payload };
      state.queryParams.page = null;
    },
    setReportsToQueryFilter: (state, action) => {
      state.reportsToQueryParams.filters = { ...state.reportsToQueryParams.filters, ...action.payload };
    },
    setValidateFormField: (state, action) => {
      const fieldError = validateSystemsPositionsInfo(action.payload.fieldName, action.payload.fieldData);

      state.formErrors = {
        ...state.formErrors,
        [action.payload.fieldName]: fieldError,
      };

      state.formFields = {
        ...state.formFields,
        [action.payload.fieldName]: action.payload.fieldData,
      };
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setFormErrors: (state, action) => {
      state.formErrors = action.payload;
    },
    setFormFields: (state, action) => {
      state.formFields = { ...state.formFields, ...action.payload };
    },
    setPage: (state, action) => {
      state.queryParams.page = action.payload;
    },
    setSort: (state, action) => {
      state.queryParams.sort = action.payload;
    },
    resetModal: (state) => {
      state.formErrors = initialState.formErrors;
      state.systemsPositionsModal = initialState.systemsPositionsModal;
      state.positionDeleteDialog = initialState.positionDeleteDialog;
      state.systemsPositionsAssignModal = initialState.systemsPositionsAssignModal;
      state.formFields = initialState.formFields;
    },
    setNumberTakenUsers: (state, action) => {
      state.numberTakenUsers = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    const getTargetLoader = (isReportsTo = false) => {
      return isReportsTo ? 'isReportsToLoading' : 'isLoading';
    };

    builder
      .addCase(fetchSystemsPositions.pending, (state, action) => {
        state[getTargetLoader(action.meta.arg?.isReportsTo)] = true;
      })
      .addCase(fetchSystemsPositions.fulfilled, (state, action) => {
        const targetState = action.meta.arg?.isReportsTo ? 'reportsToData' : 'data';

        state[targetState] = action.payload.data;
        state[getTargetLoader(action.meta.arg?.isReportsTo)] = false;
        state.meta = action.payload.meta;
      })
      .addCase(fetchSystemsPositions.rejected, (state, action) => {
        state[getTargetLoader(action.meta.arg?.isReportsTo)] = false;
      });

    builder
      .addCase(addOrEditPosition.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(addOrEditPosition.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.systemsPositionsModal = initialState.systemsPositionsModal;
        state.isSubmit = false;
      })
      .addCase(addOrEditPosition.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(assignPositions.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(assignPositions.fulfilled, (state) => {
        state.formFields = initialState.formFields;
        state.formErrors = initialState.formErrors;
        state.systemsPositionsAssignModal = initialState.systemsPositionsAssignModal;
        state.isSubmit = false;
      })
      .addCase(assignPositions.rejected, (state, action) => {
        if (action.payload.isFormValidation) {
          state.formErrors = action.payload.errors;
        }
        state.isSubmit = false;
      });
    builder
      .addCase(deletePosition.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
      })
      .addCase(deletePosition.fulfilled, (state) => {
        state.isSubmit = false;
        state.positionDeleteDialog = initialState.positionDeleteDialog;
      })
      .addCase(deletePosition.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
  }
});

export const {
  setModalData,
  setPage,
  setSort,
  setQueryFilter,
  resetModal,
  setTableHeadInput,
  setFormFields,
  setFormErrors,
  setValidateFormField,
  setReportsToQueryFilter,
  resetState,
} = systemsPositionsSlice.actions;

export default systemsPositionsSlice.reducer;
