import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import {
  EMPLOYEE_SKILLS_SET_TABLE_HEAD_INPUT,
  SET_IS_SUBMIT_IDS,
  SKILLS_FETCH_TECHNOLOGIES,
  SKILLS_FETCH_USER_TECHNOLOGIES,
  SKILLS_LIST_SET_SUBMIT,
  SKILLS_RESET_MODAL,
  SKILLS_SET_ABORT,
  SKILLS_SET_DISABLED,
  SKILLS_SET_LOADING,
  SKILLS_SET_LOG_ERROR,
  SKILLS_SET_MODAL_DATA,
  SKILLS_SET_MODAL_FIELDS,
  SKILLS_SET_PAGE,
  SKILLS_SET_QUERY_PARAMS,
  SKILLS_SET_TOGGLE_TABLE_MORE 
} from './types';

export const initialState = {
  isDataLoading: true,
  data: [],
  isApprover: false,
  links: {},
  meta: {},
  skills: [],
  isSubmit: false,
  isDeleteSubmit: false,
  tableHeadInput: {
    nameInputValue: '',
  },
  queryParams: {
    filters: {
      user: { id: [] },
      name: '',
      status: ''
    },
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
    with: ['technology', 'approver', 'projects'],
  },
  moreProps: {
    isOpen: false,
    id: '',
    style: {}
  },
  skillsModal: {
    modalId: 'skillsModal',
    isModalOpen: false,
  },
  skillsModalApprove: {
    modalId: 'skillsModalApprove',
    isModalOpen: false,
  },
  skillsModalReject: {
    modalId: 'skillsModalReject',
    isModalOpen: false,
  },
  skillsModalWarning: {
    modalId: 'skillsModalWarning',
    isModalOpen: false,
  },
  skillsFields: {
    skillId: null,
    isNameOpen: false,
    technologyId: null,
    name: '',
    isProjectOpen: false,
    projects: [],
    projectName: '',
    projectSearchValue: '',
    isUsageLevelOpen: false,
    usageLevelName: '',
    lastUsage: '',
    experience: '',
    comment: '',
    skillTree: ''
  },
  abortProps: {
    abortSkills: {},
  },
  modalErrors: {},
  isSubmitIds: [],
  isDisabled: true
};

export default function employeeSkills(state = initialState, action) {
  switch (action.type) {
    case SKILLS_SET_LOADING:
      return {
        ...state,
        meta: {},
        queryParams: {
          ...state.queryParams,
          page: null,
        },
        isDataLoading: action.isLoading,
      };
    case SKILLS_SET_ABORT:
      return {
        ...state,
        abortProps: {
          ...state.abortProps,
          ...action.abortProps
        }
      };
    case SKILLS_FETCH_USER_TECHNOLOGIES:
      return {
        ...state,
        isDataLoading: false,
        ...action.payload,
      };
    case SKILLS_FETCH_TECHNOLOGIES:
      return {
        ...state,
        skills: action.skills,
      };
    case SKILLS_SET_PAGE:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          page: action.page,
        },
      };
    case SKILLS_SET_QUERY_PARAMS:
      return {
        ...state,
        queryParams: {
          ...state.queryParams,
          filters: {
            ...state.queryParams.filters,
            ...action.filters
          }
        }
      };
    case SKILLS_SET_TOGGLE_TABLE_MORE:
      return {
        ...state,
        moreProps: action.moreProps
      };
    case SKILLS_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case SKILLS_RESET_MODAL:
      return {
        ...state,
        skillsFields: initialState.skillsFields,
        modalErrors: initialState.modalErrors,
        skillsModal: initialState.skillsModal,
      };
    case SKILLS_SET_MODAL_FIELDS:
      return {
        ...state,
        skillsFields: {
          ...state.skillsFields,
          ...action.data
        }
      };
    case SKILLS_LIST_SET_SUBMIT:
      return {
        ...state,
        [action.key]: action.value,
      };
    case EMPLOYEE_SKILLS_SET_TABLE_HEAD_INPUT:
      return {
        ...state,
        tableHeadInput: {
          ...state.tableHeadInput,
          ...action.tableHeadInput,
        }
      };
    case SKILLS_SET_LOG_ERROR:
      return {
        ...state,
        modalErrors: action.modalErrors,
      };
    case SET_IS_SUBMIT_IDS:
      return {
        ...state,
        isSubmitIds: [...action.isSubmitIds],
      };
    case SKILLS_SET_DISABLED:
      return {
        ...state,
        isDisabled: action.value,
      };
    default:
      return state;
  }
}
