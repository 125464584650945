import Loader from '../Loaders/Loader';
import Logo from '../Logo/Logo';

import styles from './sass/AuthLoader.module.scss';

const AuthLoader = () => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <Logo />
      <div className={styles.features}>
        <div className={styles.small} />
        <div className={styles.small} />
        <div className={styles.user}>
          <div className={styles.long} />
          <div className={styles.avatar} />
        </div>
      </div>
    </div>
    <div className={styles.loaderWrap}>
      <Loader />
    </div>
  </div>
);

export default AuthLoader;
