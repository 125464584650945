import { ALERT_AUTO_CLOSE_TIME } from '../../constants/values';
import {
  ADD_ALERT, ADD_ALERTS, REMOVE_ALERT, REMOVE_ALERTS
} from './types';

const defaultOptions = {
  autoClose: true,
  autoCloseTime: ALERT_AUTO_CLOSE_TIME
};

export const removeAlert = (id) => ({ type: REMOVE_ALERT, id });

export const removeAlerts = (alerts) => ({ type: REMOVE_ALERTS, alerts });

export const addAlert = (alert, options = {}) => {
  return (dispatch) => {
    const alertOptions = { ...defaultOptions, ...options };
    dispatch({ type: ADD_ALERT, alert });

    if (alertOptions.autoClose) {
      setTimeout(() => {
        dispatch(removeAlert(alert.id));
      }, alertOptions.autoCloseTime);
    }
  };
};

export const addAlerts = (alerts, options = {}) => {
  return (dispatch) => {
    const alertsOptions = { ...defaultOptions, ...options };
    dispatch({ type: ADD_ALERTS, alerts });

    if (alertsOptions.autoClose) {
      setTimeout(() => {
        dispatch(removeAlerts(alerts));
      }, alertsOptions.autoCloseTime);
    }
  };
};
