import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import {
  Content,
  Portal, Root, Trigger
} from '@radix-ui/react-hover-card';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { LOADER_COLOR_BLUE } from '../../constants/common';
import { USER_STATUSES } from '../../constants/status';
import { useDispatch, useSelector } from '../../hooks/reduxToolkit';
import { fetchVacationUpcomingRequests } from '../../toolkitStore/userHoverCard/slice';
import Avatar from '../Avatar/Avatar';
import Badge from '../Badge/Badge';
import Vacation from '../Badge/Vacation';
import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';
import Mail from '../Icons/Mail';
import Phone from '../Icons/Phone';
import BallPulse from '../Loaders/BallPulse';
import UserHoverCardItems from './UserHoverCardItems';

import styles from './sass/UserHoverCard.module.scss';

const UserHoverCard = ({ children, user }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const container = document.getElementById('hover-card-container');

  const [avatar, setAvatar] = useState(user.photo48_link);
  const {
    requests, isLoading
  } = useSelector(({ userHoverCard }) => ({
    requests: userHoverCard.requests,
    isLoading: userHoverCard.isLoading,
  }), shallowEqual);

  const handleEnter = () => {
    if (requests.find((item) => item.id === user.id)) return;

    dispatch(fetchVacationUpcomingRequests({ id: user.id }));
  };

  const content = () => {
    if (requests.length > 0) {
      return requests.map((item) => (
        <UserHoverCardItems key={`${item.id}`} data={item.id === user.id ? item : undefined} />
      ));
    }

    return (<div className={styles.emptyVacation}>{t('doesntHaveVacation')}</div>);
  };

  useEffect(() => {
    setAvatar(user.photo48_link);
  }, [user]);

  return (
    <Root
      onOpenChange={(e) => user.on_vacation && handleEnter(e)}
      openDelay={400}
    >
      <Trigger className={styles.bodyTrigger}>
        {children}
      </Trigger>
      <Portal container={container}>
        <Content
          className={styles.wrapper}
          sideOffset={5}
          align="start"
        >
          <div className={styles.contentTitle}>
            <Badge
              statuses={user.on_vacation ? (
                <Vacation vacation="vacation" sizeStatus="medium" />
              ) : undefined}
            >
              <Avatar
                width={48}
                height={48}
                image={avatar}
                alt={user?.name}
                onError={() => setAvatar('/images/avatar.svg')}
              />
            </Badge>
            <div className={styles.userInfo}>
              {user.name ? (
                <ClipboardCopy
                  className={styles.name}
                  copyText={user.name}
                >
                  <div>{user?.name}</div>
                </ClipboardCopy>
              ) : null}
              {user.email ? (
                <ClipboardCopy
                  className={styles.email}
                  copyText={user.email}
                >
                  <span className={styles.icon}><Mail /></span>
                  <div>{user.email}</div>
                </ClipboardCopy>
              ) : null}
              {user.phone ? (
                <ClipboardCopy
                  className={styles.email}
                  copyText={user.phone}
                >
                  <span><Phone /></span>
                  <div>{user.phone}</div>
                </ClipboardCopy>
              ) : null}
            </div>
          </div>
          {isLoading ? (
            <div className={styles.loader}><BallPulse color={LOADER_COLOR_BLUE} /></div>
          ) : content()}
        </Content>
      </Portal>
    </Root>
  );
};

UserHoverCard.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    photo48_link: PropTypes.string,
    status: PropTypes.oneOf(USER_STATUSES),
    on_vacation: PropTypes.bool,
  }).isRequired,
};

export default UserHoverCard;
