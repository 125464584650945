import { STATUS_INACTIVE } from '../../constants/status';
import {
  CLIENT_INFO_FETCH_CLIENT, CLIENT_INFO_FETCH_CLIENT_STATISTIC, CLIENT_INFO_FETCH_PAYMENT_METHODS,
  CLIENT_INFO_RESET_CLIENT, CLIENT_INFO_SEARCH_MANAGER,
  CLIENT_INFO_SET_DATA, CLIENT_INFO_SET_EDIT, CLIENT_INFO_SET_ERROR, CLIENT_INFO_SET_ERRORS,
  CLIENT_INFO_SET_MODAL_DATA,
  CLIENT_INFO_SET_SUBMIT
} from './types';

export const initialState = {
  client: {},
  clientStatistic: {
    billing: [],
  },
  isEdit: false,
  isSubmit: false,
  paymentMethods: [],
  clientEditProps: {
    group: '',
    comment: '',
    salesManager: '',
    status: STATUS_INACTIVE,
    internally_approved_default: false,
  },
  selectProps: {
    isPeriodOpen: false,
    isPaymentOpen: false,
  },
  selectSearchProps: {
    paymentSearchValue: '',
  },
  errorMessage: '',
  errorStatus: null,
  errors: {},
};

export default function clientInfo(state = initialState, action) {
  switch (action.type) {
    case CLIENT_INFO_SET_MODAL_DATA:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.data
        }
      };
    case CLIENT_INFO_RESET_CLIENT:
      return initialState;
    case CLIENT_INFO_SET_ERROR:
      return {
        ...state,
        errorMessage: action.message,
        errorStatus: action.status
      };
    case CLIENT_INFO_SET_ERRORS:
      return { ...state, errors: action.errors };
    case CLIENT_INFO_FETCH_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods
      };
    case CLIENT_INFO_SET_SUBMIT:
      return {
        ...state,
        isSubmit: action.isSubmit
      };
    case CLIENT_INFO_SET_DATA:
      return {
        ...state,
        clientEditProps: {
          ...state.clientEditProps,
          ...action.clientData
        },
        errors: {},
      };
    case CLIENT_INFO_SEARCH_MANAGER:
      return {
        ...state,
        selectSearchProps: {
          ...state.selectSearchProps,
          managerSearchValue: action.value
        }
      };
    case CLIENT_INFO_SET_EDIT:
      return {
        ...state,
        isEdit: action.isEdit,
        clientEditProps: {
          group: state.client?.group,
          comment: state.client?.comment || '',
          salesManager: state.client?.sales_manager,
          status: state.client?.status,
          preferredBillingPeriod: state.client?.preferred_billing_period,
          internally_approved_default: state.client?.internally_approved_default,
          multi_company_agreements: state.client?.multi_company_agreements,
          country_code: state.client?.country_code,
          country_code_subdivision: state.client?.country_code_subdivision,
          pipedriveLink: state.client?.pipedrive_link || '-',
          invoicePerson: state.client?.invoice_person_first_name
            ? `${state.client.invoice_person_first_name} ${state.client.invoice_person_last_name}`
            : '-',
        },
        errors: {},
      };
    case CLIENT_INFO_FETCH_CLIENT:
      return {
        ...state,
        client: action.client
      };
    case CLIENT_INFO_FETCH_CLIENT_STATISTIC:
      return {
        ...state,
        clientStatistic: action.clientStatistic
      };
    default:
      return state;
  }
}
