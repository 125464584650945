import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import * as httpStatusCodes from '../../constants/httpStatusCodes';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { newValidateErrors, validateAssetsModal, validateErrors } from '../../utils/validators';

export const initialState = {
  asset: {},
  isLoading: false,
  isSubmit: false,
  assetInfoModal: {
    modalId: 'assetInfoModal',
    isModalOpen: false,
    isCategoryOpen: false,
    isEmployeeOpen: false,
    isPartNumberOpen: false,
    isNameOpen: false,
    searchEmployeeValue: '',
    shared_device: false,
    isCurrencyOpen: false,
    currencies: [],
    isDepartmentOpen: false,
    departmentSearchValue: '',
    asset_id: null,
    category: null,
    name: '',
    inventory_number: '',
    serial_number: '',
    part_number: '',
    ean_number: null,
    description: '',
    employee: null,
    user_id: '',
    amount: '',
    currency: '',
    currency_rate: '',
    department: null,
    activity: false,
    comment: '',
    isCommentRequired: false
  },
  assetInfoWarningModal: {
    modalId: 'assetInfoWarningModal',
    asset_id: null,
    isModalOpen: false,
    name: ''
  },
  modalErrors: {},
};

export const fetchAsset = createAsyncThunk(
  'assetInfo/fetchAsset',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.get(`assets/${Router.query.id}`);

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editAsset = createAsyncThunk(
  'assetInfo/editAsset',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { assetInfoModal } = getState().assetInfo;

      const errors = validateErrors(
        assetInfoModal,
        validateAssetsModal,
        {
          isCommentRequired: assetInfoModal.isCommentRequired,
          isDepartmentExist: assetInfoModal.shared_device,
        }
      );

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      const body = assetInfoModal.shared_device
        ? { department_id: assetInfoModal.department.id }
        : { department_id: '', employee_id: assetInfoModal?.employee?.id || '' };

      const { data, status } = await axios.patch(
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/assets/${arg}`,
        {
          category_id: assetInfoModal.category.id,
          name: assetInfoModal.name,
          inventory_number: assetInfoModal.inventory_number,
          serial_number: assetInfoModal.serial_number,
          part_number: assetInfoModal.part_number,
          ean_number: assetInfoModal.ean_number,
          description: assetInfoModal.description,
          employee_id: assetInfoModal.employee?.id || null,
          shared_device: assetInfoModal.shared_device,
          amount: assetInfoModal.amount,
          currency: assetInfoModal.currency,
          currency_rate: assetInfoModal.currency_rate,
          activity: assetInfoModal.activity,
          comment: assetInfoModal.comment,
          ...body
        },
      );

      return (status === 200 || status === 201) ? data : rejectWithValue(data.message);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCurrencyRate = createAsyncThunk(
  'assetInfo/getCurrencyRate',
  async (arg, { rejectWithValue }) => {
    const params = {
      filters: { date: [formatStringDate(new Date(), 'yyyy-MM-dd')], currency: [arg] }
    };

    try {
      const { data, status } = await axios.get('/currency_rates', { params });
      const [fetchCurrency] = data.data;

      return status === 200 ? fetchCurrency?.rate : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAsset = createAsyncThunk(
  'assetInfo/deleteAsset',
  async (arg, { rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(
        `/assets/${arg}`,
      );

      return status === httpStatusCodes.NO_CONTENT ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assetInfoSlice = createSlice({
  name: 'assetInfo',
  initialState,
  reducers: {
    resetState: () => initialState,
    resetModalData: (state) => {
      state.assetInfoModal = initialState.assetInfoModal;
      state.assetInfoWarningModal = initialState.assetInfoWarningModal;
      state.modalErrors = initialState.modalErrors;
    },
    setModalData: (state, action) => {
      state.assetInfoModal = { ...state.assetInfoModal, ...action.payload };

      const { assetInfoModal } = state;
      state.modalErrors = {
        ...state.modalErrors,
        ...newValidateErrors({ ...action.payload }, validateAssetsModal, {
          isValidateEmployee: !!assetInfoModal.asset_id,
          isCurrencyExist: assetInfoModal.currency !== 'USD' && assetInfoModal?.currency?.length > 0,
          isAmountExist: !!assetInfoModal.amount,
          isDepartmentExist: assetInfoModal.shared_device,
          isCommentRequired: assetInfoModal.isCommentRequired && !assetInfoModal.activity
        })
      };
    },
    setWarningModalData: (state, action) => {
      state.assetInfoWarningModal = { ...state.assetInfoWarningModal, ...action.payload };
      state.modalErrors = {
        ...state.modalErrors,
        ...newValidateErrors({ ...action.payload }, validateAssetsModal)
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsset.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAsset.fulfilled, (state, action) => {
        state.isLoading = false;
        state.asset = action.payload;
      })
      .addCase(fetchAsset.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(deleteAsset.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(deleteAsset.fulfilled, (state) => {
        state.isSubmit = false;
      })
      .addCase(deleteAsset.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
    builder
      .addCase(editAsset.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(editAsset.fulfilled, (state, action) => {
        state.isSubmit = false;
        state.assetInfoModal = initialState.assetInfoModal;
        state.asset = action.payload;
      })
      .addCase(editAsset.rejected, (state, action) => {
        state.isSubmit = false;

        if (action.payload.isFormValidation) {
          state.modalErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
      });
    builder
      .addCase(getCurrencyRate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrencyRate.fulfilled, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          state.assetInfoModal.currency_rate = action.payload.toFixed(2).toString();
        }
      })
      .addCase(getCurrencyRate.rejected, (state, action) => {
        state.isLoading = false;
        state.modalErrors = action.payload;
      });
  }
});

export const {
  resetState,
  resetModalData,
  setModalData,
  setWarningModalData
} = assetInfoSlice.actions;

export default assetInfoSlice.reducer;
