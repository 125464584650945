import { useMemo } from 'react';
import { batch, shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';

import Button from '../../../components/Button/Button';
import { NOTIFICATIONS_CHIPS, NOTIFICATIONS_TABS } from '../../../constants/notifications';
import { useDispatch, useSelector } from '../../../hooks/reduxToolkit';
import useDraggableScroll from '../../../hooks/useDraggableScroll';
import {
  fetchNotificationsAsync, setChips, setQueryFilters,
} from '../../../toolkitStore/notificationsModal/slice';

import styles from '../sass/NotificationsChips.module.scss';

const NotificationsChips = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const {
    containerRef, canScrollLeft, canScrollRight, dragging
  } = useDraggableScroll();

  const {
    meta, activeChip, activeTab, isLoading,
  } = useSelector(({ notificationsModal }) => ({
    meta: notificationsModal.meta,
    activeChip: notificationsModal.activeChip,
    activeTab: notificationsModal.activeTab,
    isLoading: notificationsModal.isLoading
  }), shallowEqual);

  const handleClickChip = (chip) => {
    if (dragging) return;

    let group = chip;

    if (chip === NOTIFICATIONS_CHIPS.OTHER) {
      group = 'unspecified';
    }

    if (chip === NOTIFICATIONS_CHIPS.ALL) {
      group = '';
    }

    batch(() => {
      dispatch(setChips(chip));
      dispatch(setQueryFilters({ group }));
      dispatch(fetchNotificationsAsync());
    });
  };

  const isShowChip = useMemo(() => (chip) => {
    let res = false;

    const isNew = activeTab === NOTIFICATIONS_TABS.NEW;
    const isRead = activeTab === NOTIFICATIONS_TABS.READ;

    if (isNew) {
      if (chip === NOTIFICATIONS_CHIPS.ALL) {
        if (meta?.vacation?.unread > 0
          || meta?.finance?.unread > 0
          || meta?.subscription?.unread > 0
          || meta?.management?.unread > 0
          || meta?.unspecified?.unread > 0
        ) {
          res = true;
        }
      }

      if ((meta?.[chip]?.unread > 0) || (chip === NOTIFICATIONS_CHIPS.OTHER && meta?.unspecified?.unread > 0)) {
        res = true;
      }
    } else if (isRead) {
      if (chip === NOTIFICATIONS_CHIPS.ALL) {
        if (meta?.vacation?.read > 0
            || meta?.finance?.read > 0
            || meta?.subscription?.read > 0
            || meta?.management?.read > 0
            || meta?.unspecified?.read > 0
        ) {
          res = true;
        }
      }

      if ((meta?.[chip]?.read > 0) || (chip === NOTIFICATIONS_CHIPS.OTHER && meta?.unspecified?.read > 0)) {
        res = true;
      }
    }

    return res;
  }, [activeTab, meta]);

  const isShowCount = (chip) => {
    return (meta?.[chip]?.unread > 0 || meta?.unspecified?.unread)
      && activeTab === NOTIFICATIONS_TABS.NEW
      && chip !== NOTIFICATIONS_CHIPS.ALL;
  };

  return (
    <>
      {canScrollLeft && <div className={styles.canScrollLeft} />}
      {canScrollRight && <div className={styles.canScrollRight} />}
      <div role="tablist" className={styles.chips} ref={containerRef}>
        {Object.values(NOTIFICATIONS_CHIPS)
          .map((chip) => {
            return isShowChip(chip) ? (
              <div
                key={chip}
                role="tab"
                className={`${styles.chip} ${activeChip === chip ? styles.active : ''}`}
              >
                <Button
                  variant="ghost"
                  ariaLabel={`${chip} chip`}
                  isDisabled={isLoading}
                  onClick={() => handleClickChip(chip)}
                >
                  {t(chip)}
                  {isShowCount(chip)
                    ? <div className={styles.count}>{meta[chip]?.unread || meta?.unspecified?.unread}</div>
                    : null}
                </Button>
              </div>
            ) : null;
          })}
      </div>
    </>
  );
};

export default NotificationsChips;
