import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { MONTHS_SHORT } from '../../constants/common';
import axios from '../../services/axios';
import { parseMonthIntToObj, parseNumber } from '../../utils/helpers';

export const initialState = {
  data: [],
  isLoading: false,
  error: undefined,
  query: {
    filters: {
      user_id: '',
    },
    page: null,
  },
  pageFilters: {
    employeeName: '',
    isEmployeeOpen: false,
    employee: {
      id: ''
    },
  },
};

export const fetchLoanBalanceAsync = createAsyncThunk(
  'salaryLoanBalance/fetchLoanBalance',
  async (arg, { rejectWithValue, getState }) => {
    try {
      const { salaryLoanBalance: { query } } = getState();
      const { data, status } = await axios.get('salary_corrections/loan_balance', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loanBalanceSlice = createSlice({
  name: 'salaryLoanBalance',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setPageFilterData: (state, action) => {
      state.pageFilters = { ...state.pageFilters, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoanBalanceAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLoanBalanceAsync.fulfilled, (state, action) => {
        state.data = action.payload.map((item) => {
          const { month, year } = parseMonthIntToObj(item.month);

          return ({
            ...item,
            month: `${MONTHS_SHORT[month]} ${year}`,
            issue: parseNumber(item.issue),
            return: parseNumber(item.return),
            balance: parseNumber(item.balance)
          });
        });
        state.isLoading = false;
      })
      .addCase(fetchLoanBalanceAsync.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = true;
      });
  },
});

export const {
  resetState,
  setQueryFilter,
  setPageFilterData,
} = loanBalanceSlice.actions;

export default loanBalanceSlice.reducer;
