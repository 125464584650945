import { batch, shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';

import Button from '../../../components/Button/Button';
import { NOTIFICATIONS_CHIPS, NOTIFICATIONS_TABS } from '../../../constants/notifications';
import { useDispatch, useSelector } from '../../../hooks/reduxToolkit';
import {
  fetchNotificationsAsync, setChips,
  setQueryFilters,
  setQueryParams,
  setTabs
} from '../../../toolkitStore/notificationsModal/slice';
import { localStorageHelper } from '../../../utils/storageHelper';

import styles from '../sass/NotificationsTabs.module.scss';

const NotificationsTabs = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const { activeTab } = useSelector(({ notificationsModal }) => ({
    activeTab: notificationsModal.activeTab,
  }), shallowEqual);

  const handleClickTab = (tab) => {
    batch(() => {
      dispatch(setTabs(tab));

      if (tab === NOTIFICATIONS_TABS.NEW) {
        dispatch(setQueryParams({ filters: { only_unread: true } }));
      } else {
        dispatch(setQueryParams({ filters: { only_read: true } }));
      }

      dispatch(setChips(NOTIFICATIONS_CHIPS.ALL));
      dispatch(setQueryFilters({ group: '' }));
      dispatch(fetchNotificationsAsync());
    });

    localStorageHelper.set('notificationsTab', tab);
  };

  return (
    <div role="tablist" className={styles.tabs}>
      {Object.values(NOTIFICATIONS_TABS).map((tab) => (
        <div
          key={tab}
          role="tab"
          className={`${styles.tab} ${activeTab === tab ? styles.active : ''}`}
        >
          <Button
            variant="ghost"
            ariaLabel={`${tab} tab`}
            onClick={() => handleClickTab(tab)}
          >
            {t(tab)}
          </Button>
        </div>
      ))}
    </div>
  );
};

export default NotificationsTabs;
