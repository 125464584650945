import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { COUNTRY_CODE_SUBDIVISIONS, COUNTRY_CODES } from '../../constants/apiReqUrls';
import { OK } from '../../constants/httpStatusCodes';
import axios from '../../services/axios';
import { getAsyncMatchers } from '../../utils/helpers';

const {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction,
} = getAsyncMatchers('countryCode');

export const initialState = {
  isLoading: false,
  error: undefined,
  countryCodes: [],
  countryCodeSubdivisions: [],
};

export const getCountryCodes = createAsyncThunk(
  'countryCode/getCountryCodes',
  async ({ page, filters, sort }, { rejectWithValue }) => {
    try {
      const params = {
        page,
        sort,
        filters,
      };

      const { data, status } = await axios.get(COUNTRY_CODES, { params });

      return status === OK ? { key: 'countryCodes', ...data } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCountryCodeSubdivisions = createAsyncThunk(
  'countryCode/getCountryCodeSubdivisions',
  async ({ page, filters, sort }, { rejectWithValue }) => {
    try {
      const params = {
        page,
        sort,
        filters,
      };
      
      const { data, status } = await axios.get(COUNTRY_CODE_SUBDIVISIONS, { params });
      
      return status === OK ? { key: 'countryCodeSubdivisions', ...data } : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const countryCodeSlice = createSlice({
  name: 'countryCode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isPendingAction, (state) => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilledAction, (state, action) => {
        const { key, data } = action.payload;

        state[key] = data;
        state.isLoading = false;
      })
      .addMatcher(isRejectedAction, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export default countryCodeSlice.reducer;
