import PropTypes from 'prop-types';

import { LOADER_COLOR_BLUE } from '../../constants/common';
import BallPulse from '../Loaders/BallPulse';

const LoadingWrapper = ({
  isLoading,
  hasData,
  emptyComponent,
  classes = {
    wrapper: '',
    loader: '',
    emptyList: '',
  },
  children
}) => (
  <div className={`${classes?.wrapper || ''} ${classes?.emptyList}`}>
    {isLoading && <BallPulse className={`${classes?.loader || ''}`} color={LOADER_COLOR_BLUE} />}
    {!isLoading && (hasData ? children : emptyComponent)}
  </div>
);

LoadingWrapper.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasData: PropTypes.bool.isRequired,
  emptyComponent: PropTypes.element.isRequired,
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    loader: PropTypes.string,
  }),
  children: PropTypes.node.isRequired,
};

export default LoadingWrapper;
