import { useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'next-i18next';

import { LOADER_COLOR_BLUE } from '../../constants/common';
import { HELP_ARTICLE_MANAGEMENT } from '../../constants/permissions';
import { useDispatch, useSelector } from '../../hooks/reduxToolkit';
import useMediaQuery from '../../hooks/useMediaQuery';
import useOnKeydown from '../../hooks/useOnKeydown';
import { setModalData } from '../../toolkitStore/helpCenter/slice';
import { isAllowed } from '../../utils/helpers';
import Button from '../Button/Button';
import IconButton from '../Button/IconButton';
import Close from '../Icons/Close';
import BallPulse from '../Loaders/BallPulse';

import styles from './sass/HelpCenter.module.scss';

const HelpCenterReferences = () => {
  const ref = useRef(null);
  const { t } = useTranslation('common');
  const isMobile = useMediaQuery('(max-width: 767px)');
  const dispatch = useDispatch();

  const { isLoading, modalProps, isHelpManagement } = useSelector(({ helpCenter, user }) => ({
    isLoading: helpCenter.isLoading,
    modalProps: helpCenter.helpCenterModal,
    isHelpManagement: isAllowed([HELP_ARTICLE_MANAGEMENT], user.permissions),
  }), shallowEqual);

  useOnKeydown(ref, 'Escape', () => {
    if (modalProps.referenceVisible) {
      dispatch(setModalData({ referenceVisible: false }));
    }
  });

  return (
    <div
      ref={ref}
      className={`
        ${styles.referenceContainer}
        ${modalProps.referenceVisible ? styles.active : ''}
        ${isMobile ? styles.mobile : ''}
      `}
    >
      {isLoading ? <div className={styles.loader}><BallPulse color={LOADER_COLOR_BLUE} /></div> : (
        <>
          <div className={styles.title}><span>{t('helpCentre')}</span></div>
          {isHelpManagement && (
            <>
              <div className={styles.noReference}><span>{t('noAddedReference')}</span></div>
              <div className={styles.separator} />
              <Button
                size="sm"
                variant="ghost"
                color="link"
                onClick={() => dispatch(setModalData({ isModalOpen: true }))}
                className={styles.addNewRef}
              >
                {t('addNewReferenceToThisPage')}
              </Button>
            </>
          )}
          <div className={styles.emptyArticle}>
            <p>
              {!isHelpManagement && (
                <span>
                  {t('helpArticleNotAdd')}
                  {' '}
                </span>
              )}
              <a
                href={process.env.NEXT_PUBLIC_HELP_ONIX_BASE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('goToKnowledgeBase')}
              </a>
            </p>
          </div>
          <div className={styles.closeModal}>
            <IconButton
              icon={<Close />}
              variant="ghost"
              ariaLabel="closeModal"
              onClick={() => dispatch(setModalData({ referenceVisible: false }))}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default HelpCenterReferences;
