import { Fragment } from 'react';
import { shallowEqual } from 'react-redux';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';

import { REVIEW } from '../../constants/permissions';
import { useSelector } from '../../hooks/reduxToolkit';
import { isAllowed } from '../../utils/helpers';
import ClipboardCopy from '../ClipboardCopy/ClipboardCopy';
import LinkSimpleIcon from '../Icons/LinkSimpleIcon';

import styles from './sass/BreadCrumbs.module.scss';

const BreadCrumbs = ({ data, className = '', isLastCopy = false }) => {
  const { t } = useTranslation('common');

  const { isReview } = useSelector(({ user }) => ({
    isReview: isAllowed([REVIEW], user.permissions),
  }), shallowEqual);

  return (
    <div className={`${styles.block} ${className}`}>
      <Link href={isReview ? '/review' : '/timesheet'} className={styles.item}>
        {t('hydra')}
      </Link>
      <span className={styles.separator}>/</span>
      {data.map(({ url, title, active }) => (
        active ? (
          <Fragment key={`${url}_${title}`}>
            <Link href={`/${url}`} className={styles.item} title={title}>
              {title}
            </Link>
            <span className={styles.separator}>/</span>
          </Fragment>
        ) : (
          <span className={styles.item} key={title}>
            {isLastCopy ? (
              <ClipboardCopy
                icon={<LinkSimpleIcon />}
                copyText={`${window.location.origin}/${url}`}
                className={styles.clipboard}
              >
                {title}
              </ClipboardCopy>
            ) : (title)}
          </span>
        )
      ))}
    </div>
  );
};

BreadCrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
      active: PropTypes.bool,
    })
  ).isRequired,
  className: PropTypes.string,
  isLastCopy: PropTypes.bool,
};

export default BreadCrumbs;
