import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Router from 'next/router';

import { SORT_BY_NAME } from '../../constants/common';
import { LIST_SIZE_PER_PAGE } from '../../constants/values';
import axios from '../../services/axios';
import { formatStringDate } from '../../utils/helpers';
import { sendFiles } from '../../utils/sendFiles';
import {
  newValidateErrors,
  validateAgreementDocument,
  validateClientAgreement,
  validateErrors
} from '../../utils/validators';

export const initialState = {
  isLoading: true,
  isSubmit: false,
  error: undefined,
  data: [],
  meta: {},
  query: {
    filters: {
      id: [],
      name: '',
      client_id: [],
      client_agreement_id: [],
      date: ''
    },
    sort: SORT_BY_NAME,
    page: null,
    per_page: LIST_SIZE_PER_PAGE,
  },
  modalFields: {
    name: '',
    date: formatStringDate(new Date()),
    comments: '',
    documentLink: '',
    documentFile: null,
  },
  documentModal: {
    modalId: 'documentModal',
    isModalOpen: false,
    isDateOpen: false,
    documentId: null,
    isUpload: false
  },
  deleteModal: {
    modalId: 'deleteModal',
    isModalOpen: false,
    document: null,
  },
  fieldsErrors: {},
};

export const fetchAgreementDocumentsAsync = createAsyncThunk(
  'agreementDocuments/fetchAgreementDocuments',
  async (arg, { getState, rejectWithValue }) => {
    try {
      const { query } = getState().agreementDocuments;
      const { data, status } = await axios.get('/agreement_documents', { params: query });

      return status === 200 ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addOrEditDocumentAsync = createAsyncThunk(
  'agreementDocuments/addOrEditDocument',
  async (arg, { getState, rejectWithValue, dispatch }) => {
    try {
      const { modalFields, documentModal, query: { filters } } = getState().agreementDocuments;
      const errors = validateErrors(modalFields, validateAgreementDocument, { isUpload: documentModal.isUpload });

      if (errors) {
        return rejectWithValue({ isFormValidation: true, errors });
      }

      let method = 'POST';
      let url = '/agreement_documents';

      if (documentModal.documentId) {
        method = 'PUT';
        url += `/${documentModal.documentId}`;
      }

      const { query } = Router;
      const body = {
        name: modalFields.name,
        comments: modalFields.comments,
        document_link: modalFields.documentLink,
        date: formatStringDate(new Date(modalFields.date), 'y-MM-dd'),
        client_agreement_id: query.agreement || filters.client_agreement_id,
        client_id: query.id,
      };

      if (arg?.fileId) {
        body.file_id = arg.fileId;
      } else if (modalFields.documentFile?.file) {
        const mediaIds = await sendFiles([modalFields.documentFile.file]);

        if (mediaIds.error) {
          return rejectWithValue(mediaIds);
        }

        mediaIds.data = mediaIds.data.filter((item) => item !== undefined);
        body.file_id = mediaIds.data[0] || null;
      } else if (!modalFields.documentFile && documentModal.documentId) {
        body.file_id = null;
      }

      const { data, status } = await axios({ method, url, data: body });

      if ((status === 201 || status === 200) && query.agreement) {
        dispatch(fetchAgreementDocumentsAsync());
        return data;
      }

      return (status === 201 || status === 200) ? data : rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteDocumentsAsync = createAsyncThunk(
  'agreementDocuments/deleteDocuments',
  async ({ documentId }, { dispatch, rejectWithValue }) => {
    try {
      const { data, status } = await axios.delete(`/agreement_documents/${documentId}`);

      if (status === 204) {
        dispatch(fetchAgreementDocumentsAsync());
        return data;
      }

      return rejectWithValue(data.message);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const agreementDocumentsSlice = createSlice({
  name: 'agreementDocuments',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryFilter: (state, action) => {
      state.query.filters = { ...state.query.filters, ...action.payload };
    },
    setQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload };
    },
    setData: (state, action) => {
      return ({ ...state, ...action.payload });
    },
    setModalData: (state, action) => {
      state[action.payload.key] = { ...state[action.payload.key], ...action.payload.data };
    },
    setModalFields: (state, action) => {
      state.modalFields = { ...state.modalFields, ...action.payload };
      state.fieldsErrors = {
        ...state.fieldsErrors,
        ...newValidateErrors({ ...action.payload }, validateClientAgreement)
      };
    },
    resetModalFields: (state) => {
      state.modalFields = initialState.modalFields;
      state.fieldsErrors = initialState.fieldsErrors;
      state.documentModal = initialState.documentModal;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgreementDocumentsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAgreementDocumentsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(fetchAgreementDocumentsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addOrEditDocumentAsync.pending, (state) => {
        state.isSubmit = true;
        state.error = undefined;
        state.fieldsErrors = {};
      })
      .addCase(addOrEditDocumentAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.documentModal = initialState.documentModal;
        state.modalFields = initialState.modalFields;
        state.fieldsErrors = {};
      })
      .addCase(addOrEditDocumentAsync.rejected, (state, action) => {
        if (action.payload?.isFormValidation) {
          state.fieldsErrors = action.payload.errors;
        } else {
          state.error = action.payload;
        }
        state.isSubmit = false;
      })
      .addCase(deleteDocumentsAsync.pending, (state) => {
        state.isSubmit = true;
      })
      .addCase(deleteDocumentsAsync.fulfilled, (state) => {
        state.isSubmit = false;
        state.deleteModal = initialState.deleteModal;
      })
      .addCase(deleteDocumentsAsync.rejected, (state, action) => {
        state.isSubmit = false;
        state.error = action.payload;
      });
  },
});

export const {
  setQueryFilter,
  setQuery,
  setModalData,
  setModalFields,
  setData,
  resetModalFields,
  resetState,
} = agreementDocumentsSlice.actions;

export default agreementDocumentsSlice.reducer;
