import Link from 'next/link';
import PropTypes from 'prop-types';

import { STATUS_INACTIVE, USER_STATUSES } from '../../constants/status';
import UserBadge from '../UserBadge/UserBadge';
import UserHoverCard from '../UserHoverCard/UserHoverCard';

import styles from './sass/UserLink.module.scss';

const UserLink = ({
  user,
  isShowAvatar = true,
  className = '',
  width = 24,
  height = 24,
  onClick = () => {},
  isShowHoverCard = false
}) => {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      {isShowAvatar && (
        <div className={styles.avatarWrapper} style={{ width }}>
          {(isShowHoverCard || user?.on_vacation) ? (
            <UserHoverCard user={user}>
              <UserBadge
                user={user}
                width={width}
                height={height}
              />
            </UserHoverCard>
          ) : (
            <UserBadge
              user={user}
              width={width}
              height={height}
            />
          )}
        </div>
      )}
      <p>
        <Link
          href={`/employees/${user.id}`}
          className={user.status === STATUS_INACTIVE ? styles.trashed : ''}
          onClick={onClick}
        >
          {user.name}
        </Link>
      </p>
    </div>
  );
};

UserLink.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    photo48_link: PropTypes.string,
    status: PropTypes.oneOf(USER_STATUSES),
    on_vacation: PropTypes.bool,
  }).isRequired,
  isShowAvatar: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isShowHoverCard: PropTypes.bool
};

export default UserLink;
