import { batch } from 'react-redux';

import { INVOICE_STATUS_DRAFT } from '../../constants/status';
import { ALERT_SUCCESS } from '../../constants/types';
import { ALERT_AUTO_CLOSE_TIME } from '../../constants/values';
import { authGet, authSet } from '../../utils/apiCall';
import {
  filterQueryParams, getApiErrors, numberFormatter, queryStringify, setErrors,
} from '../../utils/helpers';
import { parseInvoiceData } from '../../utils/invoices';
import { queryStringify as queryStringifyRouter, replaceRouterQuery } from '../../utils/routerQuery';
import { validateErrors, validateInvoiceCustomItem } from '../../utils/validators';
import { addAlert, addAlerts } from '../alerts/actions';
import { setEditInvoice, setEditModalData } from '../invoiceEdit/actions';
import {
  INVOICES_FETCH_CATEGORIES, INVOICES_FETCH_DATA, INVOICES_FETCH_INVOICE, INVOICES_FETCH_NUMBER_OF,
  INVOICES_RESET_CUSTOM_MODAL_DATA, INVOICES_SET_ABORT, INVOICES_SET_DATEPICKER_DATA, INVOICES_SET_EXPANDED_ROW,
  INVOICES_SET_INITIAL_STATE, INVOICES_SET_LOADING,
  INVOICES_SET_MODAL_DATA, INVOICES_SET_MODAL_ERROR,
  INVOICES_SET_QUERY_PARAMS,
  INVOICES_SET_SELECTED_INVOICES, INVOICES_SET_SENDING_INVOICES, INVOICES_SET_TABLE_HEAD_DATA,
  INVOICES_TOGGLE_TABLE_MORE
} from './types';

export const setInitialState = () => ({ type: INVOICES_SET_INITIAL_STATE });

export const resetCustomInvoiceModal = () => ({ type: INVOICES_RESET_CUSTOM_MODAL_DATA });

export const setAbort = (key, controller) => ({
  type: INVOICES_SET_ABORT, key, controller
});

export const setSelectedInvoices = (selectedInvoices) => ({
  type: INVOICES_SET_SELECTED_INVOICES, selectedInvoices
});

export const setSendingInvoices = (sendingInvoices) => ({
  type: INVOICES_SET_SENDING_INVOICES, sendingInvoices
});

export const setTableHeadData = (data) => ({
  type: INVOICES_SET_TABLE_HEAD_DATA, data
});

export const toggleTableMore = (isOpen, id = '', style = {}) => ({
  type: INVOICES_TOGGLE_TABLE_MORE,
  tableMoreProps: {
    isOpen, id, style
  }
});

export const setModalData = (key, data) => ({
  type: INVOICES_SET_MODAL_DATA, key, data
});

export const fetchInvoices = () => {
  return (dispatch, getState) => {
    const { queryParams, abortProps: { abortInvoices } } = getState().invoices;
    dispatch({ type: INVOICES_SET_LOADING, key: 'isLoading', isLoading: true });

    return authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices?${queryStringify(queryParams)}`,
      {
        abortController: abortInvoices,
        setAbortController: (controller) => dispatch(setAbort('abortInvoices', controller))
      }
    ).then((res) => {
      if (res && res.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      } else if (res) {
        const data = res.data.map((item) => {
          const amount = numberFormatter.format(item.amount);
          const paidAmountPercent = ((item.xero_paid_with_cash + item.xero_paid_with_credit_notes) / item.amount) * 100;

          return { ...item, amount, paidAmountPercent };
        });

        dispatch(({ type: INVOICES_FETCH_DATA, payload: { ...res, data } }));
      }
    });
  };
};

export const setInvoice = (invoice) => ({
  type: INVOICES_FETCH_INVOICE, invoice
});

export const fetchInvoice = (id) => {
  return (dispatch) => {
    return authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices/${id}`,
    ).then((res) => {
      if (res.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      } else {
        const data = parseInvoiceData(res);
        dispatch(setInvoice(data));
      }
    });
  };
};

export const setExpandedRow = (row) => {
  return (dispatch) => {
    batch(() => {
      dispatch({ type: INVOICES_SET_EXPANDED_ROW, row });
      dispatch(setInvoice({}));

      if (row) {
        dispatch(fetchInvoice(row));
      }
    });
  };
};

export const setPage = (page) => {
  return (dispatch) => {
    batch(() => {
      dispatch({ type: INVOICES_SET_QUERY_PARAMS, params: { page } });
      dispatch(setExpandedRow(null));
      dispatch(fetchInvoices());
    });
  };
};

export const setShowOwn = (showOwn) => ({
  type: INVOICES_SET_QUERY_PARAMS,
  params: {
    filters: {
      show_own: showOwn
    }
  }
});

export const setQueryParams = (params, isReplaceUrl = true) => {
  return (dispatch, getState) => {
    const { invoices: { queryParams } } = getState();

    batch(() => {
      dispatch({
        type: INVOICES_SET_QUERY_PARAMS,
        params: {
          ...params,
          ...(params.filters ? { page: null } : {})
        }
      });
      dispatch(fetchInvoices());
      dispatch(setExpandedRow(null));
    });

    if (isReplaceUrl) {
      replaceRouterQuery(queryStringifyRouter({
        ...queryParams.filters,
        ...params.filters
      }));
    }
  };
};

export const setDatepickerData = (data) => ({
  type: INVOICES_SET_DATEPICKER_DATA, data
});

export const updateLinksData = (data) => {
  return (dispatch, getState) => {
    const { invoices } = getState();
    const newData = {};

    Object
      .entries(data)
      .forEach(([key, value]) => {
        newData[key] = invoices[key] + value;
      });

    dispatch({ type: INVOICES_FETCH_NUMBER_OF, data: newData });
  };
};

export const sendInvoice = (id, isFetchInvoices = true) => {
  return (dispatch, getState) => {
    const { sendingInvoices } = getState().invoices;
    dispatch(setSendingInvoices([...sendingInvoices, id]));
    dispatch({ type: INVOICES_SET_LOADING, key: 'isSubmitting', isLoading: true });

    authSet(
      'POST',
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices/${id}/send`,
      {},
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      const tempSending = sendingInvoices.filter((item) => item !== id);

      batch(() => {
        if (res?.message) {
          const alerts = getApiErrors(res.errors || { message: res.message });
          dispatch(addAlerts(alerts));
        } else {
          dispatch(setEditModalData({ isModalOpen: false }));
          dispatch(setEditInvoice(null));
          dispatch(updateLinksData({ numberOfMyDrafts: -1 }));
          dispatch(addAlert({
            id,
            type: ALERT_SUCCESS,
            message: res.name,
            useCustomComponent: true,
          }, { autoCloseTime: ALERT_AUTO_CLOSE_TIME * 2 }));
          if (isFetchInvoices) {
            dispatch(fetchInvoices());
          }
          dispatch(setSelectedInvoices([]));
        }
        dispatch(setSendingInvoices(tempSending));
        dispatch({ type: INVOICES_SET_LOADING, key: 'isSubmitting', isLoading: false });
      });
    });
  };
};

export const deleteInvoice = (id) => {
  return (dispatch) => {
    dispatch({ type: INVOICES_SET_LOADING, key: 'isSubmitting', isLoading: true });

    return authSet(
      'DELETE',
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices/${id}`,
      {},
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      if (res.ok) {
        batch(() => {
          dispatch(fetchInvoices());
          dispatch(updateLinksData({ numberOfMyDrafts: -1 }));
          dispatch(setModalData('warningModal', { isModalOpen: false }));
        });
      } else if (res?.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      }
      dispatch({ type: INVOICES_SET_LOADING, key: 'isSubmitting', isLoading: false });
    });
  };
};

export const mergeInvoices = (invoices) => {
  return (dispatch) => {
    return authSet(
      'POST',
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices/merge`,
      { invoices },
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      if (res?.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      } else {
        const invoice = parseInvoiceData(res);

        batch(() => {
          dispatch(setEditInvoice(invoice));
          dispatch(setEditModalData({ isModalOpen: true, isMerge: true }));
          dispatch(setSelectedInvoices([]));
          dispatch(updateLinksData({ numberOfMyDrafts: -invoices.length + 1 }));
          dispatch(fetchInvoices());
        });
      }
    });
  };
};

export const fetchNumberOfDebtors = async (isInvoicesManagement) => {
  let numberOfDebtors;

  if (isInvoicesManagement) {
    const res = await authGet(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/reports/manager_debtors`);

    if (res.message) {
      numberOfDebtors = res;
    } else {
      numberOfDebtors = res.length;
    }
  }

  return numberOfDebtors;
};

export const fetchNumberOfMyDrafts = (isShowOwn, filters = {}) => {
  const params = {
    per_page: 1,
    filters: {
      show_own: isShowOwn,
      status: [INVOICE_STATUS_DRAFT],
      ...filters
    }
  };

  return authGet(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices?${queryStringify(params)}`)
    .then((res) => {
      let numberOfMyDrafts;

      if (res.message) {
        numberOfMyDrafts = res;
      } else if (res.meta) {
        numberOfMyDrafts = res.meta.total;
      }

      return numberOfMyDrafts;
    });
};

export const fetchNumberOfRecommended = (isShowOwn, filters = {}) => {
  const params = {
    filters: {
      show_own: isShowOwn,
      ...filters
    },
    per_page: 1
  };

  return authGet(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/recommended_invoices?${queryStringify(params)}`)
    .then((res) => {
      let numberOfRecommended;

      if (res.message) {
        numberOfRecommended = res;
      } else if (res.meta) {
        numberOfRecommended = res.meta.total;
      }

      return numberOfRecommended;
    });
};

export const loadLinksData = (filters = {}, isInvoicesManagement = false) => {
  return (dispatch, getState) => {
    const { queryParams } = getState().invoiceRecommended;

    dispatch({ type: INVOICES_SET_LOADING, key: 'isLinksLoading', isLoading: true });

    Promise.all([
      fetchNumberOfRecommended(
        queryParams.filters.show_own,
        { ...filters, show_only_previous_period: queryParams.filters.show_only_previous_period }
      ),
      fetchNumberOfMyDrafts(queryParams.filters.show_own, filters),
      fetchNumberOfDebtors(isInvoicesManagement),
    ]).then((res) => {
      let alerts = [];
      const [recommended, drafts, debtors] = res;

      res.forEach((item) => {
        if (item?.message) {
          alerts = [...alerts, ...getApiErrors(res.errors || { message: res.message })];
        }
      });

      batch(() => {
        if (alerts.length > 0) {
          dispatch(addAlerts(alerts));
        }

        dispatch({
          type: INVOICES_FETCH_NUMBER_OF,
          data: {
            isLinksLoading: false,
            numberOfRecommended: recommended || 0,
            numberOfMyDrafts: drafts || 0,
            numberOfDebtors: debtors || 0,
          }
        });
      });
    });
  };
};

export const setCategories = (categories) => ({
  type: INVOICES_FETCH_CATEGORIES, categories
});

export const fetchCategories = () => {
  return (dispatch) => {
    const params = {
      sort: 'name'
    };

    authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoice_item_categories?${queryStringify(params)}`,
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      if (res?.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      } else {
        dispatch(setCategories(res.data || []));
      }
    });
  };
};

export const setModalErrors = (errors) => ({
  type: INVOICES_SET_MODAL_ERROR, errors
});

const setAndCheckData = ({
  errorsObject,
  setFieldData,
  setFieldError,
  validationFunction,
  fieldName,
  fieldData,
}) => {
  return (dispatch) => {
    const fieldError = validationFunction(fieldName, fieldData);

    batch(() => {
      dispatch(setFieldData({ [fieldName]: fieldData }));
      dispatch(setErrors({
        error: fieldError, errorsData: errorsObject, fieldName, setFieldError
      }));
    });
  };
};

export const setCustomItemField = (fieldName, fieldData) => {
  return (dispatch, getState) => {
    const { modalErrors } = getState().invoices;

    dispatch(setAndCheckData({
      errorsObject: modalErrors,
      setFieldData: (data) => setModalData('customInvoiceModal', data),
      setFieldError: setModalErrors,
      validationFunction: validateInvoiceCustomItem,
      fieldName,
      fieldData,
    }));
  };
};

export const createCustomInvoice = (fields) => {
  return (dispatch) => {
    const allFormErrors = validateErrors(fields, validateInvoiceCustomItem);

    if (allFormErrors) {
      dispatch(setModalErrors(allFormErrors));
    } else {
      const {
        company, paymentMethod, category, currency
      } = fields;

      authSet(
        'POST',
        `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices`,
        filterQueryParams({
          client_company_id: company.id,
          payment_method_id: paymentMethod.id,
          name: fields.invoiceName,
          comment: fields.comment,
          items: [{
            name: fields.itemName,
            amount: fields.amount,
            currency,
            category_id: category?.id,
            type: 'custom'
          }]
        }),
        {},
        { Accept: 'application/json', 'Content-Type': 'application/json' }
      ).then((res) => {
        if (res?.message) {
          const alerts = getApiErrors(res.errors || { message: res.message });
          dispatch(addAlerts(alerts));
        } else {
          batch(() => {
            dispatch(fetchInvoices());
            dispatch(resetCustomInvoiceModal());
            dispatch(updateLinksData({ numberOfMyDrafts: 1 }));
            dispatch(setModalData('customInvoiceModal', { isModalOpen: false }));
          });
        }
      });
    }
  };
};
