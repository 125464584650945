export const PROJECT_INFO_FETCH_PROJECT = 'PROJECT_INFO_FETCH_PROJECT';
export const PROJECT_INFO_SET_DATA = 'PROJECT_INFO_SET_DATA';
export const PROJECT_INFO_SET_EDIT = 'PROJECT_INFO_SET_EDIT';
export const PROJECT_INFO_SET_ABORT = 'PROJECT_INFO_SET_ABORT';
export const PROJECT_INFO_SET_PROJECT_LOADING = 'PROJECT_INFO_SET_PROJECT_LOADING';
export const PROJECT_INFO_SET_SUBMIT = 'PROJECT_INFO_SET_SUBMIT';
export const PROJECT_INFO_SET_DATA_LOADING = 'PROJECT_INFO_SET_DATA_LOADING';
export const PROJECT_INFO_SET_ERROR = 'PROJECT_INFO_SET_ERROR';
export const PROJECT_INFO_RESET_PROJECT = 'PROJECT_INFO_RESET_PROJECT';
export const PROJECT_INFO_SET_ERRORS = 'PROJECT_INFO_SET_ERRORS';
export const PROJECT_INFO_SET_SHOW_CLIENT_FIELDS = 'PROJECT_INFO_SET_SHOW_CLIENT_FIELDS';
export const PROJECT_INFO_FETCH_STATISTIC = 'PROJECT_INFO_FETCH_STATISTIC';
