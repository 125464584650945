import { batch } from 'react-redux';

import { INVOICE_ITEM_CUSTOM_TYPE } from '../../constants/types';
import { authGet, authSet } from '../../utils/apiCall';
import { getApiErrors, queryStringify } from '../../utils/helpers';
import { parseInvoiceData } from '../../utils/invoices';
import { validateCreateInvoiceModal, validateErrors } from '../../utils/validators';
import { addAlerts } from '../alerts/actions';
import { INVOICES_FETCH_DATA } from '../invoices/types';
import {
  INVOICE_EDIT_CLEAR,
  INVOICE_EDIT_FETCH_CATEGORIES, INVOICE_EDIT_FETCH_DATA, INVOICE_EDIT_SET_DATA, INVOICE_EDIT_SET_LOG_ERROR,
  INVOICE_EDIT_SET_MODAL_DATA
} from './types';

export const setEditInvoice = (invoice) => ({
  type: INVOICE_EDIT_FETCH_DATA,
  invoice,
  invoiceName: invoice?.name || '',
  invoiceComment: invoice?.comment || '',
  items: invoice?.items || [],
});

export const fetchEditInvoice = (id) => {
  return (dispatch) => authGet(`${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices/${id}`)
    .then((res) => {
      if (res?.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      } else {
        const data = parseInvoiceData(res);
        dispatch(setEditInvoice(data));
      }
    });
};

export const setCategories = (categories) => ({
  type: INVOICE_EDIT_FETCH_CATEGORIES, categories
});

export const fetchItemCategories = () => {
  return (dispatch) => {
    const params = {
      sort: 'name'
    };

    authGet(
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoice_item_categories?${queryStringify(params)}`,
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      if (res?.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      } else {
        dispatch(setCategories(res.data || []));
      }
    });
  };
};

export const updateInvoiceAmount = (invoiceId, amount) => {
  return (dispatch, getState) => {
    const { data } = getState().invoices;
    const tempData = [...data];
    const invoiceIndex = tempData.findIndex((item) => item.id === invoiceId);

    tempData[invoiceIndex] = { ...tempData[invoiceIndex], amount };

    dispatch({ type: INVOICES_FETCH_DATA, payload: { data: tempData } });
  };
};

export const updateInvoice = (id, body) => {
  return (dispatch) => {
    authSet(
      'PATCH',
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices/${id}`,
      body,
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      if (res?.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      } else {
        const data = parseInvoiceData(res);

        batch(() => {
          dispatch(setEditInvoice(data));
          dispatch(updateInvoiceAmount(res.id, res.amount));
        });
      }
    });
  };
};

export const setInvoiceData = (data) => ({
  type: INVOICE_EDIT_SET_DATA, data
});

export const updateInvoiceItems = (id, body) => {
  return (dispatch) => {
    authSet(
      'PUT',
      `${process.env.NEXT_PUBLIC_API_BACKEND_URL}/invoices/${id}/items`,
      body,
      {},
      { Accept: 'application/json', 'Content-Type': 'application/json' }
    ).then((res) => {
      if (res?.message) {
        const alerts = getApiErrors(res.errors || { message: res.message });
        dispatch(addAlerts(alerts));
      } else {
        const data = parseInvoiceData(res);

        batch(() => {
          dispatch(setEditInvoice(data));
          dispatch(updateInvoiceAmount(data.id, data.amount));
        });
      }
      dispatch(setInvoiceData({ isSubmittingRemove: false }));
    });
  };
};

export const clearInvoiceEdit = () => ({ type: INVOICE_EDIT_CLEAR });

export const setEditModalData = (data) => ({
  type: INVOICE_EDIT_SET_MODAL_DATA, data
});

export const editModalSetErrors = (modalErrors) => ({
  type: INVOICE_EDIT_SET_LOG_ERROR, modalErrors
});

export const setNewRows = (data) => ({
  type: INVOICE_EDIT_SET_DATA, data: { newRows: data }
});

export const setDataAction = (data, index, filed, newValue) => {
  const newData = [...data];

  newData[index] = {
    ...newData[index],
    [filed]: newValue,
    ...(
      filed === 'category' ? { isCategoryOpen: false } : {}
    )
  };

  return setNewRows(newData);
};

export const removeNewRow = (removeIndex) => {
  return (dispatch, getState) => {
    const { newRows } = getState().invoiceEdit;
    const tempRows = newRows.filter((item, index) => index !== removeIndex);

    batch(() => {
      if (removeIndex === 0) {
        dispatch(editModalSetErrors({}));
      }
      dispatch(setNewRows(tempRows));
    });
  };
};

export const removeInvoiceItem = (indexRemove) => {
  return (dispatch, getState) => {
    const { invoice, items } = getState().invoiceEdit;
    const customItems = items
      .filter((item, index) => item.type === INVOICE_ITEM_CUSTOM_TYPE && index !== indexRemove)
      .map((customItem) => {
        const amount = Number(customItem.amount.replace(/,/g, ''));

        return { ...customItem, amount };
      });
    batch(() => {
      dispatch(setInvoiceData({ isSubmittingRemove: true }));
      dispatch(updateInvoiceItems(invoice.id, { items: customItems }));
    });
  };
};

export const addInvoiceItem = (item, index) => {
  return (dispatch, getState) => {
    const { invoice, items, newRows } = getState().invoiceEdit;
    const modalErrors = validateErrors(item, validateCreateInvoiceModal);

    if (modalErrors) {
      dispatch(editModalSetErrors(modalErrors));
    } else {
      const tempRows = newRows.filter((row, removeIndex) => removeIndex !== index);
      const customItems = items
        .filter(({ type }) => type === INVOICE_ITEM_CUSTOM_TYPE)
        .map((customItem) => {
          const amount = Number(customItem.amount.replace(/,/g, ''));

          return { ...customItem, amount };
        });

      batch(() => {
        dispatch(
          updateInvoiceItems(
            invoice.id,
            {
              items: [
                ...customItems,
                {
                  ...item,
                  currency: invoice.currency
                }
              ]
            }
          )
        );
        dispatch(setNewRows(tempRows));
        dispatch(editModalSetErrors({}));
      });
    }
  };
};
