import { useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useTranslation } from 'next-i18next';

import IconButton from '../../../components/Button/IconButton';
import AppsIcon from '../../../components/Icons/AppsIcon';
import { apps, medias } from '../../../mock/appsList';
import HeaderAppListItem from './HeaderAppsListItem';

import styles from '../sass/Header.module.scss';

const HeaderAppsList = () => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Trigger asChild>
        <IconButton
          size="sm"
          icon={<AppsIcon />}
          variant="ghost"
          isActive={isOpen}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content className={`${styles.appsDropdown} ${styles.dropdown}`} sideOffset={8} align="end">
          <HeaderAppListItem data={apps} />
          <h3>{t('socialMedias')}</h3>
          <HeaderAppListItem data={medias} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default HeaderAppsList;
